<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="row align-items-center mw-100">
                    <div class="col">
                      <div class="mb-1">
                        <ol class="breadcrumb breadcrumb-alternate" aria-label="breadcrumbs">
                          <li *ngFor="let key of titleService.getNavi()" class="breadcrumb-item" [class.active]="key.current">
                            <a [routerLink]="['', key.url]">{{key.title}}</a>
                          </li>
                        </ol>
                      </div>
                      <h2 class="page-title">
                        Список водителей
                      </h2>
                    </div>
                    <div class="col-auto ms-auto d-print-none">
                      <div class="btn-list">
                        <span class="dropdown">
                          <button [disabled]="actionButtonDisabled" class="btn dropdown-toggle align-text-top" data-bs-boundary="viewport" data-bs-toggle="dropdown" aria-expanded="true">
                            <span *ngIf="actionButtonDisabled" class="spinner-border spinner-border-sm me-2" role="status"></span>
                            Действия
                          </button>
                          <div class="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(97px, 38px, 0px);">
                            <a class="dropdown-item" href="javascript:void(0)" (click)="reformatDrivers()">
                              Форматирование ФИО
                            </a>
                            <a class="dropdown-item" href="javascript:void(0)" (click)="recreateIndex()">
                              Реструктуризация индекса
                            </a>
                          </div>
                        </span>
                        <button href="javascript:void(0)" class="btn btn-primary d-none d-sm-inline-block disabled">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                          Создать
                        </button>
                        <button href="javascript:void(0)" class="btn btn-primary d-sm-none btn-icon disabled">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>


                <div *ngIf="!isLoading">
                  <div class="page-body">
                    <div class="">
                      <div class="row">
                        <div class="col-4">
                            <div class="subheader mb-2">Поиск водителя</div>
                            <div class="mb-3">
                              <input type="text" [(ngModel)]="description" (ngModelChange)="onChange()" class="form-control" placeholder="Введите ФИО">
                            </div>
                            <div class="subheader mb-2">Состояние</div>
                            <div class="mb-3">
                              <select name="workStatus" [(ngModel)]="isFired" (ngModelChange)="onChange()" class="form-select">
                                <option [ngValue]="false">Работает</option>
                                <option [ngValue]="true">Уволен</option>
                                <option [ngValue]="null">Не учитывать</option>
                              </select>
                            </div>
                            <div class="subheader mb-2">Мобильное приложение</div>
                            <div class="mb-3">
                              <select name="workStatus" [(ngModel)]="isAccepted" (ngModelChange)="onChange()" class="form-select">
                                <option [ngValue]="true">Есть</option>
                                <option [ngValue]="false">Нет</option>
                                <option [ngValue]="null">Не учитывать</option>
                              </select>
                            </div>
                            <div class="subheader mb-2">Пол</div>
                            <div class="mb-3">
                              <select [(ngModel)]="gender" name="gender" (ngModelChange)="onChange()" class="form-select">
                                <option [ngValue]="genderEnum.male">Мужской</option>
                                <option [ngValue]="genderEnum.female">Женский</option>
                                <option [ngValue]="null">Ну учитывать</option>
                              </select>
                            </div>
                            <div class="subheader mb-2">Водительское удостоверение</div>
                            <div class="mb-3">
                              <select [(ngModel)]="driverLicense" (change)="onChange()" (ngModelChange)="onChange()" name="driverLicense" class="form-select mb-3">
                                <option [ngValue]="true">Есть</option>
                                <option [ngValue]="false">Нет</option>
                                <option [ngValue]="null">Не учитывать</option>
                              </select>
                              <label [hidden]="!stateDriverLicense" class="form-check mb-1">
                                <input type="checkbox" [(ngModel)]="withExpirationDriverLicense" (ngModelChange)="onChange()" class="form-check-input">
                                <span class="form-check-label">Просроченное в/у</span>
                              </label>
                              <label [hidden]="!stateDriverLicense" class="form-check mb-1">
                                <input type="checkbox" [(ngModel)]="withoutScanDriverLicense" (ngModelChange)="onChange()" class="form-check-input">
                                <span class="form-check-label">Без скана в/у</span>
                              </label>
                            </div>
                            <div class="subheader mb-2">Аватар</div>
                            <div class="mb-3">
                              <select [(ngModel)]="avatar" (ngModelChange)="onChange()" name="avatar" class="form-select">
                                <option [ngValue]="true">Есть</option>
                                <option [ngValue]="false">Нет</option>
                                <option [ngValue]="null">Не учитывать</option>
                              </select>
                            </div>
                            <div class="subheader mb-2">Рейтинг</div>
                            <div class="mb-3">
                              <label class="form-check mb-1">
                                <input type="radio" name="rating" [value]="5" class="form-check-input" (ngModelChange)="onChange()" [(ngModel)]="rating">
                                <span class="form-cheick-label">5 звезд</span>
                              </label>
                              <label class="form-check mb-1">
                                <input type="radio" name="rating" [value]="4" class="form-check-input" (ngModelChange)="onChange()" [(ngModel)]="rating">
                                <span class="form-check-label">4 звезды</span>
                              </label>
                              <label class="form-check mb-1">
                                <input type="radio" name="rating" [value]="3" class="form-check-input" (ngModelChange)="onChange()" [(ngModel)]="rating">
                                <span class="form-check-label">3 звезды</span>
                              </label>
                              <label class="form-check mb-1">
                                <input type="radio" name="rating" [value]="2" class="form-check-input" (ngModelChange)="onChange()" [(ngModel)]="rating">
                                <span class="form-check-label">2 и менее</span>
                              </label>
                              <label class="form-check mb-1">
                                <input type="radio" name="rating" [value]="null" class="form-check-input" (ngModelChange)="onChange()" [(ngModel)]="rating">
                                <span class="form-check-label">Любой</span>
                              </label>
                            </div>
                            <div class="subheader mb-2">Логист</div>
                            <div>
                                <select name="manager" [(ngModel)]="logistId" (ngModelChange)="onChange()" class="form-select">
                                  <option *ngFor="let key of managers" [value]="key.managerId">{{getLogistName(key)}}</option>
                                  <option [ngValue]="null">Не указывать</option>
                                </select>
                              </div>
                          </div>
                        <div class="col-8">
                          <div class="row">
                            <div *ngIf="isDriversLoading == false && drivers.length === 0" class="empty">
                              <div class="empty-img"><img src="/assets/static/illustrations/undraw_quitting_time_dm8t.svg" height="128"  alt="">
                              </div>
                              <p class="empty-title">Ничего не найдено</p>
                              <p class="empty-subtitle text-muted">
                                По вашему запросу нет водителей
                              </p>
                              <div class="empty-action">
                                <a href="javascript:void(0)" (click)="defaultFilter()" class="btn btn-primary">
                                  Сбросить фильтр
                                </a>
                              </div>
                            </div>
                            <div *ngFor="let key of drivers" class="col-md-12 mb-3">
                              <div class="card card-link">
                                <div class="card-body">
                                  <div class="row g-2 align-items-center">
                                    <div class="col-auto">
                                        <span [classList]="!null ? key.val + ' avatar avatar-lg'  : 'avatar avatar-lg'" style="">{{getAvatarText(key.surname , key.name)}}</span>
                                    </div>
                                    <div class="col">
                                      <h4 class="card-title m-0">
                                        <a href="javascript:void(0)">
                                          {{key.description}}
                                        </a>
                                      </h4>
                                      <div *ngIf="!key.isFired" class="text-muted small">
                                        Работает
                                      </div>
                                      <div *ngIf="key.isFired"  class="text-muted small">
                                        Уволен
                                      </div>
                                      <div class="small mt-1">
                                        <div class="d-flex align-items-center">
                                          <span (click)="openModalWithComponent(key.manager)" *ngIf="!key.manager?.avatar" class="avatar me-2 rounded" style="">{{getTextAvatar(key.manager?.name, key.manager?.surname)}}
                                            <span *ngIf="key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span>
                                            <span *ngIf="!key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                                          </span>
                                          <span (click)="openModalWithComponent(key.manager)" *ngIf="key.manager?.avatar" class="avatar me-2 rounded" [style.background-image]="'url(' + key.manager?.avatar + ')'">
                                            <span *ngIf="key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span>
                                            <span *ngIf="!key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                                          </span>
                                          <div>
                                            <a href="javascript:void(0)" style="color: black" (click)="openModalWithComponent(key.manager)">{{key.manager.surname ?? ''}} {{key.manager.name ?? '' }}</a>
                                            <div class="text-muted">Ответственный ({{key.manager.position}})</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-auto pe-lg-2">
                                      <span hidden="" class="text" tooltip="Пользователь Apple">
                                       <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z" /><path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" /></svg>
                                     </span>
                                      <span hidden="" class="text" tooltip="Пользователь Android">
	                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="4" y1="10" x2="4" y2="16" /><line x1="20" y1="10" x2="20" y2="16" /><path d="M7 9h10v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-8a5 5 0 0 1 10 0" /><line x1="8" y1="3" x2="9" y2="5" /><line x1="16" y1="3" x2="15" y2="5" /><line x1="9" y1="18" x2="9" y2="21" /><line x1="15" y1="18" x2="15" y2="21" /></svg>
                                      </span>
                                      <span *ngIf="getDriverLicenseStatus(key.driverLicense) === driverLicenseStatus.noDriverLicense" class="text-red" tooltip="У водителя есть незаполненные документы">
	                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="3" y1="3" x2="21" y2="21" /><path d="M7 3h7l5 5v7m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-14" /></svg>
                                      </span>
                                      <span *ngIf="getDriverLicenseStatus(key.driverLicense) === driverLicenseStatus.noScanDriverLicense" class="text-yellow" tooltip="У водителя есть документы без сканов">
	                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="14" width="3" height="5" rx="1" /><path d="M6 15a1 1 0 0 1 1 -1h3.756a1 1 0 0 1 .958 .713l1.2 3c.09 .303 .133 .63 -.056 .884c-.188 .254 -.542 .403 -.858 .403h-2v2.467a1.1 1.1 0 0 1 -2.015 .61l-1.985 -3.077v-4z" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M5 11v-6a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-2.5" /></svg>
                                      </span>
                                      <span *ngIf="getExpirationDriverLicense(key.driverLicense) === driverLicenseStatus.expirationDriverLicense" class="text-yellow" tooltip="У водителя есть просроченные документы">
	                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="17" x2="12.01" y2="17" /><line x1="12" y1="11" x2="12" y2="14" /></svg>
                                      </span>
                                      <span *ngIf="getDriverLicenseStatus(key.driverLicense) === driverLicenseStatus.driverLicenseOk"  class="text-green" tooltip="У водителя все в порядке с документами">
	                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="16" width="3" height="5" rx="1" /><path d="M6 20a1 1 0 0 0 1 1h3.756a1 1 0 0 0 .958 -.713l1.2 -3c.09 -.303 .133 -.63 -.056 -.884c-.188 -.254 -.542 -.403 -.858 -.403h-2v-2.467a1.1 1.1 0 0 0 -2.015 -.61l-1.985 3.077v4z" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M5 12.1v-7.1a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-2.3" /></svg>
                                      </span>
                                    </div>
                                    <div class="col-auto">
                                      <a href="javascript:void(0)" class="btn disabled">
                                        Редактировать
                                      </a>
                                    </div>
                                    <div class="col-auto">
                                      <div class="dropdown">
                                        <a href="#" class="card-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="19" r="1"></circle><circle cx="12" cy="5" r="1"></circle></svg>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-end">
                                          <a href="javascript:void(0)" class="dropdown-item">Позвонить</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="search-results"
                              infiniteScroll
                              [infiniteScrollDistance]="2"
                              [infiniteScrollThrottle]="50"
                              (scrolled)="loadDrivers()"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
