import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import {NgxMaskModule, IConfig} from 'ngx-mask'

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/logout/logout.component';
import {ForgotComponent} from './components/forgot/forgot.component';
import {ProfileComponent} from './components/profile/profile.component';
import {FormsModule} from '@angular/forms';
import {MenuComponent} from './components/menu/menu.component';
import {FooterComponent} from './components/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ruLocale } from 'ngx-bootstrap/locale';
import localeRu from '@angular/common/locales/ru';

defineLocale('ru', ruLocale);

// Boostrap
import {ModalModule} from 'ngx-bootstrap/modal';

import {NotFoundComponent} from './components/not-found/not-found.component';
import {PermissionDeniedComponent} from './components/permission-denied/permission-denied.component';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ManagerService} from './services/manager.service';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {TitleService} from "./services/title.service";
import {InternalServerErrorComponent} from './components/internal-server-error/internal-server-error.component';
import {CatalogComponent} from './components/catalog/catalog.component';
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {ToastrModule} from "ngx-toastr";
import {MainProfileComponent} from "./components/profile/main/main.profile.component";
import {AvatarProfileComponent} from "./components/profile/avatar/avatar.profile.component";
import {OfficesProfileComponent} from "./components/profile/offices/offices.profile.component";
import {PasswordProfileComponent} from "./components/profile/password/password.profile.component";
import {SessonsProfileComponent} from "./components/profile/sessons/sessons.profile.component";
import { CreateManagerComponent } from './components/manager/create/create.manager.component';
import { GetManagerComponent } from './components/manager/get/get.manager.component';
import { ListManagerComponent } from './components/manager/list/list.manager.component';
import {RolesManagerComponent} from "./components/manager/roles/roles.manager.component";
import {EditManagerComponent} from "./components/manager/edit/edit.manager.component";
import {MainEditManagerComponent} from "./components/manager/edit/main/main.edit.manager.component";
import {AvatarEditManagerComponent} from "./components/manager/edit/avatar/avatar.edit.manager.component";
import {ManagerComponentService} from "./components/manager/manager.component.service";
import {PasswordEditManagerComponent} from "./components/manager/edit/password/password.edit.manager.component";
import {WorkStatusEditManagerComponent} from "./components/manager/edit/work-status/work.status.edit.manager.component";
import {ListCarcaseComponent} from "./components/carcase/list/list.carcase.component";
import {CreateCarcaseComponent} from "./components/carcase/create/create.carcase.component";
import {EditCarcaseComponent} from "./components/carcase/edit/edit.carcase.component";
import {ListCategoryComponent} from "./components/category/list/list.category.component";
import {CreateCategoryComponent} from "./components/category/create/create.category.component";
import {ToolComponent} from "./components/tool/tool.component";
import {EditCategoryComponent} from "./components/category/edit/edit.category.component";
import {ListSelfEmployedCheckComponent} from "./components/self-employed-check/list/list.self-employed-check.component";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {CreateSelfEmployedCheckComponent} from "./components/self-employed-check/create/create.self-employed-check.component";
import {EnvPrintComponent} from "./components/env-print/env-print.component";
import {PalletCalculatorComponent} from "./components/pallet-calculator/pallet-calculator.component";
import {CdrComponent} from "./components/cdr/cdr.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {PalletComponent} from "./components/pallet/pallet.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {CurrencyComponent} from "./components/currency/currency.component";
import {BankComponent} from "./components/bank/bank.component";
import {ServiceTypeComponent} from "./components/service-type/service-type.component";
import {CounterpartyTypesComponent} from "./components/counterparty-types/counterparty-types.component";
import {RdpProfileComponent} from "./components/profile/rdp/rdp.profile.component";
import {RdpComponent} from "./components/rdp/rdp.component";
import {CreateRdpComponent} from "./components/rdp/create/create.rdp.component";
import {RdpEditManagerComponent} from "./components/manager/edit/rdp/rdp.edit.manager.component";
import {AdmonitionService} from "./services/admonition.service";
import {DriverComponent} from "./components/driver/driver.component";
import {StateComponent} from "./components/state/state.component";
import {StatusComponent} from "./components/status/status.component";
import {PolygonComponent} from "./components/polygon/polygon.component";

registerLocaleData(localeRu, 'ru');
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    ForgotComponent,
    ProfileComponent,
    MainProfileComponent,
    MenuComponent,
    FooterComponent,
    NotFoundComponent,
    PermissionDeniedComponent,
    DashboardComponent,
    InternalServerErrorComponent,
    CatalogComponent,
    AvatarProfileComponent,
    OfficesProfileComponent,
    SessonsProfileComponent,
    PasswordProfileComponent,
    RdpProfileComponent,
    CreateManagerComponent,
    GetManagerComponent,
    ListManagerComponent,
    RolesManagerComponent,
    EditManagerComponent,
    MainEditManagerComponent,
    AvatarEditManagerComponent,
    PasswordEditManagerComponent,
    WorkStatusEditManagerComponent,
    ListCarcaseComponent,
    CreateCarcaseComponent,
    EditCarcaseComponent,
    ListCategoryComponent,
    CreateCategoryComponent,
    ToolComponent,
    EditCategoryComponent,
    ListSelfEmployedCheckComponent,
    CreateSelfEmployedCheckComponent,
    EnvPrintComponent,
    PalletCalculatorComponent,
    CdrComponent,
    PalletComponent,
    StatusComponent,
    CurrencyComponent,
    BankComponent,
    ServiceTypeComponent,
    CounterpartyTypesComponent,
    RdpComponent,
    CreateRdpComponent,
    RdpEditManagerComponent,
    DriverComponent,
    StateComponent,
    PolygonComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    InfiniteScrollModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfigFunction),
    LeafletModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    InfiniteScrollModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    ManagerService,
    AdmonitionService,
    ManagerComponentService,
    TitleService,
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}


