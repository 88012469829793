import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ManagerService} from "../../../services/manager.service";
import {PasswordProfileComponentInterface} from "./password.profile.component.interface";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-profile-password',
  templateUrl: './password.profile.component.html',
  styleUrls: ['./password.profile.component.scss']
})
export class PasswordProfileComponent implements OnInit {

  public passwordChangeLoading: boolean = false
  public passwordChanged: boolean = false

  public password: string = ''
  public secondPassword: string = ''

  public passwordErr: boolean = false
  public secondPasswordErr: boolean = false

  public secondPasswordErrText: string = ''
  public passwordErrText: string = ''

  constructor(
    private readonly http: HttpClient,
    private readonly managerService: ManagerService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {

  }

  // Сброс пароля - отправка запроса
  async changePassword(): Promise<any> {
    this.passwordChangeLoading = true
    this.clearErrors()
    if (this.password !== this.secondPassword) {
      this.secondPasswordErr = true
      this.secondPasswordErrText = 'Пароли отличаются друг от друга'
      this.passwordChangeLoading = false
      return;
    }
    const json: PasswordProfileComponentInterface = {
      password: this.password
    }
    await this.http.patch<SuccessResponseInterface>('/api/manager/profile/change-password', json).toPromise()
      .then((data) => {
        this.passwordChangeLoading = false
        this.passwordChanged = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.passwordChangeLoading = false
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Пароль должен содержать минимум 6 символов') {
              this.passwordErr = true
              this.passwordErrText = key
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          this.toastr.error(err.error.message, 'Ошибка');
          return;
        }
      })
  }

  // Сбросить ошибки
  clearErrors(): void {
    this.passwordChanged = false
    this.passwordErr = false
    this.secondPasswordErr = false
    this.secondPasswordErrText = ''
    this.passwordErrText = ''
  }

  clear(): void {
    this.password = ''
    this.secondPassword = ''
    this.clearErrors()
  }
}
