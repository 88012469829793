<div class="antialiased border-top-wide border-primary flex-column max-height">
<div class="flex-fill d-flex flex-column justify-content-center py-4 max-height">
  <div class="container-tight py-6">
    <div class="text-center mb-4">
      <a href=""><img src="../../../assets/static/logo.svg" height="50" alt=""></a>
    </div>
    <form class="card card-md" action="" method="get" autocomplete="off">
      <div class="card-body">
        <h2 class="card-title text-center mb-4">Вход в ваш аккаунт</h2>
        <div class="mb-3">
          <label class="form-label">Email</label>
          <input name="email" [(ngModel)]="email" type="text" [classList]="!isEmailErr ? 'form-control' : 'form-control is-invalid'" placeholder="Введите email">
          <div class="invalid-feedback">{{emailErr}}</div>
        </div>
        <div class="mb-3">
          <label class="form-label">
            Пароль
            <span class="form-label-description">
                  <a routerLink="/forgot">Сброс пароля</a>
                </span>
          </label>
            <input name="password" [(ngModel)]="password" type="password" [classList]="!isPasswordErr ? 'form-control' : 'form-control is-invalid'"  placeholder="Пароль"  autocomplete="off">
            <div class="invalid-feedback">{{passwordErr}}</div>
        </div>
        <div class="form-footer">
          <button [classList]="!isLogging ? 'btn btn-primary w-100' : 'btn btn-primary w-100 disabled'" type="submit" (click)="login()">
            <span *ngIf="isLogging" class="spinner-border spinner-border-sm me-2" role="status"></span>
            {{isLogging ? 'Авторизация' : 'Авторизоваться'}}
          </button>
        </div>
      </div>
    </form>
    <div class="text-center text-muted mt-3">
      Регистрация отключена для данного сервиса
    </div>
  </div>
</div>
</div>
