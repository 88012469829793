<div class="antialiased border-top-wide border-primary flex-column max-height">
<div class="flex-fill d-flex flex-column justify-content-center py-4 max-height">
  <div class="container-tight py-6">
    <div class="empty">
      <div class="empty-header">404</div>
      <p class="empty-title">Упс… Страница не найдена</p>
      <p class="empty-subtitle text-muted">
        Страница на которую вы пытаетесь обратиться не найдена
      </p>
      <div class="empty-action">
        <a href="javascript:void(0)" routerLink="/dashboard" class="btn btn-primary">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="5" y1="12" x2="19" y2="12" /><line x1="5" y1="12" x2="11" y2="18" /><line x1="5" y1="12" x2="11" y2="6" /></svg>
          Главная страница
        </a>
      </div>
    </div>
  </div>
</div>
</div>
