<div class="modal-header">
  <h5 class="modal-title">Создание чека</h5>
  <button type="button" (click)="bsModalRef.hide()" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
</div>
<div class="modal-body">
  <div *ngIf="isInfo" class="alert alert-important alert-info alert-dismissible" role="alert">
    <div class="d-flex">
      <div>
      </div>
      <div>
        {{infoText}}
      </div>
    </div>
  </div>
  <div *ngIf="isWarning" class="alert alert-important alert-warning alert-dismissible" role="alert">
    <div class="d-flex">
      <div>
      </div>
      <div>
        {{warningText}}
      </div>
    </div>
  </div>
  <div *ngIf="isErr" class="alert alert-important alert-danger alert-dismissible" role="alert">
    <div class="d-flex">
      <div>
      </div>
      <div>
        {{errText}}
      </div>
    </div>
  </div>
  <div *ngIf="!isOk" class="mb-3">
    <label class="form-label">Ссылка на чек</label>
    <div class="input-icon">
      <input [class.disabled]="disabled" [(ngModel)]="url" (ngModelChange)="changeModel($event)" type="text"
             class="form-control" placeholder="Вставьте ссылку на чек">
      <span class="input-icon-addon">
        <div *ngIf="disabled" class="spinner-border spinner-border-sm text-muted" role="status"></div>
      </span>
    </div>
  </div>
  <div *ngIf="isOk" class="text-center py-4">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-green icon-lg" width="24" height="24"
         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
         stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <circle cx="12" cy="12" r="9"></circle>
      <path d="M9 12l2 2l4 -4"></path>
    </svg>
    <h3>Чек зарегистрирован</h3>
    <div class="text-muted">Чек от <b>{{operationTime | date: 'dd MMMM yyyy HH:mm' :'GMT+03:00'}}</b> на сумму
      <b>{{totalAmount}}</b> самозанятого <b>{{description}}</b> от организации <b>{{clientDisplayName}}</b> был успешно
      зарегистрирован
    </div>
  </div>
  <div class="modal-footer p-0">
    <div class="w-100 m-0">
      <div class="row">
        <div class="col">
          <a href="javascript:void(0)" (click)="bsModalRef.hide()" class="btn btn-primary w-100" data-bs-dismiss="modal">
            Закрыть
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
