<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">

        <div class="container-xl">
          <div class="page-header d-print-none">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="page-title">
                  Записи звонков
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <div class="row">
              <div class="col-lg-4 mb-3">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">Фильтр</h3>
                    <div class="form-floating mb-3">
                      <input prefix="+7" mask="0000000000" [dropSpecialCharacters]="false" [class.is-invalid]="isIncomingPhoneErr" type="text" [disabled]="isLoading" [(ngModel)]="incomingNumber" class="form-control"
                             autocomplete="off">
                      <label>Внешний номер</label>
                      <div class="invalid-feedback">{{incomingPhoneErrText}}</div>
                    </div>
                    <div class="form-floating mb-3">
                      <input [class.is-invalid]="isInternalPhoneErr" [disabled]="isLoading" mask="000" type="text" [(ngModel)]="internalNumber"
                             class="form-control" autocomplete="off">
                      <label>Внутренний номер</label>
                      <div class="invalid-feedback">{{internalPhoneErrText}}</div>
                    </div>
                    <div class="form-floating mb-3">
                      <input [class.is-invalid]="isDateErr" class="form-control"
                             type="text"
                             placeholder=""
                             ngModel
                             bsDaterangepicker
                             [disabled]="isLoading"
                             [isDisabled]="isLoading"
                             [bsConfig]="bsConfig"
                             [(ngModel)]="date"
                             placement="right"
                             [minDate]="minDate"
                             [maxDate]="maxDate">
                      <label>Дата звонка</label>
                      <div class="invalid-feedback">{{dateErrText}}</div>
                    </div>
                    <div class="form-floating mb-3">
                      <input [class.is-invalid]="isDurationErr" [disabled]="isLoading" type="text" [(ngModel)]="duration" class="form-control"
                             autocomplete="off">
                      <label>Длительность (сек)</label>
                      <div class="invalid-feedback">{{durationErrText}}</div>
                    </div>
                    <div class="form-floating mb-3">
                      <select [disabled]="isLoading" class="form-select">
                        <option selected="">Успешный</option>
                      </select>
                      <label>Состояние звонка</label>
                    </div>
                    <div class="form-floating mb-3">
                      <select [disabled]="isLoading" [(ngModel)]="limit" class="form-select">
                        <option value="10">10</option>
                        <option value="30">30</option>
                        <option value="100">100</option>
                      </select>
                      <label>Лимит</label>
                    </div>
                    <div class="mt-2">
                      <a (click)="load()" [class.disabled]="isLoading" href="javascript:void(0)"
                         class="btn btn-primary w-100">
                        Поиск
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">

                <div *ngIf="firstRun" class="empty">
                  <div class="empty-img"><img src="/assets/static/illustrations/undraw_quitting_time_dm8t.svg" height="128" alt="">
                  </div>
                  <p class="empty-title">Звонки вы можете найти здесь</p>
                  <p class="empty-subtitle text-muted">
                    Введите внешний номер, внутренний номер, укажите интервал звонка, его длительность и лимит записей для вывода звонков
                  </p>
                  <div class="empty-action">
                  </div>
                </div>

                <div *ngIf="isLoading" class="empty">
                  <div class="spinner-grow text-blue" role="status"></div>
                </div>

                <div *ngIf="!isLoading && items.length == 0 && !firstRun" class="empty">
                  <div class="empty-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><line x1="9" y1="10" x2="9.01" y2="10"></line><line x1="15" y1="10" x2="15.01" y2="10"></line><path d="M9.5 15.25a3.5 3.5 0 0 1 5 0"></path></svg>
                  </div>
                  <p class="empty-title">Не найдено</p>
                  <p class="empty-subtitle text-muted">
                    По вашему запросу ничего не найдено. Попробуйте использовать другие данные для фильтра
                  </p>
                </div>

                <div *ngIf="items.length !== 0" class="card">
                  <div class="list-group card-list-group">

                    <div *ngFor="let item of items; let index = index" class="list-group-item">
                      <div class="row g-2 align-items-center">
                        <div class="col-auto text-h4">
                          {{index + 1}}
                        </div>
                        <div class="col-auto">
                          <a href="javascript:void(0)">
                            <span *ngIf="item.recordExist"class="avatar rounded-circle bg-azure-lt">
                              <svg (click)="playAudio(item)" *ngIf="playing !== item.uniqueid" xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                   viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                   stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                        fill="none"/><path
                                d="M7 4v16l13 -8z"/></svg>
                              <svg *ngIf="playing === item.uniqueid" (click)="pause(item)" xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                   viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                   stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                        fill="none"/><rect x="6" y="5"
                                                                                                           width="4"
                                                                                                           height="14"
                                                                                                           rx="1"/><rect
                                x="14" y="5" width="4" height="14" rx="1"/></svg>
                            </span>
                            <span (click)="recordNotFound(item)" *ngIf="!item.recordExist" class="avatar rounded-circle bg-red-lt">
	                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                 stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                      fill="none"/><line x1="3" y1="3"
                                                                                                         x2="21"
                                                                                                         y2="21"/><path
                              d="M7 3h7l5 5v7m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-14"/></svg>
                          </span>
                          </a>
                        </div>
                        <div class="col">
                          <!-- Download SVG icon from http://tabler-icons.io/i/phone-outgoing -->
                          <svg *ngIf="getDialType(item) === 'internal'" xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /><line x1="15" y1="9" x2="20" y2="4" /><polyline points="16 4 20 4 20 8" /></svg>
                          <!-- Download SVG icon from http://tabler-icons.io/i/phone-incoming -->
                          <svg *ngIf="getDialType(item) === 'external'" xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /><line x1="15" y1="9" x2="20" y2="4" /><polyline points="15 5 15 9 19 9" /></svg>
                          <!-- Download SVG icon from http://tabler-icons.io/i/phone-calling -->
                          <svg *ngIf="getDialType(item) === 'err'" xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /><line x1="15" y1="7" x2="15" y2="7.01" /><line x1="18" y1="7" x2="18" y2="7.01" /><line x1="21" y1="7" x2="21" y2="7.01" /></svg>
                          {{item.dcontext == 'ext-local' ? getFormatNumber(item.src) : getFormatNumber(item.dst)}}
                          <div class="text-muted">
                            {{formatDate(item.calldate)}} {{getDialTypeText(item)}} <a (click)="openModalWithComponent(item.manager)" href="javascript:void(0)">{{item.dcontext == 'ext-local' ? getManagerName(item.manager, item.dst) : getManagerName(item.manager, item.cnum)}}</a>
                          </div>
                        </div>
                        <div *ngIf="item.recordExist" class="col-auto text-muted mb-1">
                          <a href="javascript:void(0)" (click)="downloadAudio(item)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><polyline points="7 11 12 16 17 11" /><line x1="12" y1="4" x2="12" y2="16" /></svg>
                          </a>
                          </div>
                        <div class="col-auto text-muted mb-1">
                          {{getTimer(item.duration)}}
                        </div>
                        <div *ngIf="playing === item.uniqueid" class="">
                          <a href="javascript:void(0)">
                          <div name="progress-primary" class="progress" (click)="changeCurrentTime($event)">
                            <div name="progress-bar" class="progress-bar" [style.width.%]="currentAudioPrc" role="progressbar" aria-valuemax="100">
                              <span class="visually-hidden">{{currentAudio}}% выполнено</span>
                            </div>
                          </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>

  </div>
</div>
