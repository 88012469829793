<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">RDP настройка</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="bsModalRef.hide()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label class="form-label">Сервер</label>
      <input type="text" class="form-control" [(ngModel)]="server" placeholder="Имя пользователя в Windows">
    </div>
    <div class="mb-3">
      <label class="form-label">Имя пользователя</label>
      <input type="text" class="form-control" [(ngModel)]="user" placeholder="Имя пользователя в Windows">
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label class="form-label">Сотрудник</label>
          <select class="form-select" [(ngModel)]="managerId">
            <option *ngFor="let key of managers; let i = index" [value]="key.id">{{key.name}} {{key.surname}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a href="javascript:void(0)" (click)="bsModalRef.hide()" class="btn btn-link link-secondary" data-bs-dismiss="modal">
      Отмена
    </a>
    <a href="javascript:void(0)" (click)="create()" class="btn btn-primary ms-auto" data-bs-dismiss="modal">
      Создать
    </a>
  </div>
</div>
