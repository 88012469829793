import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {RolesManagerInterface} from "../../../../../../app-nestjs/src/modules/manager/controllers/roles/roles.manager.interface";
import {Carcase} from "../../../../../../app-nestjs/src/modules/carcase/carcase.interface";

@Component({
  selector: 'app-carcases-list',
  templateUrl: './list.carcase.component.html',
  styleUrls: ['./list.carcase.component.scss']
})
export class ListCarcaseComponent implements OnInit {

  public isLoading: boolean = true
  public carcases: Carcase.CarcaseInterface[] = []

  constructor(
    private readonly http: HttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    await this.load()
  }

  private async load(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/carcases/list', {}).toPromise()
      .then((data) => {
        this.carcases = data.data as Carcase.CarcaseInterface[]
        this.isLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }

  public getName(name: string) {
    return  name[0]
  }

  public getRandom(): string {
    const array = [
      'bg-blue-lt',
      'bg-azure-lt',
      'bg-indigo-lt',
      'bg-purple-lt',
      'bg-pink-lt',
      'bg-red-lt',
      'bg-orange-lt',
      'bg-yellow-lt',
      'bg-lime-lt',
      'bg-green-lt',
      'bg-teal-lt',
      'bg-cyan-lt'
    ]
    return  array[Math.floor(Math.random()*array.length)];
  }

}
