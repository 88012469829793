import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ManagerService} from "../../../../services/manager.service";
import {MainEditManagerComponentInterface} from "./main.edit.manager.component.interface";
import {SuccessResponseInterface} from "../../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../../interfaces/error-response.interface";
import {GenderEnum} from "../../../../../../../app-nestjs/src/constants/gender.enum";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {ManagerComponentService} from "../../manager.component.service";

@Component({
  selector: 'app-main-edit-manager-profile',
  templateUrl: './main.edit.manager.component.html',
  styleUrls: ['./main.edit.manager.component.scss']
})
export class MainEditManagerComponent implements OnInit {

  public readonly genderEnum = GenderEnum

  public isLoading: boolean = true

  public isProfileUpdated: boolean = false
  public isProfileUpdatedError: boolean = false

  public isUpdatingLoading: boolean = false

  public id: string | undefined = undefined;

  public email: string = ""
  public name: string = ""
  public surname: string = ""
  public patronymic: string = ""
  public gender: string = ""
  public mobile: string = ""
  public addNum: string = ""
  public position: string = ""
  public role: string = ""

  public isEmailError: boolean = false
  public isNameError: boolean = false
  public isSurnameError: boolean = false
  public isGenderError: boolean = false
  public isPatronymicError: boolean = false
  public isMobileError: boolean = false
  public isAddNumError: boolean = false
  public isPositionError: boolean = false
  public isRoleError: boolean = false

  public emailErrorText: string = ""
  public nameErrorText: string = ""
  public surnameErrorText: string = ""
  public genderErrorText: string = ""
  public patronymicErrorText: string = ""
  public mobileErrorText: string = ""
  public addNumberErrorText: string = ""
  public positionErrorText: string = ""
  public roleErrorText: string = ""

  constructor(
    private readonly managerService: ManagerService,
    private readonly http: HttpClient,
    private route: ActivatedRoute,
    public readonly managerComponentService: ManagerComponentService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe((data) => {
        this.id = data
      });
    await this.getDefault()
  }

  private clearErrors(): void {
    this.isEmailError = false
    this.isNameError = false
    this.isSurnameError = false
    this.isGenderError = false
    this.isPatronymicError = false
    this.isMobileError = false
    this.isAddNumError = false
    this.isPositionError = false
    this.isRoleError = false

    this.emailErrorText = ""
    this.nameErrorText = ""
    this.surnameErrorText = ""
    this.genderErrorText = ""
    this.patronymicErrorText = ""
    this.mobileErrorText = ""
    this.addNumberErrorText = ""
    this.positionErrorText = ""
    this.roleErrorText = ""

    this.isProfileUpdated = false
    this.isProfileUpdatedError = false
  }

  public async getDefault(): Promise<void> {
    this.isLoading = true
    await this.managerComponentService.loadProfile(this.id as string)
    this.email = this.managerComponentService.manager?.email as string
    this.name = this.managerComponentService.manager?.name as string
    this.surname = this.managerComponentService.manager?.surname as string
    this.patronymic = this.managerComponentService.manager?.patronymic as string
    this.gender = this.managerComponentService.manager?.gender as string
    this.mobile = this.managerComponentService.manager?.mobile as string
    this.addNum = this.managerComponentService.manager?.addNum as string
    this.position = this.managerComponentService.manager?.position as string
    this.role = this.managerComponentService.manager?.role as string
    this.isLoading = false
  }

  public async updateProfile(): Promise<void> {
    this.isUpdatingLoading = true
    this.isProfileUpdated = false
    this.isProfileUpdatedError = false
    this.clearErrors()
    if (this.mobile.length == 11) {
      this.mobile = '+' + this.mobile
    }
    const json: MainEditManagerComponentInterface = {
      email: this.email,
      addNum: this.addNum,
      gender: this.gender,
      id: this.id as string,
      mobile: this.mobile,
      name: this.name,
      patronymic: this.patronymic,
      position: this.position,
      role: this.role,
      surname: this.surname
    }
    await this.http.patch<SuccessResponseInterface>('/api/manager/managers/update', json).toPromise()
      .then(async (data) => {
        this.isUpdatingLoading = false
        this.isProfileUpdated = true
        await this.managerComponentService.loadProfile(this.id as string)
      })
      .catch((err: ErrorResponseInterface) => {
        this.isProfileUpdatedError = true
        this.isUpdatingLoading = false
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key == 'Емейл указан неверно') {
              this.isEmailError = true
              this.emailErrorText = key
            }
            if (key == 'Добавочный номер должен состоять из 3 цифр') {
              this.isAddNumError = true
              this.addNumberErrorText = key
            }
            if (key == 'Добавочный номер должен состоять из цифр') {
              this.isAddNumError = true
              this.addNumberErrorText = key
            }
            if (key == 'Пол указан неверно') {
              this.isGenderError = true
              this.genderErrorText = key
            }
            if (key == 'Неверно указан id менеджера') {
              this.isEmailError = true
              this.emailErrorText = key
            }
            if (key == 'Телефон указан неверно') {
              this.isMobileError = true
              this.mobileErrorText = key
            }
            if (key == 'Имя указано неверно') {
              this.isNameError = true
              this.nameErrorText = key
            }
            if (key == 'Отчество указано неверно') {
              this.isPatronymicError = true
              this.patronymicErrorText = key
            }
            if (key == 'Должность указана неверно') {
              this.isPositionError = true
              this.positionErrorText = key
            }
            if (key == 'Роль сотрудника указана неверно') {
              this.isRoleError = true
              this.roleErrorText = key
            }
            if (key == 'Фамилия указана неверно') {
              this.isSurnameError = true
              this.surnameErrorText = key
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          if (err.error.message == 'Уже существует менеджер с таким email') {
            this.isEmailError = true
            this.emailErrorText = err.error.message
          }
          if (err.error.message == 'Уже существует менеджер с таким мобильным номером') {
            this.isMobileError = true
            this.emailErrorText = err.error.message
          }
          if (err.error.message == 'Уже существует менеджер с таким добавочным номером') {
            this.isAddNumError = true
            this.addNumberErrorText = err.error.message
          }
          return;
        }
      })
  }

}
