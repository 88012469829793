import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ManagerService} from "../../services/manager.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {LoginComponentInterface} from "./login.component.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public email: string = ""
  public password: string = ""
  public isLogging: boolean = false
  public isEmailErr: boolean = false
  public isPasswordErr: boolean = false
  public emailErr: string = ""
  public passwordErr: string = ""


  public isErr: boolean = false
  public err: string = ""

  constructor(
    private readonly managerService: ManagerService,
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  clearErrors(): void {
    this.isPasswordErr = false
    this.isEmailErr = false
    this.emailErr = ''
    this.passwordErr = ''
    this.isErr = false
    this.err = ''
  }

  // Логин пользователя
  async login(): Promise<any> {
    this.isLogging = true
    const login: LoginComponentInterface = {
      email: this.email,
      password: this.password
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/auth/login', login).subscribe(
      (data) => {
        this.clearErrors()
        if (data.message == 'Успешная авторизация' && typeof data.auth?.token !== 'undefined') {
          const setToken = this.managerService.setToken(data.auth?.token)
          if (setToken) {
            this.router.navigateByUrl('/')
            return;
          }
        }
        this.toastr.error('Во время работы сервиса произошла ошибка', 'Ошибка');
      }, (error: ErrorResponseInterface) => {
        this.clearErrors()
        this.isLogging = false
        // Если это массив ошибок
        if (Array.isArray(error?.error?.message)) {
            for (const key of error?.error?.message) {
              if (key == 'Неверно указан емейл') {
                  this.isEmailErr = true
                  this.emailErr = key
              }
              if (key == 'Пароль не может содержать менее 6 символов') {
                  this.isPasswordErr = true
                  this.passwordErr = key
              }
            }
            return;
        }
        // Если это ошибка
        if (typeof error?.error?.message === 'string') {
            if (error?.error.message == 'Неверный логин или пароль') {
              this.isEmailErr = true
              this.emailErr = 'Неверный логин или пароль'
              return;
            }
          this.toastr.error(error?.error.message, 'Ошибка');
          }
      }
    )
  }

}
