import { Component, Input, OnInit } from '@angular/core';
import {Manager} from "../../../../../../app-nestjs/src/modules/manager/manager.interface";
import {BsModalRef} from "ngx-bootstrap/modal";
import {HttpClient} from "@angular/common/http";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-create-rdp',
  templateUrl: './create.rdp.component.html',
  styleUrls: ['./create.rdp.component.scss']
})
export class CreateRdpComponent implements OnInit {

  public managers: Manager.ManagerInterface[] = []
  public errText: string = ''

  public user: string = ''
  public server: string = '195.43.95.151'
  public managerId: string = ''

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpClient,
    public readonly toastr: ToastrService,
  ) { }

  ngOnInit(): void {

  }

  public async create(): Promise<void> {
    let json = {
      user: this.user,
      server: this.server,
      managerId: this.managerId
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/rdp/create', {...json}).toPromise()
      .then((data) => {
        this.toastr.clear()
        this.toastr.info('RDP настройка создана', 'Успешно')
        this.bsModalRef.hide()
      })
      .catch((err: ErrorResponseInterface) => {
        this.toastr.clear()
        this.toastr.error(err.error.message as string, 'Ошибка создания')
      })
  }
}
