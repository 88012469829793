<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div *ngIf="constService.get(constService.itemTypes.rdpAdmonition)" class="alert alert-important alert-info alert-dismissible" role="alert">
                <div class="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><line x1="12" y1="8" x2="12.01" y2="8"></line><polyline points="11 12 12 12 12 16 13 16"></polyline></svg>
                  </div>
                  <div>
                    Список настроек пользователя для подключения к удаленному рабочему столу Windows. Необходимо добавить наименование пользователя и сервер, чтобы пользователь смог управлять своими сессиями и подключаться к удаленному рабочему столу
                  </div>
                </div>
                <a (click)="constService.add(constService.itemTypes.rdpAdmonition)" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
              </div>
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="row align-items-center mw-100">
                    <div class="col">
                      <div class="mb-1">
                        <ol class="breadcrumb breadcrumb-alternate" aria-label="breadcrumbs">
                          <li *ngFor="let key of titleService.getNavi()" class="breadcrumb-item" [class.active]="key.current">
                            <a [routerLink]="['', key.url]">{{key.title}}</a>
                          </li>
                        </ol>
                      </div>
                      <h2 class="page-title">
                        Настройки RDP подключений
                      </h2>
                    </div>
                    <div class="col-auto ms-auto d-print-none">
                      <div class="btn-list">
                        <a href="javascript:void(0)" (click)="openModalWithComponent()" class="btn btn-primary d-none d-sm-inline-block">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                          Создать
                        </a>
                        <a href="javascript:void(0)" (click)="openModalWithComponent()" class="btn btn-primary d-sm-none btn-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>


                <div class="col-md-12">
                  <div class="card mb-3">
                    <div class="card-header">
                      <h3 class="card-title">Список</h3>
                    </div>
                    <div class="list-group list-group-flush list-group-hoverable">
                      <div *ngFor="let item of rdps" class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <a href="javascript:void(0)">
                              <span [classList]="true ? item.val + ' avatar'  : 'avatar'" style="">
	                              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="4" y1="12" x2="20" y2="12" /></svg>
                              </span>
                            </a>
                          </div>
                          <div class="col text-truncate">
                            <a href="javascript:void(0)" class="text-body d-block">{{item?.user}} ({{item?.manager.name}} {{item?.manager.surname}})</a>
                            <small class="d-block text-muted text-truncate mt-n1">Сервер: {{item?.server.toUpperCase()}}</small>
                          </div>
                          <div class="col-auto">
                            <div class="dropdown">
                              <a href="#" class="card-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="19" r="1"></circle><circle cx="12" cy="5" r="1"></circle></svg>
                              </a>
                              <div class="dropdown-menu dropdown-menu-end" style="">
                                <a href="javascript:void(0)" class="dropdown-item">Обновить</a>
                                <a href="javascript:void(0)" (click)="remove(item.id)" class="dropdown-item">Удалить</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
