<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="container-xl">
          <div class="page-header d-print-none">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="page-title">
                  Регистрация сотрудника
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <div class="row row-cards">

              <div class="col-md-12">

                <div *ngIf="isCreated" class="alert alert-important alert-success alert-dismissible" role="alert">
                  <div class="d-flex">
                    <div>
                    </div>
                    <div>
                      Сотрудник зарегистрирован
                    </div>
                  </div>
                  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
                </div>

                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Новый сотрудник</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Емейл</label>
                      <div class="col">
                        <input [(ngModel)]="email" [class.is-invalid]="isEmailErr" type="email" class="form-control"
                               placeholder="Введите email">
                        <small *ngIf="!isEmailErr" class="form-hint">Разные сотрудники не могут иметь одинаковые
                          емейлы</small>
                        <div class="invalid-feedback">{{emailErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Фамилия</label>
                      <div class="col">
                        <input [(ngModel)]="surname" [class.is-invalid]="isSurnameErr" type="text" class="form-control"
                               placeholder="Фамилия">
                        <small *ngIf="!isSurnameErr" class="form-hint">
                          Фамилия нового сотрудника
                        </small>
                        <div class="invalid-feedback">{{surnameErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Имя</label>
                      <div class="col">
                        <input [(ngModel)]="name" [class.is-invalid]="isNameErr" type="text" class="form-control"
                               placeholder="Имя">
                        <small *ngIf="!isNameErr" class="form-hint">
                          Имя нового сотрудника
                        </small>
                        <div class="invalid-feedback">{{nameErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Отчество</label>
                      <div class="col">
                        <input [(ngModel)]="patronymic" [class.is-invalid]="isPatronymicErr" type="text"
                               class="form-control" placeholder="Отчество">
                        <small *ngIf="!isPasswordErr" class="form-hint">
                          Отчество нового сотрудника
                        </small>
                        <div class="invalid-feedback">{{patronymicErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Мобильный номер</label>
                      <div class="col">
                        <input [(ngModel)]="mobile" [class.is-invalid]="isMobileErr" name="mobile" type="text"
                               mask="00000000000" class="form-control" placeholder="Введите мобильный">
                        <small *ngIf="!isMobileErr" class="form-hint">Номер телефона в формате 7ХХХХХХХХХХ. В системе не
                          может быть несколько одинаковых телефонов</small>
                        <div class="invalid-feedback">{{mobileErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Добавочный номер</label>
                      <div class="col">
                        <input name="addNum" [(ngModel)]="addNum" [class.is-invalid]="isAddNumErr" type="text"
                               mask="000" class="form-control" placeholder="Введите добавочный номер">
                        <small *ngIf="!isAddNumErr" class="form-hint">Внутренний номер телефона для интеграции с
                          телефонией. Не может быть несколько одинаковых добавочных</small>
                        <div class="invalid-feedback">{{addNumErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Пол</label>
                      <div class="col">
                        <select [(ngModel)]="gender" [class.is-invalid]="isGenderErr" class="form-select">
                          <option value="{{genderEnum.male}}">Мужской</option>
                          <option value="{{genderEnum.female}}">Женский</option>
                        </select>
                        <small *ngIf="!isGenderErr" class="form-hint">
                          Укажите пол сотрудника
                        </small>
                        <div class="invalid-feedback">{{genderErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Роль</label>
                      <div class="col">
                        <select [class.is-invalid]="isRoleErr" [(ngModel)]="role" class="form-select">
                          <option value="{{managerRolesEnum.user}}">Пользователь</option>
                          <option value="{{managerRolesEnum.logist}}">Логист</option>
                          <option value="{{managerRolesEnum.sales}}">Сотрудник отдела продаж</option>
                          <option value="{{managerRolesEnum.manager}}">Менеджер по работе с клиентами</option>
                          <option value="{{managerRolesEnum.accountant}}">Бухгалтер</option>
                          <option value="{{managerRolesEnum.director}}">Директор</option>
                          <option value="{{managerRolesEnum.admin}}">Администратор</option>
                        </select>
                        <small *ngIf="!isRoleErr" class="form-hint">
                          Укажите права сотрудника
                        </small>
                        <div class="invalid-feedback">{{roleErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Должность</label>
                      <div class="col">
                        <input [(ngModel)]="position" [class.is-invalid]="isPositionErr" type="text"
                               class="form-control" placeholder="Должность">
                        <small *ngIf="!isPositionErr" class="form-hint">
                          Должность сотрудника
                        </small>
                        <div class="invalid-feedback">{{positionErrText}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Пароль</label>
                      <div class="col">
                        <input [class.is-invalid]="isPasswordErr" [(ngModel)]="password" type="password"
                               class="form-control" placeholder="Пароль">
                        <small *ngIf="!isPasswordErr" class="form-hint">
                          Пароль должен содержать минимум 6 символов
                        </small>
                        <div class="invalid-feedback">{{passwordErrText}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-end bgc-t">
                    <div class="d-flex">
                      <a href="javascript:void(0)" (click)="clear()" class="btn btn-link">Очистить</a>
                      <button [class.disabled]="isRegistering || isCreated" [class.btn-success]="isCreated" [class.btn-danger]="isErr" type="submit" (click)="register()" class="btn btn-primary ms-auto">
                        <span *ngIf="isRegistering" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        {{isCreated ? 'Зарегистрирован' : 'Зарегистрировать'}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
