import {Component, OnInit} from '@angular/core';
import {ManagerService} from "../../../services/manager.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-profile-avatar',
  templateUrl: './avatar.profile.component.html',
  styleUrls: ['./avatar.profile.component.scss']
})
export class AvatarProfileComponent implements OnInit {

  fileToUpload: File | null = null;
  fileUploaded: boolean = false;
  fileAvatarRemoved: boolean = false;
  fileRemoveLoaded: boolean = false;
  fileUploadErr: boolean = false;
  fileUploadErrText: string = '';

  constructor(
    public managerService: ManagerService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
  }

  async handleFileInput(event: Event): Promise<void> {
    this.fileAvatarRemoved = false;
    this.fileUploadErr = false;
    this.fileUploadErrText= '';
    this.fileUploaded = false;
    let target = event.target as HTMLInputElement;
    this.fileToUpload = (target.files as FileList)[0];
    const formData: FormData = new FormData();
    formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
    return await this.http.patch('/api/manager/profile/upload-avatar', formData).toPromise()
      .then(async (data) => {
        await this.managerService.getProfile()
        this.fileUploaded = true
      }).catch(async (err: any) => {
        if (err.error.message == 'Accept only images(png, jpeg, jpg)') {
          this.fileUploaded = false
          this.fileUploadErr = true
          this.fileUploadErrText = 'Поддерживаются только форматы изображений: png, jpeg'
        }
      })
  }

  // Удалить аватар
  async removeAvatar(): Promise<void> {
    this.fileUploadErr = false;
    this.fileUploaded = false;
    this.fileAvatarRemoved = false;
    this.fileRemoveLoaded = true;
    await this.http.delete('/api/manager/profile/remove-avatar').toPromise()
      .then(async (response) => {
        await this.managerService.getProfile()
        this.fileRemoveLoaded = false;
        this.fileAvatarRemoved = true;
      })
      .catch(async (err) => {
        await this.managerService.getProfile()
        this.fileRemoveLoaded = false;
        this.fileAvatarRemoved = false;
      })
  }


}
