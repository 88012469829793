export interface EnvPrintInterface {
    "fromOrg": string,
    "fromIndex": string,
    "fromAddress": string,
    "envSize": EnvSizeEnum,
    "destOrg": string,
    "destIndex": string,
    "destAddress": string,
    "envType": EnvTypeEnum
}

export enum EnvSizeEnum {
    'c4' = 'c4',
    'c5' = 'c5',
    'c4_without' = 'c4_without',
    'c5_without' = 'c5_without'
}

export enum EnvTypeEnum {
    'regular' = 'regular',
    'ordered' = 'ordered',
    'courier' = 'courier'
}
