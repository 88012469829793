<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="container-xl">
            <div class="row row-cards">

              <div *ngIf="!isLoading" class="container-xl">
                <div class="page-header d-print-none">
                  <div class="row align-items-center">
                    <div class="col">
                      <h2 class="page-title">
                        Категории транспорта
                      </h2>
                      <div class="text-muted mt-1">Всего {{categories.length}} категорий(-ия) транспорта</div>
                    </div>
                    <div class="col-auto ms-auto d-print-none">
                      <div class="btn-list">
                        <a href="javascript:void(0)" [routerLink]="['', 'categories', 'create']" class="btn btn-primary d-none d-sm-inline-block">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                          Создать
                        </a>
                        <a href="javascript:void(0)" [routerLink]="['', 'categories', 'create']" class="btn btn-primary d-sm-none btn-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isLoading" class="container-xl">
                <div class="loader loader-center"></div>
              </div>

              <div *ngIf="!isLoading" class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Список</h3>
                  </div>
                  <div class="table-responsive" style="margin-bottom: 0rem;">
                    <table class="table card-table table-vcenter text-nowrap datatable">
                      <thead>
                      <tr>
                        <th class="w-1">№</th>
                        <th>Наименование</th>
                        <th>Синоним</th>
                        <th>Сокр.</th>
                        <th>Вместительность</th>
                        <th>Объем</th>
                        <th>Длина</th>
                        <th>Ширина</th>
                        <th>Высота</th>
                        <th></th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let key of categories; let index = index">
                        <td><span class="text-muted"><small>{{index + 1}}</small></span></td>
                        <td><small>{{key.description}}</small></td>
                        <td>
                          <small>{{key.synonym}}</small>
                        </td>
                        <td>
                          <small>{{key.shortName}}</small>
                        </td>
                        <td>
                          <small>{{key.capacity}} кг</small>
                        </td>
                        <td>
                          <small>{{key.volume / 1000000}} м3</small>
                        </td>

                        <td>
                          <small>{{key.length / 100}} м</small>
                        </td>
                        <td>
                          <small>{{key.width / 100}} м</small>
                        </td>
                        <td>
                          <small>{{key.height / 100}} м</small>
                        </td>
                        <td>
                        </td>
                        <td class="text-end">
                            <span class="dropdown">
                              <button class="btn dropdown-toggle align-text-top" data-bs-boundary="viewport"
                                      data-bs-toggle="dropdown" aria-expanded="false"><small>Действия</small></button>
                              <div class="dropdown-menu dropdown-menu-end" style="">
                                <a class="dropdown-item" href="javascript:void(0)" [routerLink]="['', 'categories', 'edit', key.id]">
                                  <small>Редактировать</small>
                                </a>
                              </div>
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
