import {Manager} from "../manager/manager.interface";

export namespace Admonition {
    export namespace ManagerAdmonition {

        export enum ItemTypes {
            'statusAdmonition' = 'statusAdmonition',
            'palletsAdmonition' = 'palletsAdmonition',
            'rdpAdmonition' = 'rdpAdmonition',
            'rolesAdmonition' = 'rolesAdmonition',
            'counterpartyTypesAdmonition' = 'counterpartyTypesAdmonition',
            'banksAdmonition' = 'banksAdmonition',
            'serviceTypesAdmonition' = 'serviceTypesAdmonition',
            'selfEmployedCheckAdmonition' = 'selfEmployedCheckAdmonition',
            'currencyAdmonition' = 'currencyAdmonition',
            'statesAdmonition' = 'statesAdmonition',
            'polygonsAdmonition' = 'polygonsAdmonition'
        }

        export type id = string
        export type item = ItemTypes
        export type value = boolean
        export type createdAt = Date
        export type updatedAt = Date
        export type managerId = Manager.id

        export interface ManagerAdmonitionInterface {
            id: Admonition.ManagerAdmonition.id,
            item: Admonition.ManagerAdmonition.item,
            value: Admonition.ManagerAdmonition.value,
            createdAt?: Admonition.ManagerAdmonition.createdAt,
            updatedAt?: Admonition.ManagerAdmonition.createdAt,
            managerId: Admonition.ManagerAdmonition.managerId
        }
    }

    export namespace ClientAdmonition {

        export interface ClientAdmonitionInterface {

        }

    }

    export namespace DriverAdmonition {

        export interface ClientAdmonitionInterface {

        }
    }
}
