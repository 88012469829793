import {Component, OnDestroy, OnInit} from '@angular/core';
import { ManagerService } from "../../services/manager.service";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {MenuItems} from "../../app-routing.module";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {


  public menuItems = MenuItems

  constructor(
    public readonly managerService: ManagerService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  public isMenuActive(item: MenuItems): boolean {
    const data: Data = this.activatedRoute.snapshot.data;
    if (item === data.menu as MenuItems) {
      return true
    }
    return false;
  }

}
