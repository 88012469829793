import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {ChangePasswordForgotComponentInterface, ForgotComponentInterface} from "./forgot.component.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {error} from "protractor";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  email: string = "";
  emailCode: string = '';
  password: string = '';
  secondPassword: string = '';

  isForgotLoading: boolean = false;
  isPasswordLoading: boolean = false;

  isErrEmail: boolean = false;
  isPasswordChangeErr: boolean = false;
  isEmailCodeErr: boolean = false;

  isErrEmailText: string = "";
  isPasswordChangeErrText: string = "";
  emailCodeTextErr: string = "";

  // Какой текущий компонент запущен
  isSendForgotComponent: boolean = true;
  isPasswordComponents: boolean = false;
  isChangedComponent: boolean = false;

  progress: number = 0

  constructor(
    private http: HttpClient,
    private router: Router,
    private titleService: TitleService
  ) {
  }

  ngOnInit(): void {
    this.titleService.set('Сброс пароля')
  }


  // Кнопка сброса пароля
  async sendForgotComponent(): Promise<void> {
    this.isForgotLoading = true;
    this.isErrEmail = false;
    this.isErrEmailText = ''

    let body: ForgotComponentInterface = {
      email: this.email
    }

    // Отправка запроса на сервер
    return await this.http.post<SuccessResponseInterface>('/api/manager/auth/send-forgot-code', body).toPromise()
      .then(async (data) => {
        this.isForgotLoading = false;
        this.setCurrentComponent('passwordComponent')
      })
      .catch(async (error: ErrorResponseInterface) => {
        this.isForgotLoading = false;
        if (Array.isArray(error.error.message)) {
          for (const key of error.error.message) {
            if (key === 'Емейл указан неверно') {
              this.isErrEmail = true;
              this.isErrEmailText = key
            }
          }
          return;
        }
        if (typeof error.error.message) {
          this.isErrEmail = true;
          this.isErrEmailText = error.error.message as string
        }
      })
  }

  // Отправить новый пароль
  async sendPassword(): Promise<void> {
    this.isPasswordLoading = true
    this.isPasswordChangeErr = false;
    this.isEmailCodeErr = false;
    this.isPasswordChangeErrText = ''
    this.emailCodeTextErr = ''

    if (this.password !== this.secondPassword) {
      this.isPasswordChangeErr = true;
      this.isPasswordChangeErrText = 'Пароли не совпадают'
      return;
    }

    const body: ChangePasswordForgotComponentInterface = {
      email: this.email,
      password: this.password,
      code: this.emailCode
    }

    await this.http.post<SuccessResponseInterface>('/api/manager/auth/change-password', body).toPromise()
      .then((data) => {
        this.isPasswordLoading = false
        this.setCurrentComponent('changedComponent')
      })
      .catch((error: ErrorResponseInterface) => {
        this.isPasswordLoading = false
          if (Array.isArray(error.error.message)) {
            for (const key of error.error.message) {
              if (key == 'Пароль должен содержать минимум 6 символов') {
                this.isPasswordChangeErr = true
                this.isPasswordChangeErrText = key
              }
              if (key == 'Код содежит только цифры') {
                this.isEmailCodeErr = true
                this.emailCodeTextErr = key
              }
            }
            return;
          }
          if (typeof error.error.message === 'string') {
            if (error.error.message == 'Отправленный вами код указан неверно') {
              this.isEmailCodeErr = true
              this.emailCodeTextErr = 'Отправленный вами код указан неверно'
            }
          }
      })
  }

  // Установить текущий компонент и изменить прогресс
  setCurrentComponent(value: string): void {
    if (value == 'forgotComponent') {
      this.isSendForgotComponent = true;
      this.isPasswordComponents = false;
      this.isChangedComponent = false;
      this.progress = 0;
    }
    if (value == 'passwordComponent') {
      this.isSendForgotComponent = false;
      this.isPasswordComponents = true;
      this.isChangedComponent = false;
      this.progress = 50;
    }
    if (value == 'changedComponent') {
      this.isSendForgotComponent = false;
      this.isPasswordComponents = true;
      this.isChangedComponent = true;
      this.progress = 100;
    }
  }

}
