<header class="navbar navbar-expand-md navbar-light d-print-none">
  <div class="container-xl">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
      <a href="">
        <img src="../../../assets/static/logo.svg" width="110" height="32" alt="AKRO Manager" class="navbar-brand-image">
      </a>
    </h1>
    <div class="navbar-nav flex-row order-md-last">
      <div class="nav-item dropdown d-none d-md-flex me-3">
        <a href="#" class="nav-link px-0" data-bs-toggle="dropdown" tabindex="-1" aria-label="Show notifications">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
               stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"/>
            <path d="M9 17v1a3 3 0 0 0 6 0v-1"/>
          </svg>
          <span class="badge bg-red"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-card">
          <div class="card">
            <div class="card-body">
              Этот компонент еще не готов. Тут будут отображться уведомления
            </div>
          </div>
        </div>
      </div>
      <div class="nav-item dropdown">
        <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">

          <span *ngIf="managerService.manager?.avatar && managerService.avatarBase64" class="avatar avatar-sm"
                [style.background-image]="'url(data:image/jpeg;base64,' + managerService.avatarBase64 + ')'"></span>

          <span *ngIf="!managerService.manager?.avatar"
                class="avatar avatar-sm">{{managerService.getStringAvatar()}}</span>
          <div class="d-none d-xl-block ps-2">
            <div>{{managerService.manager?.name}} {{managerService.manager?.surname}}</div>
            <div class="mt-1 small text-muted">{{managerService.manager?.position}}</div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
          <a routerLink="/profile" href="javascript:void(0)" class="dropdown-item">Профиль</a>
          <div class="dropdown-divider"></div>
          <a routerLink="/logout" href="javascript:void(0)" class="dropdown-item">Выход</a>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="navbar-expand-md">
  <div class="collapse navbar-collapse" id="navbar-menu">
    <div class="navbar navbar-light">
      <div class="container-xl">
        <ul class="navbar-nav">
          <li class="nav-item" routerLinkActive="active" [class.active]="isMenuActive(menuItems.dashboard)" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" routerLink="/dashboard">
                    <span class="nav-link-icon d-md-none d-lg-inline-block"><svg xmlns="http://www.w3.org/2000/svg"
                                                                                 class="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" stroke-width="2"
                                                                                 stroke="currentColor" fill="none"
                                                                                 stroke-linecap="round"
                                                                                 stroke-linejoin="round"><path
                      stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="5 12 3 12 12 3 21 12 19 12"/><path
                      d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/><path
                      d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/></svg>
                    </span>
              <span class="nav-link-title">
                      Главная
                    </span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active" [class.active]="isMenuActive(menuItems.tools)" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" href="javascript:void(0)" routerLink="/tools">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                           stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none"
                                                                                                                  d="M0 0h24v24H0z"
                                                                                                                  fill="none"/><path
                        d="M17 3l4 4l-14 14l-4 -4z"/><path d="M16 7l-1.5 -1.5"/><path d="M13 10l-1.5 -1.5"/><path d="M10 13l-1.5 -1.5"/><path
                        d="M7 16l-1.5 -1.5"/></svg>
                    </span>
              <span class="nav-link-title">
                      Инструменты
                    </span>
            </a>
          </li>
          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" role="button"-->
          <!--               aria-expanded="false">-->
          <!--                    <span class="nav-link-icon d-md-none d-lg-inline-block">&lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/notebook &ndash;&gt;-->
          <!--	<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"-->
          <!--       stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none"-->
          <!--                                                                                              d="M0 0h24v24H0z"-->
          <!--                                                                                              fill="none"/><path-->
          <!--    d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"/><line x1="13" y1="8"-->
          <!--                                                                                                     x2="15" y2="8"/><line-->
          <!--    x1="13" y1="12" x2="15" y2="12"/></svg></span>-->
          <!--              <span class="nav-link-title">-->
          <!--                      Справочники-->
          <!--                    </span>-->
          <!--            </a>-->
          <!--            <div class="dropdown-menu">-->
          <!--              <div class="dropdown-menu-columns">-->
          <!--                <div class="dropdown-menu-column">-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Автомобили-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" routerLink="/drivers">-->
          <!--                    Водители-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" routerLink="/drivers">-->
          <!--                    Сотрудники-->
          <!--                  </a>-->
          <!--                </div>-->
          <!--                <div class="dropdown-menu-column">-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Клиенты-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Пользователи-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Тарифы-->
          <!--                  </a>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </li>-->
          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" role="button"-->
          <!--               aria-expanded="false">-->
          <!--                    <span class="nav-link-icon d-md-none d-lg-inline-block">&lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/notebook &ndash;&gt;-->
          <!--                      &lt;!&ndash; Download SVG icon from http://tabler-icons.io/i/star &ndash;&gt;-->
          <!--	<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"-->
          <!--       stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none"-->
          <!--                                                                                              d="M0 0h24v24H0z"-->
          <!--                                                                                              fill="none"/><path-->
          <!--    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"/></svg></span>-->
          <!--              <span class="nav-link-title">-->
          <!--                      Утилиты-->
          <!--                    </span>-->
          <!--            </a>-->
          <!--            <div class="dropdown-menu">-->
          <!--              <div class="dropdown-menu-columns">-->
          <!--                <div class="dropdown-menu-column">-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Маршрутизатор-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item active" routerLink="/drivers">-->
          <!--                    Доступность-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Дистанция-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Полигоны-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Ближайшая точка-->
          <!--                  </a>-->
          <!--                </div>-->
          <!--                <div class="dropdown-menu-column">-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Калькулятор паллет-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Вместимость груза-->
          <!--                  </a>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </li>-->
          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle" href="#navbar-base" data-bs-toggle="dropdown" role="button"-->
          <!--               aria-expanded="false">-->
          <!--                    <span class="nav-link-icon d-md-none d-lg-inline-block">-->
          <!--	<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><rect x="9" y="3" width="6" height="4" rx="2" /><path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" /><path d="M12 17v1m0 -8v1" /></svg></span>-->
          <!--              <span class="nav-link-title">-->
          <!--                      Бухгалтерия-->
          <!--                    </span>-->
          <!--            </a>-->
          <!--            <div class="dropdown-menu">-->
          <!--              <div class="dropdown-menu-columns">-->
          <!--                <div class="dropdown-menu-column">-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Акты-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Акты сверки-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Банковские аккаунты-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Валюты-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Договоры-->
          <!--                  </a>-->
          <!--                </div>-->
          <!--                <div class="dropdown-menu-column">-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Сканы-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Счета-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    Счета-реестры-->
          <!--                  </a>-->
          <!--                  <a class="dropdown-item" href="javascript:void(0)">-->
          <!--                    УПД-->
          <!--                  </a>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </li>-->
          <li class="nav-item" routerLinkActive="active" [class.active]="isMenuActive(menuItems.catalog)" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" routerLink="/catalog" href="javascript:void(0)" role="button" aria-expanded="false">
              <span class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/database -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                     stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path
                  stroke="none" d="M0 0h24v24H0z" fill="none"/><ellipse cx="12" cy="6" rx="8" ry="3"></ellipse><path
                  d="M4 6v6a8 3 0 0 0 16 0v-6"/><path d="M4 12v6a8 3 0 0 0 16 0v-6"/></svg></span>
              <span class="nav-link-title">
                Каталог
              </span>
            </a>
          </li>
          <li class="nav-item" [hidden]="!isMenuActive(menuItems.profile)" [class.active-profile]="isMenuActive(menuItems.profile)">
            <a class="nav-link" href="javascript:void(0)" role="button" aria-expanded="false">
              <span class="nav-link-icon d-md-none d-lg-inline-block">
	              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="7" r="4" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
              </span>
              <span class="nav-link-title">
                Профиль
              </span>
            </a>
          </li>
          <li class="nav-item" [hidden]="!isMenuActive(menuItems.admin)" [class.active-admin]="isMenuActive(menuItems.admin)">
            <a class="nav-link" href="javascript:void(0)" role="button" aria-expanded="false">
              <span class="nav-link-icon d-md-none d-lg-inline-block">
	              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" /><circle cx="12" cy="11" r="1" /><line x1="12" y1="12" x2="12" y2="14.5" /></svg>
              </span>
              <span class="nav-link-title">
                Администратор
              </span>
            </a>
          </li>
        </ul>
        <div class="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
        </div>
      </div>
    </div>
  </div>
</div>
