<div *ngIf="passwordChanged" class="alert alert-important alert-success alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
      Пароль изменен
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">Смена пароля</h3>
  </div>
  <div class="card-body">
    <div class="form-group mb-3 ">
      <label class="form-label required">Пароль</label>
      <div>
        <input [(ngModel)]="password" [class.is-invalid]="passwordErr" type="password" class="form-control" placeholder="Введите пароль">
        <small *ngIf="!passwordErr" class="form-hint">Пароль должен содержать не менее 6 символов</small>
        <div class="invalid-feedback">{{passwordErrText}}</div>
      </div>
    </div>
    <div class="form-group mb-3 ">
      <label class="form-label required">Повтор пароля</label>
      <div>
        <input [(ngModel)]="secondPassword" [class.is-invalid]="secondPasswordErr" type="password" class="form-control" placeholder="Введите повтор пароля">
        <div class="invalid-feedback">{{secondPasswordErrText}}</div>
      </div>
    </div>
  </div>
  <div class="card-footer text-end bgw">
    <div class="d-flex">
      <a href="javascript:void(0)" (click)="clear()" class="btn btn-link bgc-t">Отмена</a>
      <button [class.disabled]="passwordChangeLoading" (click)="changePassword()" type="submit" class="btn btn-primary ms-auto">
        <span *ngIf="passwordChangeLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
        Сменить пароль
      </button>
    </div>
  </div>
</div>
