import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {ManagerService} from "../../../../services/manager.service";
import {SuccessResponseInterface} from "../../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../../interfaces/error-response.interface";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {WorkStatusEditManagerComponentInterface} from "./work.status.edit.manager.component.interface";
import {ManagerComponentService} from "../../manager.component.service";

@Component({
  selector: 'app-work-status-edit-manager-profile',
  templateUrl: './work.status.edit.manager.component.html',
  styleUrls: ['./work.status.edit.manager.component.scss']
})
export class WorkStatusEditManagerComponent implements OnInit {

  public id: string | undefined = undefined

  public isFiredChangeLoading: boolean = false
  public ifFiredChanged: boolean = false
  public isErr: boolean = false
  public errText: string = ''

  public isFired: boolean = false

  constructor(
    private readonly http: HttpClient,
    private readonly managerService: ManagerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public managerComponentService: ManagerComponentService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe((data) => {
        this.id = data
      });
    await this.managerComponentService.loadProfile(this.id as string)
    this.isFired = this.managerComponentService.manager?.isFired as boolean
  }

  // Сброс пароля - отправка запроса
  async changedIsFired(): Promise<any> {
    this.isFiredChangeLoading = true
    this.isErr = false
    const json: WorkStatusEditManagerComponentInterface = {
      id: this.id as string,
      isFired: this.isFired
    }
    await this.http.patch<SuccessResponseInterface>('/api/manager/managers/setIsFired', json).toPromise()
      .then((data) => {
        this.isFiredChangeLoading = false
        this.ifFiredChanged = true
        this.isErr = false
      })
      .catch((err: ErrorResponseInterface) => {
        this.isFiredChangeLoading = false
        this.isErr = true
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Не указано уволен ли менеджер') {
              this.isErr = true
              this.errText = key
            }
            if (key === 'Указан неверный id менеджера') {
              this.isErr = true
              this.errText = key
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          this.toastr.error(err.error.message, 'Ошибка');
          return;
        }
      })
  }

}
