<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="container-xl">
          <div class="page-header d-print-none">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="page-title">
                  Создание типа кузова
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <div class="row row-cards">

              <div class="col-md-12">

                <div *ngIf="isCreated" class="alert alert-important alert-success alert-dismissible" role="alert">
                  <div class="d-flex">
                    <div>
                    </div>
                    <div>
                      Тип кузова создан
                    </div>
                  </div>
                  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
                </div>

                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Новый тип кузова</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Наименование</label>
                      <div class="col">
                        <input [(ngModel)]="description" type="text" [class.is-invalid]="isErr" class="form-control"
                               placeholder="Введите наименование">
                        <small *ngIf="!isErr" class="form-hint">Не может быть несколько одинаковых наименований</small>
                        <div class="invalid-feedback">{{nameErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Тент</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="awning" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Может ли данный тип кузова производить растентовку</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Ровный пол</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="flatFloor" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Может ли данный тип кузова иметь ровный пол</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Гидроборт</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="tailLift" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Может ли данный тип кузова иметь гидроборт</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Манипулятор</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="manipulator" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Может ли данный тип иметь манипулятор</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Температурный режим</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="temperatureRegime" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Поддерживает ли данный тип кузова температурный режим</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Сан обработка</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="sanitization" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Может ли данный тип кузова подвергаться сан обработке</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Катюша</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="katyusha" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Поддерживается ли конструктивно катюша</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Конники</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="conics" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Поддерживается ли конники</small>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label pt-0 required">Пирамида</label>
                      <div class="col">
                        <label class="form-check">
                          <input [(ngModel)]="pyramid" class="form-check-input" type="checkbox">
                          <span class="form-check-label">Да</span>
                        </label>
                        <small class="form-hint">Может ли данный тип кузова иметь пирамиду</small>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-end bgc-t">
                    <div class="d-flex">
                      <a href="javascript:void(0)" (click)="clear()" class="btn btn-link">Очистить</a>
                      <button [class.disabled]="isCreating || isCreated" [class.btn-success]="isCreated" [class.btn-danger]="isErr" type="submit" (click)="register()" class="btn btn-primary ms-auto">
                        <span *ngIf="isCreating" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        {{isCreated ? 'Создан' : 'Создать'}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
