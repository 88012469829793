import {Injectable} from "@angular/core";
import {Manager} from "../../../../../app-nestjs/src/modules/manager/manager.interface";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {HttpClient} from "@angular/common/http";
import {RolesManagerInterface} from "../../../../../app-nestjs/src/modules/manager/controllers/roles/roles.manager.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ManagerComponentService {

  public id: string | undefined = undefined;
  public manager: Manager.ManagerInterface | undefined = undefined
  public roles: RolesManagerInterface[] = []

  constructor(
    private http: HttpClient,
    public router: Router
  ) {
  }

  async loadProfile(id: string): Promise<void> {
    await this.loadRoles()
    this.id = id
    const json = {
      id: this.id
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/managers/get', json).toPromise()
      .then((data) => {
        let manager = data.data as Manager.ManagerInterface
        this.manager = manager
      })
      .catch((err: ErrorResponseInterface) => {
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Неверно указан id менеджера') {
              this.router.navigateByUrl('/404')
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          return;
        }
      })
  }

  private async loadRoles(): Promise<void> {
    if (this.roles.length === 0) {
      await this.http.post<SuccessResponseInterface>('/api/manager/roles/list', {}).toPromise()
        .then((data) => {
          this.roles = data.data as RolesManagerInterface[]
          return;
        })
    }
  }
}
