<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div *ngIf="constService.get(constService.itemTypes.statesAdmonition)"
                   class="alert alert-important alert-info alert-dismissible" role="alert">
                <div class="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24"
                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                         stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <line x1="12" y1="8" x2="12.01" y2="8"></line>
                      <polyline points="11 12 12 12 12 16 13 16"></polyline>
                    </svg>
                  </div>
                  <div>
                    Список событий генерируемый на стороне 1С. Невозможно удаление, а создание требует дополнительных манипуляций в 1С. Редактирование возможно - но только для корректирования отображения событий у пользователей
                  </div>
                </div>
                <a (click)="constService.add(constService.itemTypes.statesAdmonition)" class="btn-close btn-close-white"
                   data-bs-dismiss="alert" aria-label="close"></a>
              </div>
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="row align-items-center mw-100">
                    <div class="col">
                      <div class="mb-1">
                        <ol class="breadcrumb breadcrumb-alternate" aria-label="breadcrumbs">
                          <li *ngFor="let key of titleService.getNavi()" class="breadcrumb-item"
                              [class.active]="key.current">
                            <a [routerLink]="['', key.url]">{{key.title}}</a>
                          </li>
                        </ol>
                      </div>
                      <h2 class="page-title">
                        События заказов
                      </h2>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>


                <div class="col-md-12">
                  <div class="card mb-3">
                    <div class="card-header">
                      <h3 class="card-title">Список событий заказа</h3>
                    </div>
                    <div class="table-responsive" style="margin-bottom: 0rem">
                      <table class="table table-vcenter card-table table-striped">
                        <thead>
                        <tr>
                          <th>Событие</th>
                          <th>Для клиента</th>
                          <th>Для водителя</th>
                          <th>Для менеджера</th>
                          <th class="w-1"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let key of states">
                          <td class="small">{{key.description}}</td>
                          <td class="text-muted small">
                            <span *ngIf="key.forClient" class="badge bg-success me-1"></span>
                            <span *ngIf="!key.forClient" class="badge bg-danger me-1"></span>
                            {{key.forClient ? 'Отображается' : 'Не доступно'}}</td>
                          <td class="text-muted small">
                            <span *ngIf="key.forDriver" class="badge bg-success me-1"></span>
                            <span *ngIf="!key.forDriver" class="badge bg-danger me-1"></span>
                            {{key.forDriver ? 'Отображается' : 'Не доступно'}}</td>
                          <td class="text-muted small">
                            <span *ngIf="key.forManager" class="badge bg-success me-1"></span>
                            <span *ngIf="!key.forManager" class="badge bg-danger me-1"></span>
                            {{key.forManager ? 'Отображается' : 'Не доступно'}}</td>
                          <td>
                            <a href="javascript:void(0)" class="small">Редактировать</a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
