import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {ManagerService} from "../../../../services/manager.service";
import {PasswordEditManagerComponentInterface} from "./password.edit.manager.component.interface";
import {SuccessResponseInterface} from "../../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../../interfaces/error-response.interface";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'app-password-edit-manager-profile',
  templateUrl: './password.edit.manager.component.html',
  styleUrls: ['./password.edit.manager.component.scss']
})
export class PasswordEditManagerComponent implements OnInit {

  public id: string | undefined = undefined

  public passwordChangeLoading: boolean = false
  public passwordChanged: boolean = false

  public password: string = ''
  public secondPassword: string = ''

  public passwordErr: boolean = false
  public secondPasswordErr: boolean = false

  public secondPasswordErrText: string = ''
  public passwordErrText: string = ''

  constructor(
    private readonly http: HttpClient,
    private readonly managerService: ManagerService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe((data) => {
        this.id = data
      });
  }

  // Сброс пароля - отправка запроса
  async changePassword(): Promise<any> {
    this.passwordChangeLoading = true
    this.clearErrors()
    if (this.password !== this.secondPassword) {
      this.secondPasswordErr = true
      this.secondPasswordErrText = 'Пароли отличаются друг от друга'
      this.passwordChangeLoading = false
      return;
    }
    const json: PasswordEditManagerComponentInterface = {
      id: this.id as string,
      password: this.password
    }
    await this.http.patch<SuccessResponseInterface>('/api/manager/managers/change-password', json).toPromise()
      .then((data) => {
        this.passwordChangeLoading = false
        this.passwordChanged = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.passwordChangeLoading = false
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Пароль не может содержать менее 6 символов') {
              this.passwordErr = true
              this.passwordErrText = key
            }
            if (key === 'ID менеджера указан неверно') {
              this.passwordErr = true
              this.passwordErrText = key
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          this.toastr.error(err.error.message, 'Ошибка');
          return;
        }
      })
  }

  // Сбросить ошибки
  clearErrors(): void {
    this.passwordChanged = false
    this.passwordErr = false
    this.secondPasswordErr = false
    this.secondPasswordErrText = ''
    this.passwordErrText = ''
  }

  clear(): void {
    this.password = ''
    this.secondPassword = ''
    this.clearErrors()
  }
}
