
<div *ngIf="isLoading" class="container-xl">
  <div class="loader loader-center"></div>
</div>

<div *ngIf="!isLoading" class="card">
  <div class="card-header">
    <h3 class="card-title">RDP Сессии</h3>
  </div>
  <div class="card-body">
    <div *ngIf="rdpSessions.length == 0 && !isLoading" class="card">
      <div class="empty">
        <p class="empty-title">Не найдены</p>
        <p class="empty-subtitle text-muted">Сессии не обнаружены или возникла ошибка при получении</p>
      </div>
    </div>
    <div *ngFor="let key of rdpSessions" class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row g-2 align-items-center">
            <div class="col-auto">
              <span class="avatar avatar-md">
	              <svg xmlns="http://www.w3.org/2000/svg" class="icon-md" width="46" height="46" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="4" y1="12" x2="20" y2="12" /></svg>
              </span>
            </div>
            <div class="col">
              <h4 class="card-title m-0">
                <a class="active" href="javascript:void(0)">{{key.user}}</a>
              </h4>
              <div class="text-muted">
                {{key.name ?? '(Нет подключения)'}}
              </div>
              <div *ngIf="key.isConnected" class="small mt-1">
                <span class="badge bg-green"></span> Активна
              </div>
              <div *ngIf="!key.isConnected" class="small mt-1">
                <span class="badge"></span> Отключена
              </div>
            </div>
            <div class="col-auto">
              <a href="javascript:void(0)" [class.disabled]="isRemoving" (click)="removeRdpSession(key.id)" class="btn btn-danger">
                <span *ngIf="isRemoving" class="spinner-border spinner-border-sm me-2" role="status"></span>
                Удалить
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
