import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/logout/logout.component';
import {ForgotComponent} from './components/forgot/forgot.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {PermissionDeniedComponent} from './components/permission-denied/permission-denied.component';
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {IsAuthGuard} from "./guards/is-auth.guard";
import {InternalServerErrorComponent} from "./components/internal-server-error/internal-server-error.component";
import {CatalogComponent} from "./components/catalog/catalog.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {IsAlreadyAuthGuard} from "./guards/is-already-auth.guard";
import {CreateManagerComponent} from "./components/manager/create/create.manager.component";
import {ListManagerComponent} from "./components/manager/list/list.manager.component";
import {RolesManagerComponent} from "./components/manager/roles/roles.manager.component";
import {EditManagerComponent} from "./components/manager/edit/edit.manager.component";
import {ListCarcaseComponent} from "./components/carcase/list/list.carcase.component";
import {CreateCarcaseComponent} from "./components/carcase/create/create.carcase.component";
import {EditCarcaseComponent} from "./components/carcase/edit/edit.carcase.component";
import {ListCategoryComponent} from "./components/category/list/list.category.component";
import {CreateCategoryComponent} from "./components/category/create/create.category.component";
import {ToolComponent} from "./components/tool/tool.component";
import {EditCategoryComponent} from "./components/category/edit/edit.category.component";
import {ListSelfEmployedCheckComponent} from "./components/self-employed-check/list/list.self-employed-check.component";
import {EnvPrintComponent} from "./components/env-print/env-print.component";
import {PalletCalculatorComponent} from "./components/pallet-calculator/pallet-calculator.component";
import {CdrComponent} from "./components/cdr/cdr.component";
import {PalletComponent} from "./components/pallet/pallet.component";
import {CurrencyComponent} from "./components/currency/currency.component";
import {BankComponent} from "./components/bank/bank.component";
import {ServiceTypeComponent} from "./components/service-type/service-type.component";
import {CounterpartyTypesComponent} from "./components/counterparty-types/counterparty-types.component";
import {RdpComponent} from "./components/rdp/rdp.component";
import {DriverComponent} from "./components/driver/driver.component";
import {StateComponent} from "./components/state/state.component";
import {StatusComponent} from "./components/status/status.component";
import {PolygonComponent} from "./components/polygon/polygon.component";

export enum MenuItems {
  'null' = 'null',
  'dashboard' = 'dashboard',
  'tools' = 'tools',
  'catalog' = 'catalog',
  'profile' = 'profile',
  'admin' = 'admin'
}

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [IsAlreadyAuthGuard], data: {menu: MenuItems.null, title: 'Авторизация', navi: []}},
  {path: 'forgot', component: ForgotComponent, canActivate: [IsAlreadyAuthGuard], data: {menu: MenuItems.null, title: 'Сброс пароля', navi: []}},
  {path: 'logout', component: LogoutComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.null, title: 'Выход', navi: []}},
  {path: 'dashboard', component: DashboardComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.dashboard, title: 'Рабочий стол', navi: []}},
  {path: 'profile', component: ProfileComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.profile, title: 'Профиль', navi: []}},
  {path: 'catalog', component: CatalogComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Каталог', navi: []}},
  {path: 'tools', component: ToolComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.tools, title: 'Инструменты', navi: []}},
  {path: 'managers/list', component: ListManagerComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Менеджеры', navi: ['catalog', 'managers/list']}},
  {path: 'managers/create', component: CreateManagerComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.admin, title: 'Создание менеджера', navi: ['catalog', 'managers/list', 'managers/create']}},
  {path: 'managers/edit/:id', component: EditManagerComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.admin, title: 'Редактирование менеджера', navi: ['catalog', 'managers/list', 'managers/edit/:id']}},
  {path: 'managers/roles', component: RolesManagerComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Роли менеджеров', navi: ['catalog', 'managers/roles']}},
  {path: 'carcases/list', component: ListCarcaseComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Типы кузова', navi: ['catalog', 'carcases/list']}},
  {path: 'carcases/create', component: CreateCarcaseComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.admin, title: 'Создание типа кузова', navi: ['catalog', 'carcases/list', 'carcases/create']}},
  {path: 'carcases/edit/:id', component: EditCarcaseComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.admin, title: 'Редактирование типа кузова', navi: ['catalog', 'carcases/list', 'carcases/edit/:id']}},
  {path: 'categories/list', component: ListCategoryComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Категории транспорта', navi: ['catalog', 'categories/list']}},
  {path: 'categories/create', component: CreateCategoryComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.admin, title: 'Создание категории ТС', navi: ['catalog', 'categories/list', 'categories/create']}},
  {path: 'categories/edit/:id', component: EditCategoryComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.admin, title: 'Редактирование категории ТС', navi: ['catalog', 'categories/list', 'categories/edit/:id']}},
  {path: 'self-employed-check/list', component: ListSelfEmployedCheckComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.tools, title: 'Чеки СЗ', navi: ['tools', 'self-employed-check/list']}},
  {path: 'env-print', component: EnvPrintComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.tools, title: 'Печать конверта', navi: ['tools', 'env-print']}},
  {path: 'pallet-calculator', component: PalletCalculatorComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.tools, title: 'Калькулятор паллет', navi: ['tools', 'pallet-calculator']}},
  {path: 'pallets/list', component: PalletComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Паллеты', navi: ['catalog', 'pallets/list']}},
  {path: 'statuses/list', component: StatusComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Статусы заказов', navi: ['catalog', 'statuses/list']}},
  {path: 'states/list', component: StateComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'События заказов', navi: ['catalog', 'states/list']}},
  {path: 'currencies/list', component: CurrencyComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Валюты', navi: ['catalog', 'currencies/list']}},
  {path: 'banks/list', component: BankComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Банки', navi: ['catalog', 'banks/list']}},
  {path: 'service-types/list', component: ServiceTypeComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Типы перевозок', navi: ['catalog', 'service-types/list']}},
  {path: 'counterparty-types/list', component: CounterpartyTypesComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Типы контрагентов', navi: ['catalog', 'counterparty-types/list']}},
  {path: 'drivers/list', component: DriverComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Водители', navi: ['catalog', 'drivers/list']}},
  {path: 'polygons/list', component: PolygonComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.catalog, title: 'Полигоны', navi: ['catalog', 'polygons/list']}},
  {path: 'cdr', component: CdrComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.tools, title: 'Записи звонков', navi: ['tools', 'cdr']}},
  {path: 'rdp/list', component: RdpComponent, canActivate: [IsAuthGuard], data: {menu: MenuItems.admin, title: 'Список настроек RDP', navi: ['catalog', 'rdp/list']}},
  {path: 'error', component: InternalServerErrorComponent, data: {menu: MenuItems.null, title: 'Ошибка сервиса', navi: []}},
  {path: 'denied', component: PermissionDeniedComponent, data: {menu: MenuItems.null, title: 'Доступ закрыт', navi: []}},
  {path: '', pathMatch: 'full', redirectTo: '/dashboard'},
  {path: '**', component: NotFoundComponent, data: {menu: MenuItems.null, title: '404', navi: []}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
