<div class="antialiased border-top-wide border-primary flex-column max-height">
<div class="flex-fill d-flex flex-column justify-content-center py-4 max-height">
  <div class="container-tight py-6">
    <div class="text-center mb-4">
      <a href=""><img src="../../../assets/static/logo.svg" height="50" alt=""></a>
    </div>
    <div class="card card-md">
      <div name="changed" *ngIf="isChangedComponent" class="alert alert-important alert-success alert-dismissible" role="alert">
        <div class="d-flex">
          <div>
          </div>
          <div>
            Ваш пароль был изменен
          </div>
        </div>
      </div>
      <div name="email" *ngIf="isSendForgotComponent"  class="card-body">
        <h2 class="card-title text-center mb-4">Сброс пароля</h2>
        <p class="text-muted mb-4">Введите email для получения кода сброса пароля</p>
        <div class="mb-3">
          <label class="form-label">Email</label>
          <input name="email" type="email" [classList]="isErrEmail ? 'form-control is-invalid' : 'form-control'"placeholder="Email" [(ngModel)]="email">
          <div class="invalid-feedback">{{isErrEmailText}}</div>
        </div>
        <div class="form-footer">
          <a (click)="sendForgotComponent()" href="javascript:void(0)" [classList]="isForgotLoading ? 'btn btn-primary w-100 disabled' : 'btn btn-primary w-100'">
            <span *ngIf="isForgotLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
            Восстановить пароль
          </a>
        </div>
      </div>
      <div name="passwordList" *ngIf="isPasswordComponents" class="card-body">
        <h2 class="card-title text-center mb-4">Изменение пароля</h2>
        <p class="text-muted mb-4">Введите новый пароль для восстановления доступа к учетной записи</p>
        <div class="mb-3">
          <label class="form-label">Email код</label>
          <input [(ngModel)]="emailCode" mask="000000" [disabled]="isChangedComponent" name="code" type="text" [classList]="isEmailCodeErr ? 'form-control is-invalid' : 'form-control'" placeholder="Емейл код для сброса">
          <div class="invalid-feedback">{{emailCodeTextErr}}</div>
        </div>
        <div class="mb-3">
          <label class="form-label">Пароль</label>
          <div class="input-group input-group-flat">
            <input [(ngModel)]="password" name="password" type="password" class="form-control" [disabled]="isChangedComponent" [classList]="isPasswordChangeErr ? 'form-control is-invalid' : 'form-control'"  placeholder="Пароль"  autocomplete="off">
            <div class="invalid-feedback">{{isPasswordChangeErrText}}</div>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Подтверждение пароля</label>
          <div class="input-group input-group-flat">
            <input [(ngModel)]="secondPassword" [disabled]="isChangedComponent" name="second_password" type="password" class="form-control"  placeholder="Подтверждение пароля"  autocomplete="off">
          </div>
        </div>
        <div class="form-footer">
          <a (click)="sendPassword()" [class.disabled]="!password || !secondPassword || !emailCode || isChangedComponent" href="javascript:void(0)" class="btn btn-primary w-100">
            <span *ngIf="isPasswordLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
            {{isChangedComponent ? 'Пароль изменен' : 'Изменить пароль'}}
          </a>
        </div>
      </div>
      <div name="progress" class="progress progress-sm">
        <div class="progress-bar" [style.width.%]="progress" role="progressbar" aria-valuemin="1" aria-valuemax="100">
          <span class="visually-hidden">57% Complete</span>
        </div>
      </div>
    </div>
    <div class="text-center text-muted mt-3">
      Вернуться назад, <a routerLink="/login">на страницу авторизации</a>
    </div>
  </div>
</div>
</div>
