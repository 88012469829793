import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../services/title.service";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {Bank} from "../../../../../app-nestjs/src/modules/bank/bank.interface";
import {AdmonitionService} from "../../services/admonition.service";

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {

  public isLoading: boolean = true
  public banks: Bank.BankInterface[] = []
  public offset: number = 0
  public limit: number = 20

  constructor(
    private readonly http: HttpClient,
    public readonly constService: AdmonitionService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.load()
  }

  public async load(): Promise<void> {
    if (this.banks.length !== 0) {
      this.offset = this.offset + this.limit
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/banks/list', {
      offset: this.offset,
      limit: this.limit
    }).toPromise()
      .then((data) => {
        for (const item of data.data) {
          this.banks.push(item)
        }
        this.isLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }



}
