import {Component, OnInit, TemplateRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ManagerService} from "../../../services/manager.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

export interface SessionList {
  data: Session[]
}

export interface Session {
  browser: string | null
  createdAt: Date
  id: string
  ip: string | null
  isCurrent: boolean
  lastOnline: Date
  platform: string | null
}

@Component({
  selector: 'app-profile-sessons',
  templateUrl: './sessons.profile.component.html',
  styleUrls: ['./sessons.profile.component.scss']
})
export class SessonsProfileComponent implements OnInit {

  public sessions: Session[] | undefined
  public isLoading: boolean = true
  public modalRef: BsModalRef = new BsModalRef

  // Удаление всех сессий
  public allSessionsRemoved: boolean = false
  public allSessionsRemovedErr: boolean = false
  public allSessionsRemoveLoading: boolean = false

  // Удаление одной сессии
  public oneSessionRemoved: boolean = false
  public oneSessionRemovedErr: boolean = false
  public oneSessionRemoveLoading: boolean = false
  public sessionToRemove: any

  constructor(
    private readonly http: HttpClient,
    private readonly managerService: ManagerService,
    private modalService: BsModalService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadAllManagerSessions()
  }

  // Загрузка всех сессий менеджера
  async loadAllManagerSessions(): Promise<any> {
    let sessions = await this.http.get('/api/manager/main/list-sessions').toPromise()
      .then(async (data: any) => {
        let sessions = data.data
        this.sessions = []
        for (let session of sessions) {
          let item: Session = session
          this.sessions.push(item)
        }
        this.isLoading = false
      })
      .catch(async (err: any) => {
        this.isLoading = false
      })
  }

  // Получение текста для сессии
  getTextForSession(item: Session): string {
    let string = ''
    if (item.browser) {
      string = string + 'Браузер: <strong>' + item.browser + '</strong> '
    }
    if (item.platform) {
      string = string + 'Операционная система: <strong>' + item.platform + '</strong> '
    }
    if (item.ip) {
      string = string + 'IP: <strong>' + item.ip + '</strong> '
    }
    return string
  }

  // Получить что это устройство не является в списке известных
  getDeviceOther(item: Session): boolean {
    let value = true
    if (item.platform == 'Apple Mac') {
      value = false
    }
    if (item.platform == 'Microsoft Windows') {
      value = false
    }
    if (item.platform == 'Linux') {
      value = false
    }
    if (item.platform == 'Android') {
      value = false
    }
    return value
  }

  // Удалить все сессии - модальное окно
  removeAllSessionsModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  // Удалить определенную сессию
  removeOneSessionModal(template: TemplateRef<any>, item: Session): void {
    this.sessionToRemove = item
    this.modalRef = this.modalService.show(template);
  }

  // Запрос на сервер - удаление всех сессий
  async removeAllSessions(): Promise<void> {
    let request = await this.http.get('/api/manager/main/remove-sessions').toPromise()
      .then(async (data: any) => {
        this.allSessionsRemoved = true
        this.allSessionsRemoveLoading = false
        this.allSessionsRemovedErr = false
      })
      .catch(async (err: any) => {
        this.allSessionsRemoved = false
        this.allSessionsRemoveLoading = false
        this.allSessionsRemovedErr = true
      })
    return;
  }

  // Удаление одной сессии
  async removeOneSession(id: string): Promise<void> {
    let json = {
      id: id
    }
    let request = await this.http.post('/api/manager/main/remove-session', json).toPromise()
      .then((data) => {
        this.oneSessionRemoved = true
        this.oneSessionRemoveLoading = false
        this.oneSessionRemovedErr = false
      })
      .catch((err) => {
        this.oneSessionRemoved = false
        this.oneSessionRemoveLoading = false
        this.allSessionsRemovedErr = false
      })
    return
  }
}
