import { Component, OnInit } from '@angular/core';
import {GenderEnum} from "../../../../../../app-nestjs/src/constants/gender.enum";
import {ManagerRolesEnum} from "../../../../../../app-nestjs/src/modules/manager/manager.roles.enum";
import {Manager} from "../../../../../../app-nestjs/src/modules/manager/manager.interface";
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-create-manager',
  templateUrl: './create.manager.component.html',
  styleUrls: ['./create.manager.component.scss']
})
export class CreateManagerComponent implements OnInit {

  public isRegistering: boolean = false
  public isCreated: boolean = false
  public isErr: boolean = false

  public genderEnum = GenderEnum
  public managerRolesEnum = ManagerRolesEnum

  public email: string = ''
  public password: string = ''
  public addNum: string = ''
  public gender: string = ''
  public mobile: string = ''
  public name: string = ''
  public patronymic: string = ''
  public position: string = ''
  public role: string = ''
  public surname: string = ''

  public isEmailErr: boolean = false
  public isPasswordErr: boolean = false
  public isAddNumErr: boolean = false
  public isGenderErr: boolean = false
  public isMobileErr: boolean = false
  public isNameErr: boolean = false
  public isPatronymicErr: boolean = false
  public isPositionErr: boolean = false
  public isRoleErr: boolean = false
  public isSurnameErr: boolean = false

  public emailErrText: string = ''
  public passwordErrText: string = ''
  public addNumErrText: string = ''
  public genderErrText: string = ''
  public mobileErrText: string = ''
  public nameErrText: string = ''
  public patronymicErrText: string = ''
  public positionErrText: string = ''
  public roleErrText: string = ''
  public surnameErrText: string = ''

  constructor(
    private readonly http: HttpClient,
    private readonly toastrService: ToastrService
  ) { }

  ngOnInit(): void {
  }

  public clear(): void {
    this.email = ''
    this.password = ''
    this.addNum = ''
    this.gender = ''
    this.mobile = ''
    this.name = ''
    this.patronymic = ''
    this.position = ''
    this.role = ''
    this.surname = ''
    this.isRegistering = false
    this.isCreated = false
    this.clearErrors()
  }

  public clearErrors(): void {
    this.isEmailErr= false
    this.isPasswordErr = false
    this.isAddNumErr = false
    this.isGenderErr = false
    this.isMobileErr = false
    this.isNameErr = false
    this.isPatronymicErr = false
    this.isPositionErr = false
    this.isRoleErr = false
    this.isSurnameErr = false

    this.emailErrText = ''
    this.passwordErrText = ''
    this.addNumErrText = ''
    this.genderErrText = ''
    this.mobileErrText = ''
    this.nameErrText = ''
    this.patronymicErrText = ''
    this.positionErrText= ''
    this.roleErrText = ''
    this.surnameErrText = ''

    this.isErr = false
  }

  async register(): Promise<void> {
    this.clearErrors()
    this.isErr = false
    this.isRegistering = true
    const json: Manager.ManagerInterface = {
      addNum: this.addNum,
      avatar: null,
      email: this.email,
      gender: this.gender as Manager.gender,
      id: "",
      isFired: false,
      mobile: '+' + this.mobile,
      name: this.name,
      password: this.password,
      patronymic: this.patronymic,
      position: this.position,
      role: this.role as Manager.role,
      surname: this.surname
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/managers/create', json).toPromise()
      .then((data) => {
        this.isRegistering = false
        this.isErr = false
        this.isCreated = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.isRegistering = false
        this.isErr = true
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Емейл указан неверно') {
              this.isEmailErr = true
              this.emailErrText = key
            }
            if (key === 'Пароль не может содержать менее 6 символов') {
              this.isPasswordErr = true
              this.passwordErrText = key
            }
            if (key === 'Добавочный номер не может содержать менее 3 символов') {
              this.isAddNumErr = true
              this.addNumErrText = key
            }
            if (key === 'Добавочный номер содержит только цифры') {
              this.isAddNumErr = true
              this.addNumErrText = key
            }
            if (key === 'Пол не может быть пустым') {
              this.isGenderErr = true
              this.genderErrText = key
            }
            if (key === 'Пол указан неверно') {
              this.isGenderErr = true
              this.genderErrText = key
            }
            if (key === 'Неверно указан мобильный номер телефона') {
              this.isMobileErr = true
              this.mobileErrText = key
            }
            if (key === 'Имя указано неверно') {
              this.isNameErr = true
              this.nameErrText = key
            }
            if (key === 'Отчество указано неверно') {
              this.isPatronymicErr = true
              this.patronymicErrText = key
            }
            if (key === 'Должность указана неверно') {
              this.isPositionErr = true
              this.positionErrText = key
            }
            if (key === 'Роль не может быть пустой') {
              this.isRoleErr = true
              this.roleErrText = key
            }
            if (key === 'Роль указана неверно') {
              this.isRoleErr = true
              this.roleErrText = key
            }
            if (key === 'Фамилия указана неверно') {
              this.isSurnameErr = true
              this.surnameErrText = key
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          if (err.error.message === 'Менеджер с таким емейлом уже зарегистрирован') {
            this.isEmailErr = true
            this.emailErrText = err.error.message as string
          }
          if (err.error.message === 'Менеджер с таким мобильным телефоном уже зарегистрирован') {
            this.isMobileErr = true
            this.mobileErrText = err.error.message as string
          }
          if (err.error.message === 'Менеджер с таким добавочным номером уже зарегистрирован') {
            this.isAddNumErr = true
            this.addNumErrText = err.error.message as string
          }
          return;
        }
      })
  }

}
