import {Inject, Injectable, InjectionToken} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { ManagerService } from '../services/manager.service';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public manager: ManagerService,
    public router: Router
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    // Определяем url, в которые не нужно вставлять jwt и session
    let needExclude = false
    const excludeUrl = [
      '/api/manager/auth/login',
      '/api/manager/auth/send-forgot-code',
      '/api/manager/auth/change-password'
    ]

    excludeUrl.forEach((element) => {
      if (element == request.url) {
        needExclude = true
      }
    })

    if (needExclude) {
      return next.handle(request)
    }

    // Предварительно проверить установлены ли токены
    const tokenInstalled = this.manager.checkToken()
    if (!tokenInstalled) {
      this.router.navigateByUrl('/login')
    }

    request = request.clone({
      setHeaders: {
        token: this.manager.getToken()
      }
    });

    return next.handle(request).pipe(
      tap(
        (event) => {
          // Тут перепишем токены
        },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.error.statusCode == 401) {
              this.manager.logout()
              this.router.navigateByUrl('/login')
            }
            if (err.error.statusCode == 403) {
              this.router.navigateByUrl('/denied')
            }
            if (err.error.statusCode == 500) {
              this.router.navigateByUrl('/error')
            }
            if (err.error.statusCode == 404) {
              this.router.navigateByUrl('/404')
            }
          }
        },
      )
    )
  }
}
