import { Component, Input, OnInit } from '@angular/core';
import {Manager} from "../../../../../../app-nestjs/src/modules/manager/manager.interface";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-get-manager',
  templateUrl: './get.manager.component.html',
  styleUrls: ['./get.manager.component.scss']
})
export class GetManagerComponent implements OnInit {

  public manager?: Manager.ManagerInterface

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {

  }

  // Получить текстовый аватар
  public getTextAvatar(name: Manager.name | undefined, surname: Manager.surname | undefined): any {
    if (typeof name == 'string' && typeof surname == 'string') {
      if (surname && name) {
        return name[0] + surname[0]
      }
      if (name) {
        return name[0]
      }
    }
  }
}
