import { Component, Input, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {HttpClient} from "@angular/common/http";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";

@Component({
  selector: 'app-create-self-employed-check',
  templateUrl: './create.self-employed-check.component.html',
  styleUrls: ['./create.self-employed-check.component.scss']
})
export class CreateSelfEmployedCheckComponent implements OnInit {

  public url: string = ''
  public disabled: boolean = false

  public isInfo: boolean = false
  public isErr: boolean = false
  public isOk: boolean = false
  public isWarning: boolean = false

  public infoText: string = ''
  public errText: string = ''
  public okText: string = ''
  public warningText: string = ''

  public description: string = ''
  public totalAmount: string = ''
  public clientDisplayName: string = ''
  public operationTime: string = ''

  constructor(
    public bsModalRef: BsModalRef,
    private readonly http: HttpClient,
  ) {
  }

  ngOnInit(): void {

  }

  public async changeModel(event: any): Promise<void> {
    this.clearAll()
    await this.register()
  }

  private clearAll(): void {
    this.isErr = false
    this.isInfo = false
    this.isWarning = false
    this.isOk = false
    this.okText = ''
    this.warningText = ''
    this.errText = ''
    this.infoText = ''
    this.description = ''
    this.totalAmount = ''
    this.clientDisplayName = ''
    this.operationTime = ''
  }

  public async register(): Promise<void> {
    this.disabled = true
    let json = {
      url: this.url
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/self-employed-checks/create', json).toPromise()
      .then((data) => {
        this.disabled = false
        this.isOk = true
        this.okText = 'Чек зарегистрирован'
        this.description = data.data.description
        this.operationTime = data.data.operationTime
        this.totalAmount = data.data.totalAmount
        this.clientDisplayName = data.data.clientDisplayName
      })
      .catch((err: ErrorResponseInterface) => {
        this.disabled = false
        if (typeof err.error.message === 'string') {
          if (err.error.message === 'Ссылка не ведет на nalog.ru') {
            this.isErr = true
            this.errText = err.error.message
            return;
          }
          if (err.error.message === 'Не удалось получить данные с nalog.ru') {
            this.isErr = true
            this.errText = err.error.message
            return;
          }
          if (err.error.message === 'Чек анулирован или отменен') {
            this.isErr = true
            this.errText = err.error.message
            return;
          }
          if (err.error.message === 'В чеке отсутствует ИНН отправителя чека') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует время операции') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует время операции') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует ИНН плательщика-организации') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует имя плательщика-организации') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует ID самого чека') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует время регистрации чека') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует описание услуг') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'В чеке отсутствует полная сумма') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'Во время обращения к 1С для получения платежного поручения произошла ошибка') {
            this.isErr = true
            this.errText = err.error.message
            return;
          }
          if (err.error.message === 'Нет платежных поручений по данным с этого чека') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'Во время обращения к 1С для получения значения "Есть Чек" произошла ошибка') {
            this.isErr = true
            this.errText = err.error.message
            return;
          }
          if (err.error.message === 'Указанный чек ранее уже был зарегистрирован в системе') {
            this.isInfo = true
            this.infoText = err.error.message
            return;
          }
          if (err.error.message === 'Нет платежных поручений по данным с этого чека') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'Нет счета-реестра по данным с этого чека') {
            this.isWarning = true;
            this.warningText = err.error.message;
            return;
          }
          if (err.error.message === 'Во время записи в БД произошла ошибка в наборе данных') {
            this.isErr = true
            this.errText = err.error.message
            return;
          }
        }
        if (Array.isArray(err?.error?.message)) {
          this.clearAll()
          return;
        }
      })
  }

}
