import {Component, Injectable} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, ActivationEnd, Router} from "@angular/router";

export interface NaviTitleInterface {
  url: string,
  title: string,
  current: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  mainTittle: string = 'АКРО Менеджер'

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      if (val instanceof ActivationEnd) {
        const data = val.snapshot.data
        this.set(data.title)
      }
    });
  }

  public set(title: string): void {
    this.titleService.setTitle(title + ' - ' + this.mainTittle);
  }

  public get(): string {
    return this.titleService.getTitle().split(' - ')[0]
  }

  public getNavi(): NaviTitleInterface[] {
    let answer: NaviTitleInterface[] = []
    let path = this.activatedRoute.snapshot.children[0].routeConfig?.path
    if (path) {
      for (let key of this.router.config) {
        if (key.path === path) {
          let navi = key.data?.navi
          if (navi) {
            for (let item of navi) {
              for (let conf of this.router.config) {
                if (conf.path === item) {
                  let json: NaviTitleInterface = {
                    current: false,
                    title: conf.data?.title,
                    url: conf.path as string
                  }
                  if (json.url === path) {
                    json.current = true
                  }
                  answer.push(json)
                }
              }
            }
          }
        }
      }
    }
    return answer as NaviTitleInterface[]
  }


}
