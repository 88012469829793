<div *ngIf="isProfileUpdated" class="alert alert-important alert-success alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
    </div>
    <div>
      Ваш профиль обновлен
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>
<div *ngIf="isProfileUpdatedError" class="alert alert-important alert-warning alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
    </div>
    <div>
      Во время обновления профиля обнаружены ошибки
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>

<div *ngIf="isLoading" class="container-xl">
  <div class="loader loader-center"></div>
</div>
<div *ngIf="!isLoading" class="card">
    <div class="card-header">
      <h3 class="card-title">Профиль</h3>
    </div>
    <div class="card-body">
      <div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Емейл</label>
          <div class="col">
            <input [(ngModel)]="email" [class.is-invalid]="isEmailError" name="email" type="email" class="form-control" placeholder="Введите емейл">
            <small *ngIf="!isEmailError" class="form-hint">Не может быть несколько менеджеров с одинаковым емейлом. Смена емейла доступна только администратору системы</small>
            <div class="invalid-feedback">{{emailErrorText}}</div>
          </div>
        </div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Должность</label>
          <div class="col">
            <input [(ngModel)]="position" [class.is-invalid]="isPositionError" name="position" type="text" class="form-control" placeholder="Введите должность">
            <small *ngIf="!isPositionError" class="form-hint">Отображается сотрудникам, клиентам и водителям</small>
            <div class="invalid-feedback">{{positionErrorText}}</div>
          </div>
        </div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Права доступа</label>
          <div class="col">
            <select class="form-select" [class.is-invalid]="isRoleError" [(ngModel)]="role">
              <option *ngFor="let key of managerComponentService.roles" [value]="key.value">{{key.name}}</option>
            </select>
            <small *ngIf="!isRoleError" class="form-hint">Права доступа разрешают или запрещают доступ к данным</small>
            <div class="invalid-feedback">{{roleErrorText}}</div>
          </div>
        </div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Фамилия</label>
          <div class="col">
            <input [(ngModel)]="surname" [class.is-invalid]="isSurnameError" name="surname" type="text" class="form-control" placeholder="Введите фамилию">
            <small *ngIf="!isSurnameError" class="form-hint">Ваша фамилия</small>
            <div class="invalid-feedback">{{surnameErrorText}}</div>
          </div>
        </div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Имя</label>
          <div class="col">
            <input [(ngModel)]="name" [class.is-invalid]="isNameError" name="name" type="text" class="form-control" placeholder="Введите имя">
            <small *ngIf="!isNameError" class="form-hint">Ваше полное имя</small>
            <div class="invalid-feedback">{{nameErrorText}}</div>
          </div>
        </div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Отчество</label>
          <div class="col">
            <input [(ngModel)]="patronymic" [class.is-invalid]="isPatronymicError" name="patronymic" type="text" class="form-control" placeholder="Введите отчество">
            <small *ngIf="!isPatronymicError" class="form-hint">Ваше отчество</small>
            <div class="invalid-feedback">{{patronymicErrorText}}</div>
          </div>
        </div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Пол</label>
          <div class="col">
            <div class="col">
              <select class="form-select" [class.is-invalid]="isGenderError" [(ngModel)]="gender">
                <option value="{{genderEnum.male}}">Мужской</option>
                <option value="{{genderEnum.female}}">Женский</option>
              </select>
              <small *ngIf="!isGenderError" class="form-hint">Выберите ваш пол</small>
              <div class="invalid-feedback">{{genderErrorText}}</div>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label required">Мобильный номер</label>
          <div class="col">
            <input [(ngModel)]="mobile" [class.is-invalid]="isMobileError" name="mobile" type="text" mask="00000000000" class="form-control" placeholder="Введите мобильный">
            <small *ngIf="!isMobileError" class="form-hint">Номер телефона в формате 7ХХХХХХХХХХ. В системе не может быть несколько одинаковых телефонов</small>
            <div class="invalid-feedback">{{mobileErrorText}}</div>
          </div>
        </div>
        <div class="form-group row">
          <label class="form-label col-3 col-form-label required">Добавочный номер</label>
          <div class="col">
            <input [(ngModel)]="addNum" [class.is-invalid]="isAddNumError" name="addNum" type="text" mask="000"  class="form-control" placeholder="Введите добавочный номер">
            <small *ngIf="!isAddNumError" class="form-hint">Внутренний номер телефона для интеграции с телефонией. Не может быть несколько одинаковых добавочных</small>
            <div class="invalid-feedback">{{addNumberErrorText}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end bgc-t">
      <div class="d-flex">
        <a (click)="getDefault()" href="javascript:void(0)" class="btn btn-link bgc-t">Сбросить</a>
        <button [class.disabled]="isUpdatingLoading" type="submit" (click)="updateProfile()" class="btn btn-primary ms-auto">
          <span *ngIf="isUpdatingLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
          <svg *ngIf="!isUpdatingLoading" xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><circle cx="12" cy="14" r="2" /><polyline points="14 4 14 8 8 8 8 4" /></svg>
          Сохранить</button>
      </div>
    </div>
  </div>
