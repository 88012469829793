<div *ngIf="fileUploaded" class="alert alert-important alert-success alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
    </div>
    <div>
      Ваш профиль обновлен
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>
<div *ngIf="fileAvatarRemoved" class="alert alert-important alert-success alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>
    </div>
    <div>
      Аватар удален
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>
<div *ngIf="fileUploadErr" class="alert alert-important alert-warning alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
    </div>
    <div>
      {{fileUploadErrText}}
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>
<div class="card">
  <div class="card-header">
    <h3 class="card-title">Аватар</h3>
  </div>
  <div class="card-body text-center py-4 p-sm-5">
    <span *ngIf="managerService.manager?.avatar && managerService.avatarBase64" [style.background-image]="'url(data:image/jpeg;base64,' + managerService.avatarBase64 + ')'" class="avatar avatar-xl"></span>
    <span *ngIf="!managerService.manager?.avatar" class="avatar avatar-xl">{{managerService.getStringAvatar()}}</span>
    <h3 class="mt-4">{{managerService.manager?.name}} {{managerService.manager?.surname}}</h3>
    <p *ngIf="!managerService.manager?.avatar" class="text-muted">Для комфортного общения между сотрудниками, клиентами и водителями рекомендуем установить аватар</p>
    <p *ngIf="managerService.manager?.avatar"  class="text-muted">У вас уже установлен автар. Вы сможете его удалить или обновить</p>
    <div class="btn-list centered">
      <a *ngIf="managerService.manager?.avatar" [class.disabled]="fileRemoveLoaded" (click)="removeAvatar()" href="javascript:void(0)" class="btn btn-danger">
        <span *ngIf="fileRemoveLoaded" class="spinner-border spinner-border-sm me-2" role="status"></span>
        Удалить
      </a>
      <div class="mb-3">
        <input type="file" (change)="handleFileInput($event)" class="form-control">
      </div>
    </div>
  </div>
</div>
