import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Manager} from "../../../../../../app-nestjs/src/modules/manager/manager.interface";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {BehaviorSubject, fromEvent, merge, Observable, Subject} from "rxjs";
import {SelfEmployedCheck} from "../../../../../../app-nestjs/src/modules/self-employed-check/self-employed-check.interface";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {GetManagerComponent} from "../../manager/get/get.manager.component";
import {BsDatepickerConfig, BsLocaleService} from "ngx-bootstrap/datepicker";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {CreateSelfEmployedCheckComponent} from "../create/create.self-employed-check.component";
import {ToastrService} from "ngx-toastr";
import {AdmonitionService} from "../../../services/admonition.service";

@Component({
  selector: 'app-list-self-employed-check',
  templateUrl: './list.self-employed-check.component.html',
  styleUrls: ['./list.self-employed-check.component.scss']
})
export class ListSelfEmployedCheckComponent implements OnInit, OnDestroy {
  @ViewChild('PrintedElem') PrintedElem: ElementRef | undefined;

  bsModalRef: BsModalRef | undefined

  public bsConfig: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    containerClass: 'theme-dark-blue',
    showClearButton: true,
    clearPosition: 'center',
    clearButtonLabel: 'Удалить дату'
  }

  public arrayToPrint: string[] = []

  public isInit: boolean = false
  public isLoading: boolean = true
  public offset: number = 0
  public limit: number = 10
  public name: string | null = null
  public date: Date | null = null
  public amount: string | null = null
  public isNotPrinted: boolean = false
  public isMouseOverPrint: boolean = false

  public events = new Subject<any>()

  public checks$ = new BehaviorSubject<SelfEmployedCheck.SelfEmployedCheckListInterface[]>([])


  constructor(
    private readonly http: HttpClient,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    public readonly constService: AdmonitionService,
    private toastr: ToastrService,
  ) {
    this.events.pipe(
      debounceTime(500),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      tap(async (event) => {
        this.checks$ = new BehaviorSubject<SelfEmployedCheck.SelfEmployedCheckListInterface[]>([])
        await this.load()
        return;
      })
    ).subscribe();
  }

  async ngOnInit(): Promise<void> {
    this.localeService.use('ru')
    this.isLoading = true
    await this.load()
    this.isInit = true
  }

  ngOnDestroy(): void {
    this.events.unsubscribe()
  }

  ngAfterViewInit(): void {
    const mouseOver$ = fromEvent(this.PrintedElem?.nativeElement, 'mouseover')
    const mouseLeave$ = fromEvent(this.PrintedElem?.nativeElement, 'mouseleave')
    const allEvents$ = merge(
      mouseLeave$,
      mouseOver$
    );
    allEvents$.subscribe((val) => {
      let data = val as MouseEvent
      if (data.type === 'mouseleave') {
        this.isMouseOverPrint = false
      }
      if (data.type === 'mouseover') {
        this.isMouseOverPrint = true
      }
    });
  }

  // Загрузить чеки
  public async load(): Promise<void> {
    this.isLoading = true
    const json = {
      offset: this.offset,
      limit: this.limit,
      date: this.date,
      amount: this.amount,
      name: this.name,
      isPrinted: !this.isNotPrinted
    }
    if (this.checks$.getValue().length < this.offset) {
      this.isLoading = false
      return;
    }
    this.offset = this.offset + 10
    await this.http.post<SuccessResponseInterface>('/api/manager/self-employed-checks/list', json).toPromise()
      .then((data) => {
        for (const key of data.data as SelfEmployedCheck.SelfEmployedCheckListInterface[]) {
          key.needPrint = false
          this.checks$.next(this.checks$.getValue().concat([key]));
        }
        this.isLoading = false
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  // Получить текстовый аватар
  public getTextAvatar(name: Manager.name | undefined, surname: Manager.surname | undefined): any {
    if (typeof name == 'string' && typeof surname == 'string') {
      return name[0] + surname[0]
    }
  }

  public async openModalWithComponent(manager: Manager.ManagerInterface) {
    const initialState = {
      manager: manager
    };
    // @ts-ignore
    this.bsModalRef = this.modalService.show(GetManagerComponent, {initialState});
  }

  public changeModelName(value: string): void {
    if (!value) {
      this.name = null
    }
    this.offset = 0
    this.events.next({
      offset: this.offset,
      limit: this.limit,
      date: this.date,
      amount: this.amount,
      name: this.name,
      isPrinted: !this.isNotPrinted
    })
  }

  public changeModelIsNotPrinted(value: boolean): void {
    this.offset = 0
    this.events.next({
      offset: this.offset,
      limit: this.limit,
      date: this.date,
      amount: this.amount,
      name: this.name,
      isPrinted: !this.isNotPrinted
    })
  }

  public changeModelAmount(value: string): void {
    if (!value) {
      this.amount = null
    }
    this.offset = 0
    this.events.next({
      offset: this.offset,
      limit: this.limit,
      date: this.date,
      amount: this.amount,
      name: this.name,
      isPrinted: !this.isNotPrinted
    })
  }

  public bsChangeModelDate(value: any): void {
    if (value === null && this.date == null) {
      return;
    }
    if (value === undefined) {
      this.date = null
    }
    this.offset = 0
    this.events.next({
      offset: this.offset,
      limit: this.limit,
      date: this.date,
      amount: this.amount,
      name: this.name,
      isPrinted: !this.isNotPrinted
    })
  }

  public async openModalCreateWithComponent() {
    // @ts-ignore
    this.bsModalRef = this.modalService.show(CreateSelfEmployedCheckComponent);
  }

  public async sendPrint(check: SelfEmployedCheck.SelfEmployedCheckListInterface): Promise<void> {
    let body = {
      id: check.id,
      isPrinted: true
    }
    await this.http.post('/api/manager/self-employed-checks/set-is-print', body).toPromise()
      .then((data) => {
        check.isPrinted = true
        return;
      })
      .catch((err) => {
        this.toastr.error('Во время установки значения, что чек распечатан произошла ошибка. Обратитесь к администратору', 'Ошибка');
        return;
      })
  }

  async print(check: SelfEmployedCheck.SelfEmployedCheckListInterface): Promise<void> {
    const iframe: any = document.createElement('iframe');
    iframe.style.height = 0;
    iframe.style.visibility = 'hidden';
    iframe.style.width = 0;
    iframe.setAttribute('srcdoc', '<html><body></body></html>');
    document.body.appendChild(iframe);

    iframe.addEventListener('load', async () => {
      // @ts-ignore
      const image = document.getElementById(check.url).cloneNode();
      // @ts-ignore
      image.style.maxWidth = '100%';
      const body = iframe.contentDocument.body;
      body.style.textAlign = 'center';
      body.appendChild(image);
      image.addEventListener('load', async () => {
        iframe.contentWindow.print();
      });
      iframe.contentWindow.addEventListener('afterprint', async () => {
        await this.sendPrint(check)
      })
    });
  }

  public clearFilter(): void {
    this.offset = 0
    this.limit = 10
    this.name = null
    this.date = null
    this.amount = null
    this.isNotPrinted = false
    this.changeModelName('')
  }

  public getNeedPrint(): boolean {
    if (this.isMouseOverPrint) {
      return true
    }
    let data = this.checks$.getValue()
    let needPrint = false
    for (let key of data) {
      if (key.needPrint) {
        needPrint = true
      }
    }
    return needPrint
  }

  public async printAll(): Promise<void> {
    let data = this.checks$.getValue()
    for await (let key of data) {
      if (key.needPrint) {
        await this.print(key)
        await this.sendPrint(key)
        key.needPrint = false
      }
    }
  }

  public setDefaultPrintAll(): void {
    let data = this.checks$.getValue()
    for (let key of data) {
      key.needPrint = false
    }
  }

  public isPrintedInstalled(): boolean {
    let data = this.checks$.getValue()
    let value = false
    for (let key of data) {
      if (key.needPrint) {
        value = true
      }
    }
    return value
  }

  public checkAll(): void {
    let data = this.checks$.getValue()
    for (let key of data) {
      if (!key.isPrinted) {
        key.needPrint = true
      }
    }
  }

}
