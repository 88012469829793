import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../services/title.service";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {AdmonitionService} from "../../services/admonition.service";
import {Driver} from "../../../../../app-nestjs/src/modules/driver/driver.interface";
import {ManagerService} from "../../services/manager.service";
import {GenderEnum} from "../../../../../app-nestjs/src/constants/gender.enum";
import {Manager} from "../../../../../app-nestjs/src/modules/manager/manager.interface";
import {GetManagerComponent} from "../manager/get/get.manager.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {DriverLicense} from "../../../../../app-nestjs/src/modules/driver-license/driver-license.interface";
import {BehaviorSubject, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {SelfEmployedCheck} from "../../../../../app-nestjs/src/modules/self-employed-check/self-employed-check.interface";
import {ToastrService} from "ngx-toastr";

export enum DriverLicenseStatus {
  'noDriverLicense' = 'noDriverLicense',
  'noScanDriverLicense' = 'noScanDriverLicense',
  'expirationDriverLicense' = 'expirationDriverLicense',
  'driverLicenseOk' = 'driverLicenseOk'
}

export interface DriverListInterfaceForManagerAdditional extends Driver.DriverListInterfaceForManager {
  val?: string
}

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit {

  public isLoading: boolean = true
  public isDriversLoading: boolean = false
  public managers: Driver.LogistDriverInterface[] = []
  public drivers: DriverListInterfaceForManagerAdditional[] = []
  private offset: number = 0
  private limit: number = 20

  public events = new Subject<any>()
  public actionButtonDisabled: boolean = false

  public stateDriverLicense: boolean = false
  public genderEnum = GenderEnum
  public bsModalRef: BsModalRef | undefined
  public driverLicenseStatus = DriverLicenseStatus

  public logistId: Driver.managerId | null = null
  public rating: number | null = null
  public isAccepted: boolean | null = null
  public avatar: boolean | null = null
  public driverLicense: boolean | null = null
  public withExpirationDriverLicense: boolean = false
  public withoutScanDriverLicense: boolean = false
  public gender: GenderEnum | null = null
  public isFired: boolean | null = false
  public description: string = ''

  constructor(
    private readonly http: HttpClient,
    public readonly constService: AdmonitionService,
    public readonly titleService: TitleService,
    public readonly managerService: ManagerService,
    private modalService: BsModalService,
    private readonly toastService: ToastrService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadManagers()
    await this.loadDrivers()
    this.events.pipe(
      debounceTime(300),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      tap(async (event) => {
        this.drivers = []
        this.offset = 0
        this.limit = 20
        await this.loadDrivers()
        return;
      })
    ).subscribe();
  }

  private async loadManagers(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/drivers/logists', {}).toPromise()
      .then((data) => {
        for (const key of data.data) {
          this.managers.push(key)
        }
        this.isLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }

  public getAvatarText(surname: string, name: string) {
    return surname[0] + name[0]
  }

  public getRandom(): string {
    const array = [
      'bg-blue-lt',
      'bg-azure-lt',
      'bg-indigo-lt',
      'bg-purple-lt',
      'bg-pink-lt',
      'bg-red-lt',
      'bg-orange-lt',
      'bg-yellow-lt',
      'bg-lime-lt',
      'bg-green-lt',
      'bg-teal-lt',
      'bg-cyan-lt'
    ]
    return array[Math.floor(Math.random()*array.length)];
  }

  public getLogistName(key: Driver.LogistDriverInterface): string {
    let managerId = this.managerService.manager?.id
    if (managerId === key.managerId) {
      return 'Я (' + key.manager.name + ' ' + key.manager.surname + ')'
    }
    return key.manager.name + ' ' + key.manager.surname
  }

  public onChange(): any {
    if (this.driverLicense) {
      this.stateDriverLicense = true
    } else {
      this.stateDriverLicense = false
      this.withExpirationDriverLicense = false
      this.withoutScanDriverLicense = false
    }
    this.events.next({
      offset: this.offset,
      limit: this.limit,
      isAccepted: this.isAccepted,
      isFired: this.isFired,
      description: (this.description || null),
      avatar: this.avatar,
      gender: this.gender,
      logist: this.logistId,
      driverLicense: this.driverLicense,
      driverWithoutScanLicense: (this.withoutScanDriverLicense || null),
      driverWithExpirationDriverLicense: (this.withExpirationDriverLicense || null)
    })
  }

  public async openModalWithComponent(manager: Manager.ManagerInterface) {
    const initialState = {
      manager: manager
    };
    // @ts-ignore
    this.bsModalRef = this.modalService.show(GetManagerComponent, {initialState})
  }

  // Получить текстовый аватар
  public getTextAvatar(name: Manager.name | undefined, surname: Manager.surname | undefined): any {
    if (typeof name == 'string' && typeof surname == 'string') {
      if (surname && name) {
        return name[0] + surname[0]
      }
      if (name) {
        return name[0]
      }
    }
  }

  public async loadDrivers(): Promise<any> {
    if (this.drivers.length !== 0) {
      this.offset = this.offset + 20
    }
    this.isDriversLoading = true
    let data = await this.http.post<SuccessResponseInterface>('/api/manager/drivers/list', {
      offset: this.offset,
      limit: this.limit,
      isAccepted: this.isAccepted,
      isFired: this.isFired,
      avatar: this.avatar,
      gender: this.gender,
      logist: this.logistId,
      driverLicense: this.driverLicense,
      driverWithoutScanLicense: (this.withoutScanDriverLicense || null),
      driverWithExpirationDriverLicense: (this.withExpirationDriverLicense || null),
      description: this.description
    }).toPromise()
      .then((data) => {
        this.isDriversLoading = false
        let drivers: DriverListInterfaceForManagerAdditional[] = data.data
        for(let key of drivers) {
          key.val = this.getRandom()
          this.drivers.push(key)
        }
      })
      .catch((err: ErrorResponseInterface) => {
        this.isDriversLoading = false
      })
  }

  public getDriverLicenseStatus(driverLicense: DriverLicense.DriverLicenseInterface | null): any {
    if (driverLicense === null) {
      return this.driverLicenseStatus.noDriverLicense
    }
    if (driverLicense && !driverLicense.frontScan && !driverLicense.backScan) {
      return this.driverLicenseStatus.noScanDriverLicense
    }
    if (driverLicense && driverLicense.frontScan && driverLicense.backScan && !this.getExpirationDriverLicense(driverLicense)) {
      return this.driverLicenseStatus.driverLicenseOk
    }
  }

  public getExpirationDriverLicense(driverLicense: DriverLicense.DriverLicenseInterface | null): any {
    if (driverLicense && driverLicense.toDate) {
      let date: number | Date = new Date();
      date.setMonth(date.getMonth() + 1);
      date = Math.round((new Date(date)).getTime() / 1000)
      let driverLicenseDate: Date | number = driverLicense.toDate
      driverLicenseDate = Math.round((new Date(driverLicenseDate)).getTime() / 1000)
      if (driverLicenseDate < date) {
        return this.driverLicenseStatus.expirationDriverLicense
      }
      return false
    }
  }

  public async reformatDrivers(): Promise<void> {
    this.actionButtonDisabled = true
    await this.http.post('/api/manager/drivers/reformat-descriptions', {}).toPromise()
      .then((data) => {
        this.actionButtonDisabled = false
        this.toastService.clear()
        this.toastService.success('Реформатирование ФИО водителей завершено', 'Успешно')
      })
      .catch((err) => {
        this.actionButtonDisabled = false
        this.toastService.error('Во время реформатирования ФИО водителей произошла ошибка', 'Ошибка')
      })
  }

  public async recreateIndex(): Promise<void> {
    this.actionButtonDisabled = true
    await this.http.post('/api/manager/drivers/recreate-index', {}).toPromise()
      .then((data) => {
        this.actionButtonDisabled = false
        this.toastService.clear()
        this.toastService.success('Индекс для водителей реструктуризирован', 'Успешно')
      })
      .catch((err) => {
        this.actionButtonDisabled = false
        this.toastService.error('Во время реструктуризации индекса водителей произошла ошибка', 'Ошибка')
      })
  }

  public defaultFilter(): void {
    this.logistId = null
    this.rating = null
    this.isAccepted = null
    this.avatar = null
    this.driverLicense = null
    this.withExpirationDriverLicense = false
    this.withoutScanDriverLicense = false
    this.gender = null
    this.isFired = false
    this.description = ''
    this.events.next({
      offset: this.offset,
      limit: this.limit,
      isAccepted: this.isAccepted,
      isFired: this.isFired,
      description: (this.description || null),
      avatar: this.avatar,
      gender: this.gender,
      logist: this.logistId,
      driverLicense: this.driverLicense,
      driverWithoutScanLicense: (this.withoutScanDriverLicense || null),
      driverWithExpirationDriverLicense: (this.withExpirationDriverLicense || null)
    })
  }
}
