import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-create-category',
  templateUrl: './create.category.component.html',
  styleUrls: ['./create.category.component.scss']
})
export class CreateCategoryComponent implements OnInit {


  public isCreating: boolean = false
  public isCreated: boolean = false

  public isErr: boolean = false

  public isCapacityErr: boolean = false
  public isDescriptionErr: boolean = false
  public isSynonymErr: boolean = false
  public isHeightErr: boolean = false
  public isImageErr: boolean = false
  public isLengthErr: boolean = false
  public isShortNameErr: boolean = false
  public isVolumeErr: boolean = false
  public isWidthErr: boolean = false

  public capacityErr: string = ''
  public descriptionErr: string = ''
  public synonymErr: string = ''
  public heightErr: string = ''
  public lengthErr: string = ''
  public shortNameErr: string = ''
  public volumeErr: string = ''
  public widthErr: string = ''

  public capacity: string = ''
  public description: string = ''
  public synonym: string = ''
  public height: string = ''
  public length: string = ''
  public shortName: string = ''
  public volume: string = ''
  public width: string = ''

  constructor(
    private readonly http: HttpClient,
    private readonly toastrService: ToastrService
  ) { }

  ngOnInit(): void {
  }

  public clear(): void {
    this.description = ''
    this.isCreating = false
    this.isCreated = false
    this.clearErrors()
  }

  public clearErrors(): void {

    this.isErr = false
    this.isCapacityErr = false
    this.isDescriptionErr = false
    this.isSynonymErr = false
    this.isHeightErr = false
    this.isImageErr = false
    this.isLengthErr = false
    this.isShortNameErr = false
    this.isVolumeErr = false
    this.isWidthErr = false

    this.capacityErr = ''
    this.descriptionErr = ''
    this.synonymErr = ''
    this.heightErr = ''
    this.lengthErr = ''
    this.shortNameErr = ''
    this.volumeErr = ''
    this.widthErr = ''
  }

  async create(): Promise<void> {
    this.clearErrors()
    this.isErr = false
    this.isCreating = true
    const json = {
      "capacity": parseInt(this.capacity),
      "description": this.description,
      "synonym": this.synonym,
      "height": parseInt(this.height),
      "length": parseInt(this.length),
      "shortName": this.shortName,
      "volume": parseInt(this.volume),
      "width": parseInt(this.width)
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/categories/create', json).toPromise()
      .then((data) => {
        this.isCreating = false
        this.isErr = false
        this.isCreated = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.isCreating = false
        this.isErr = true
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Вместительность не может быть менее 1 кг') {
              this.capacityErr = key
              this.isCapacityErr = true
            }
            if (key === 'Полное наименование указано неверно') {
              this.descriptionErr = key
              this.isDescriptionErr = true
            }
            if (key === 'Синоним указане неверно') {
              this.synonymErr = key
              this.isSynonymErr = true
            }
            if (key === 'Высота не может быть меньше 1 сантиметра') {
              this.isHeightErr = true
              this.heightErr = key
            }
            if (key === 'Длина не может быть меньше 1 сантиметра') {
              this.lengthErr = key
              this.isLengthErr = true
            }
            if (key === 'Сокращенное наименование указано неверно') {
              this.shortNameErr = key
              this.isShortNameErr = true
            }
            if (key === 'Объем не может быть меньше 1 кубического сантиметра') {
              this.volumeErr = key
              this.isVolumeErr = true
            }
            if (key === 'Ширина не может быть меньше 1 сантиметра') {
              this.widthErr = key
              this.isWidthErr = true
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          if (err.error.message === 'Категория с таким полным именем уже существует') {
            this.isDescriptionErr = true
            this.descriptionErr = err.error.message
          }
          if (err.error.message === 'Категория с таким сокращенным именем уже существует') {
            this.isShortNameErr = true
            this.shortNameErr = err.error.message
          }
          if (err.error.message === 'Категория с таким синонимом уже существует') {
            this.isSynonymErr = true
            this.synonymErr = err.error.message
          }
          return;
        }
      })
  }

}
