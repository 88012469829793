<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div *ngIf="constService.get(constService.itemTypes.rolesAdmonition)" class="alert alert-important alert-info alert-dismissible" role="alert">
                <div class="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><line x1="12" y1="8" x2="12.01" y2="8"></line><polyline points="11 12 12 12 12 16 13 16"></polyline></svg>
                  </div>
                  <div>
                    Информация предоставленная на странице является константой и не может быть изменена самостоятельно
                  </div>
                </div>
                <a (click)="constService.add(constService.itemTypes.rolesAdmonition)" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
              </div>
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Роли сотрудников
                        </h2>
                        <div class="text-muted mt-1">Всего {{roles.length}} прав доступа</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>

                <div class="col-md-12">
                  <div class="card mb-3">
                    <div class="card-header">
                      <h3 class="card-title">Список прав пользователей</h3>
                    </div>
                    <div class="list-group list-group-flush list-group-hoverable">
                      <div *ngFor="let role of roles" class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <a href="javascript:void(0)">
                              <span [classList]="true ? role.val + ' avatar'  : 'avatar'" style="">{{getName(role?.name)}}</span>
                            </a>
                          </div>
                          <div class="col text-truncate">
                            <a href="javascript:void(0)" class="text-body d-block">{{role?.name}}</a>
                            <small class="d-block text-muted text-truncate mt-n1">{{role?.description}}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
