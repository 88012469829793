<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="container-xl">
          <div class="row row-cards">

            <div *ngIf="constService.get(constService.itemTypes.selfEmployedCheckAdmonition)" class="alert alert-important alert-info alert-dismissible"
                 role="alert">
              <div class="d-flex">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24"
                       viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                       stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="12" r="9"/>
                    <line x1="12" y1="8" x2="12.01" y2="8"/>
                    <polyline points="11 12 12 12 12 16 13 16"/>
                  </svg>
                </div>
                <div>
                  Инструмент для регистрации чеков самозанятых в 1С. Отображается список ранее зарегистрированных через
                  систему чеков, которые успешно обработаны в 1С. Регистрация чека производится только если все данные
                  сходятся
                </div>
              </div>
              <a class="btn-close btn-close-white" (click)="constService.add(constService.itemTypes.selfEmployedCheckAdmonition)" data-bs-dismiss="alert"
                 aria-label="close"></a>
            </div>

            <div class="container-xl">
              <div class="page-header d-print-none">
                <div class="row align-items-center">
                  <div class="col">
                    <h2 class="page-title">
                      Чеки самозанятых
                    </h2>
                    <div class="text-muted mt-1">Всего {{(checks$ | async)?.length}} чека(-ов)</div>
                  </div>
                  <div class="col-auto ms-auto d-print-none">
                    <div class="btn-list">
                      <a href="javascript:void(0)" (click)="openModalCreateWithComponent()"
                         class="btn btn-primary d-none d-sm-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                             viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                             stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        Зарегистрировать
                      </a>
                      <a href="javascript:void(0)" (click)="openModalCreateWithComponent()"
                         class="btn btn-primary d-sm-none btn-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                             viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                             stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isLoading && !isInit" class="container-xl">
              <div class="loader loader-center"></div>
            </div>

            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8">
                      <div class="row g-2 mb-3">
                        <div class="col">
                          <div class="form-floating input-group input-group-flat">
                            <input type="text" [(ngModel)]="name" (ngModelChange)="changeModelName($event)"
                                   placeholder="" class="form-control">
                            <span class="input-group-text">
                                <a href="javascript:void(0)" (click)="changeModelName('')" class="link-secondary"
                                   title="" data-bs-toggle="tooltip" data-bs-original-title="Clear search">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                       viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                       stroke-linecap="round" stroke-linejoin="round"><path stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                            fill="none"></path><line
                                    x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18"
                                                                               y2="18"></line></svg>
                                </a>
                                </span>
                            <label>ФИО СЗ</label>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-floating input-group input-group-flat">
                            <input type="button"
                                   [style.text-align]="'left'"
                                   placeholder="Datepicker"
                                   (bsValueChange)="bsChangeModelDate($event)"
                                   [(ngModel)]="date"
                                   class="form-control" [placeholder]="'Дата чека'"
                                   bsDatepicker
                                   [bsConfig]="bsConfig">
                            <span class="input-group-text">
                                <a href="javascript:void(0)" (click)="bsChangeModelDate(undefined)"
                                   class="link-secondary"
                                   title="" data-bs-toggle="tooltip" data-bs-original-title="Clear search">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                       viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                       stroke-linecap="round" stroke-linejoin="round"><path stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                            fill="none"></path><line
                                    x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18"
                                                                               y2="18"></line></svg>
                                </a>
                                </span>
                            <label>Дата чека</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-floating input-group input-group-flat mb-3">
                        <input type="text" [(ngModel)]="amount" [dropSpecialCharacters]="false"
                               (ngModelChange)="changeModelAmount($event)" placeholder=""
                               thousandSeparator="." mask="0*.00" class="form-control">
                        <span class="input-group-text">
                                <a href="javascript:void(0)" (click)="changeModelAmount('')" class="link-secondary"
                                   title="" data-bs-toggle="tooltip" data-bs-original-title="Clear search"><!-- Download SVG icon from http://tabler-icons.io/i/x -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                       viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                       stroke-linecap="round" stroke-linejoin="round"><path stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                            fill="none"></path><line
                                    x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18"
                                                                               y2="18"></line></svg>
                                </a>
                                </span>
                        <label>Сумма чека</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <label class="form-check">
                          <input [(ngModel)]="isNotPrinted" (ngModelChange)="changeModelAmount($event)" class="form-check-input" type="checkbox">
                          <span class="form-check-label">
                                Не напечатанные
                              </span>
                          <span class="form-check-description">
                                Отобразить список чеков, которые еще не были отправлены на печать
                              </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="(checks$ | async)?.length == 0 && !isLoading" class="empty">
              <div class="empty-icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><line x1="9" y1="10" x2="9.01" y2="10"></line><line x1="15" y1="10" x2="15.01" y2="10"></line><path d="M9.5 15.25a3.5 3.5 0 0 1 5 0"></path></svg>
              </div>
              <p class="empty-title">Не найдено</p>
              <p class="empty-subtitle text-muted">
                По вашему фильтру ничего не найдено, попробуйте изменить фильтрацию списка
              </p>
              <div class="empty-action">
                <a href="javascript:void(0)" (click)="clearFilter()" class="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="10" cy="10" r="7"></circle><line x1="21" y1="21" x2="15" y2="15"></line></svg>
                  Сбросить фильтр
                </a>
              </div>
            </div>

            <div *ngFor="let check of checks$ | async" class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <a href="javascript:void(0)"><img [id]="check.url" [style.max-width.%]="70"
                                                        (click)="goToLink(check.url)"
                                                        [src]="check.url" class="rounded "></a>
                    </div>
                    <div class="col">
                      <h4 class="card-title mb-3">
                        <a href="javascript:void(0)">{{check.description}}</a>
                        <a style="margin-left: 0.5rem;" *ngIf="check?.isPrinted" href="javascript:void(0)" class="badge bg-green"></a>
                      </h4>
                      <dl class="row">
                        <dd class="col-3">Сумма:</dd>
                        <dd class="col-7">{{check.totalAmount}} руб</dd>
                        <dd class="col-3">ИНН Получателя:</dd>
                        <dd class="col-7">{{check.inn}}</dd>
                        <dd class="col-3">Номер чека</dd>
                        <dd class="col-7">{{check.receiptId}}</dd>
                        <dd class="col-3">ИНН Плательщика:</dd>
                        <dd class="col-7">{{check.clientInn}}</dd>
                        <dd class="col-3">Организация:</dd>
                        <dd class="col-7">{{check.clientDisplayName}}</dd>
                        <dd class="col-3">Операция</dd>
                        <dd class="col-7">{{check.service}}</dd>
                        <dd class="col-3">Дата регистрации чека</dd>
                        <dd class="col-7">{{check.createdAt | date: 'dd MMMM yyyy HH:mm' :'GMT+03:00'}}</dd>
                        <dd class="col-3">Дата оплаты (операции)</dd>
                        <dd class="col-7">{{check.operationTime | date: 'dd MMMM yyyy HH:mm' :'GMT+03:00'}}</dd>
                        <dd class="col-3">Чек создан получателем</dd>
                        <dd class="col-7">{{check.registerTime | date: 'dd MMMM yyyy HH:mm' :'GMT+03:00'}}</dd>
                      </dl>
                      <div class="d-flex align-items-center">
                        <a href="javascript:void(0)">
                          <span (click)="openModalWithComponent(check.manager)" *ngIf="!check.manager?.avatar"
                                class="avatar me-3 rounded"
                                style="">{{getTextAvatar(check.manager?.name, check.manager?.surname)}}
                            <span *ngIf="check.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span>
                            <span *ngIf="!check.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                          </span>
                          <span (click)="openModalWithComponent(check.manager)" *ngIf="check.manager?.avatar"
                                class="avatar me-3 rounded"
                                [style.background-image]="'url(' + check.manager?.avatar + ')'">
                            <span *ngIf="check.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span>
                            <span *ngIf="!check.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                          </span>
                        </a>
                        <div>
                          <a href="javascript:void(0)">
                            <div style="color: black"
                                 (click)="openModalWithComponent(check.manager)"> {{check.manager.surname}} {{check.manager.name}} {{check.manager.patronymic}}</div>
                          </a>
                          <div class="text-muted">{{check.manager.position}}</div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!check?.isPrinted" class="col-auto">
                      <label style="margin-bottom: 0rem;" class="form-check">
                        <input class="form-check-input" [(ngModel)]="check.needPrint" type="checkbox">
                      </label>
                    </div>
                    <div class="col-auto">
                      <div class="dropdown">
                        <a href="#" class="card-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="12" cy="19" r="1"></circle>
                            <circle cx="12" cy="5" r="1"></circle>
                          </svg>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                          <a (click)="print(check)" href="javascript:void(0)" class="dropdown-item">
                            Печать</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="search-results"
                  infiniteScroll
                  [infiniteScrollDistance]="2"
                  [infiniteScrollThrottle]="50"
                  (scrolled)="load()"
                ></div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #PrintedElem style="min-height: 3rem" class="hidden-div-printed">
      <div [hidden]="!getNeedPrint()"  class="footer-self-employed">
        <div class="card-footer text-end">
          <div class="container-xl">
            <div class="d-flex">
              <button (click)="checkAll()" type="submit" class="btn btn-primary">Выделить все</button>
              <a (click)="setDefaultPrintAll()" href="javascript:void(0)" class="btn btn-link">Сброс</a>
              <button [class.disabled]="!isPrintedInstalled()" (click)="printAll()" type="submit" class="btn btn-primary ms-auto">Распечатать</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>

</div>
</div>
