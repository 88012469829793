import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-create-carcase',
  templateUrl: './create.carcase.component.html',
  styleUrls: ['./create.carcase.component.scss']
})
export class CreateCarcaseComponent implements OnInit {

  public isCreating: boolean = false
  public isCreated: boolean = false
  public isErr: boolean = false
  public nameErr: String = ''

  public description: string = ''
  public awning: boolean = false
  public conics: boolean = false
  public flatFloor: boolean = true
  public katyusha: boolean = false
  public manipulator: boolean = false
  public pyramid: boolean = false
  public sanitization: boolean = false
  public tailLift: boolean = false
  public temperatureRegime: boolean = false

  constructor(
    private readonly http: HttpClient,
    private readonly toastrService: ToastrService
  ) { }

  ngOnInit(): void {
  }

  public clear(): void {
    this.description = ''
    this.awning = false
    this.conics = false
    this.flatFloor = true
    this.katyusha = false
    this.manipulator = false
    this.pyramid = false
    this.sanitization = false
    this.tailLift = false
    this.temperatureRegime = false
    this.isCreating = false
    this.isCreated = false
    this.clearErrors()
  }

  public clearErrors(): void {
    this.isErr = false
    this.nameErr = ''
  }

  async register(): Promise<void> {
    this.clearErrors()
    this.isErr = false
    this.isCreating = true
    const json = {
      "awning": this.awning,
      "conics": this.conics,
      "description": this.description,
      "flatFloor": this.flatFloor,
      "katyusha": this.katyusha,
      "manipulator": this.manipulator,
      "pyramid": this.pyramid,
      "sanitization": this.sanitization,
      "tailLift": this.tailLift,
      "temperatureRegime": this.temperatureRegime
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/carcases/create', json).toPromise()
      .then((data) => {
        this.isCreating = false
        this.isErr = false
        this.isCreated = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.isCreating = false
        this.isErr = true
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          if (err.error.message === 'Уже существует кузов с таким именем') {
            this.isErr = true;
            this.nameErr = err.error.message
          }
          return;
        }
      })
  }

}
