<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Сотрудники
                        </h2>
                        <div class="text-muted mt-1">Всего {{managers.length}} сотрудника(-ов)</div>
                      </div>
                      <div class="col-auto ms-auto d-print-none">
                        <div class="btn-list">
                          <a href="javascript:void(0)" [routerLink]="['', 'managers', 'create']" class="btn btn-primary d-none d-sm-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            Зарегистрировать
                          </a>
                          <a href="javascript:void(0)" [routerLink]="['', 'managers', 'create']" class="btn btn-primary d-sm-none btn-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>

                <div *ngFor="let manager of managers" class="col-md-6 col-xl-3">
                  <a href="javascript:void(0)" (click)="openModalWithComponent(manager)" class="card card-link">
                    <div class="card-body text-center">
                      <div class="mb-3">
                        <span *ngIf="!manager.avatar" class="avatar avatar-xl" style="">{{getTextAvatar(manager.name, manager.surname)}}
                          <span [style.width.rem]="0.5" [style.height.rem]="0.5" *ngIf="manager?.isOnline" class="badge bg-success"></span>
                          <span *ngIf="!manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                        </span>
                        <span *ngIf="manager.avatar" class="avatar avatar-xl" [style.background-image]="'url(' + manager.avatar + ')'">
                          <span [style.width.rem]="0.5" [style.height.rem]="0.5" *ngIf="manager?.isOnline" class="badge bg-success"></span>
                          <span *ngIf="!manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                        </span>
                      </div>
                      <div class="card-title mb-1">{{manager.name}} {{manager.surname}}</div>
                      <div class="text-muted">{{manager.position}}</div>
                      <div *ngIf="!manager.isFired" class="small mt-1">
                        Работает
                      </div>
                      <div *ngIf="manager.isFired" class="small mt-1">
                        Уволен
                      </div>
                    </div>
                    <div class="d-flex">
                      <a href="javascript:void(0)" [routerLink]="['', 'managers', 'edit', manager.id]" class="card-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon me-2 text-muted" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" /><path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" /><line x1="16" y1="5" x2="19" y2="8" /></svg>
                        Редактировать</a>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
