import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../services/title.service";
import {BsDatepickerConfig, BsLocaleService} from "ngx-bootstrap/datepicker";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {Manager} from "../../../../../app-nestjs/src/modules/manager/manager.interface";
import {CdrInterface} from "../../../../../app-nestjs/src/modules/cdr/cdr.interface";
import {ToastrService} from "ngx-toastr";
import {BsModalService} from "ngx-bootstrap/modal";
import {GetManagerComponent} from "../manager/get/get.manager.component";
import {ManagerService} from "../../services/manager.service";
import { saveAs } from "file-saver";

enum getDialTypeEnum {
  'internal' = 'internal',
  'external' ='external',
  'err' = 'err',
  'in' = 'in'
}

@Component({
  selector: 'app-cdr',
  templateUrl: './cdr.component.html',
  styleUrls: ['./cdr.component.scss']
})
export class CdrComponent implements OnInit, OnDestroy{

  public isLoading: boolean = true
  public firstRun: boolean = true

  public incomingNumber: string = ''
  public internalNumber: string = '105'
  public date: Date[] = []
  public duration: string = '15'
  public limit: string = '30'

  public audio: HTMLAudioElement = new Audio();
  public totalAudio: number = 0
  public currentAudioPrc: number = 0
  public currentAudio: number = 0
  private url: any
  public playing: string = ''


  public isIncomingPhoneErr: boolean = false
  public isInternalPhoneErr: boolean = false
  public isDurationErr: boolean = false
  public isDateErr: boolean = false

  public internalPhoneErrText: string = ''
  public incomingPhoneErrText: string = ''
  public durationErrText: string = ''
  public dateErrText: string = ''

  public minDate: Date;
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig> = {
    adaptivePosition: false,
    containerClass: 'theme-dark-blue',
    showClearButton: true,
    clearPosition: 'center',
    clearButtonLabel: 'Удалить дату'
  }

  public items:CdrInterface[] = []

  constructor(
    private readonly http: HttpClient,
    public readonly toast: ToastrService,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private readonly managerService: ManagerService
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 90);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    let startDate = new Date()
    let endDate = new Date()
    startDate.setDate(startDate.getDate() - 90)
    endDate.setDate(endDate.getDate() + 1)
    this.date = [startDate, endDate]
  }

  async ngOnInit(): Promise<void> {
    this.internalNumber = this.managerService.manager?.addNum ?? ''
    this.localeService.use('ru')
    this.isLoading = false
  }

  ngOnDestroy() {
    this.stopAndDelete()
  }

  private clearErr() {
    this.isIncomingPhoneErr = false
    this.isInternalPhoneErr = false
    this.isDurationErr = false
    this.isDateErr = false

    this.internalPhoneErrText = ''
    this.incomingPhoneErrText = ''
    this.durationErrText = ''
    this.dateErrText = ''
  }

  async load(): Promise<any> {
    this.clearErr()
    this.firstRun = false
    this.isLoading = true
    this.items = []
    let body = {
      "src": this.incomingNumber,
      "dst": this.internalNumber,
      "offset": 0,
      "limit": Number(this.limit),
      "duration": Number(this.duration),
      "fromDate": this.date[0],
      "toDate": this.date[1]
    }
    this.stopAndDelete()
    await this.http.post<SuccessResponseInterface>('/api/manager/cdr/list', body).toPromise()
      .then((data) => {
        this.items = []
        for (let key of data.data as CdrInterface[]) {
          this.items.push(key as CdrInterface)
        }
        this.isLoading = false
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
        if (Array.isArray(err.error.message)) {
          for (let key of err.error.message) {
            if (key === 'Номер телефона указан неверно') {
              this.incomingPhoneErrText = key
              this.isIncomingPhoneErr = true
            }
            if (key === 'Номер звонящего должен содержать 12 символов') {
              this.incomingPhoneErrText = key
              this.isIncomingPhoneErr = true
            }
            if (key === 'Добавочный номер не может содержать более или менее 3 цифр') {
              this.internalPhoneErrText = key
              this.isInternalPhoneErr = true
            }
            if (key === 'Не менее 1 секунды') {
              this.durationErrText = key
              this.isDurationErr = true
            }
            if (key === 'Время указано неверно') {
              this.dateErrText = key
              this.isDateErr = true
            }
          }
          return;
        }
      })
  }

  public getManagerName(manager: Manager.ManagerInterface | null | undefined, number: string | undefined): string {
    if (!manager && !number) {
      return 'Не удалось определить входящий номер'
    }
    if (!manager && number) {
      return number
    }
    return manager?.name + ' ' + manager?.surname + ' (' + manager?.addNum + ')'
  }

  public getFormatNumber(value: string | undefined): string {
    if (value === undefined) {
      return 'Номер не определен'
    }
    if (value.length == 11 && value[0] == '7') {
      return '+' + value
    }
    if (value.length == 11 && value[0] == '8') {
      return '+7' + value.slice(1);
    }
    return value
  }

  public getTimer(value: number | undefined): string {
    if (value === undefined) {
      return '00:00'
    }
      value %= 3600;
      let minutes: any = Math.floor(value / 60);
      let seconds: any = value % 60;
      minutes = String(minutes).padStart(2, "0");
      seconds = String(seconds).padStart(2, "0");
      return minutes + ":" + seconds
  }

  public formatDate(date: string | undefined): string {
    if (!date) {
      return 'В неопределенное время'
    }
    let datetime = new Date(date);
    return datetime.toLocaleString('ru-Ru', {timeZone: 'Europe/Moscow', weekday: 'long', month: 'short', minute: 'numeric', hour: 'numeric', day: 'numeric', year: 'numeric'})
  }

  public recordNotFound(item: CdrInterface): void {
    this.stopAndDelete()
    this.toast.clear()
    this.toast.error('Файл с разговором ' + item?.uniqueid + ' был удален на сервере телефонии', 'Запись удалена')
    return;
  }

  public async openModalWithComponent(manager: Manager.ManagerInterface | undefined | null) {
    if (!manager) {
      this.toast.clear()
      this.toast.info('Менеджер с указанным добавочным номером не найден в системе', 'Менеджер не зарегистрирован')
      return;
    }
    const initialState = {
      manager: manager
    };
    // @ts-ignore
    this.bsModalRef = this.modalService.show(GetManagerComponent, {initialState});
  }

  public async playAudio(item: CdrInterface): Promise<void> {
    if (this.audio.title === item.uniqueid && this.audio) {
      await this.audio.play().then((data) => {
        this.audio.title = item.uniqueid as string
        this.playing = item.uniqueid as string
        return;
      })
      return;
    }
    let file = await this.http.post('/api/manager/cdr/get-record', {path: item.path}, {
      responseType: 'arraybuffer',
    }).toPromise()
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return null;
      })
    if (!file) {
      this.toast.clear()
      this.toast.error('Не удалось воспроизвести файл так как его не удалось скачать', 'Ошибка')
      return;
    }
    const blob = new Blob([file], {
      type: 'audio/wav'
    });
    this.audio.autoplay = false
    this.url = window.URL.createObjectURL(blob);
    this.audio.src = this.url
    this.audio.onloadedmetadata = () => {
      this.totalAudio = Math.floor(this.audio.duration);
      this.audio.addEventListener('timeupdate', (event) => {
        this.currentAudioPrc = this.audio.currentTime / this.totalAudio * 100
        this.currentAudio = Math.floor(this.currentAudioPrc)
      }, false);
    };
    await this.audio.play().then((data) => {
      this.audio.currentTime = 0;
      this.audio.addEventListener("ended", () => {
        this.audio.currentTime = 0;
        this.stopAndDelete()
      });
    })
    this.audio.title = item.uniqueid as string
    this.playing = item.uniqueid as string
    return;
  }

  public async pause(item: CdrInterface): Promise<void> {
    this.playing = ''
    this.audio.pause()
  }

  private stopAndDelete(): void {
    this.playing = ''
    this.audio.pause()
    this.audio.remove()
    this.audio = new Audio()
    this.url = window.URL.revokeObjectURL(this.url);
  }

  public changeCurrentTime(event: any): void {
    if (event.target.getAttribute('name') === 'progress-primary') {
      let max = event.srcElement.clientWidth
      let value = event.layerX
      let prc = Math.floor(value / max * 100)
      this.audio.currentTime = this.audio.duration / 100 * prc
      this.currentAudioPrc = this.audio.currentTime / this.totalAudio * 100
      this.currentAudio = Math.floor(this.currentAudioPrc)
      return;
    }
    if (event.target.getAttribute('name') === 'progress-bar') {
      let max = event.target.offsetParent.clientWidth
      let value = event.layerX
      let prc = Math.floor(value / max * 100)
      this.audio.currentTime = this.audio.duration / 100 * prc
      this.currentAudioPrc = this.audio.currentTime / this.totalAudio * 100
      this.currentAudio = Math.floor(this.currentAudioPrc)
      return;
    }

  }

  public getDialType(item: CdrInterface): string {
    if (!item.recordingfile) {
      return getDialTypeEnum.err
    }
    if (item.recordingfile.split('-')[0] === 'internal') {
      return getDialTypeEnum.internal
    }
    if (item.recordingfile.split('-')[0] === 'in') {
      return getDialTypeEnum.in
    }
    if (item.recordingfile.split('-')[0] === 'external') {
      return getDialTypeEnum.external
    }
    if (item.recordingfile.split('-')[0] === 'out') {
      return getDialTypeEnum.internal
    }
    return getDialTypeEnum.err
  }

  public getDialTypeText(item: CdrInterface): string {
    let data = this.getDialType(item)
    if (data == getDialTypeEnum.internal) {
      return 'звонил'
    }
    if (data == getDialTypeEnum.external) {
      return 'ответил'
    }
    if (data == getDialTypeEnum.in) {
      return 'ответил'
    }
    return 'разговаривал'
  }

  public async downloadAudio(item: CdrInterface): Promise<void> {
    let file = await this.http.post('/api/manager/cdr/get-record', {path: item.path}, {
      responseType: 'arraybuffer',
    }).toPromise()
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return null;
      })
    if (!file) {
      this.toast.clear()
      this.toast.error('Не удалось скачать файл', 'Ошибка')
      return;
    }
    const blob = new Blob([file], {
      type: 'audio/wav'
    });
    if (item.uniqueid) {
      saveAs(blob, item.uniqueid.split('.')[0] + '.wav');
    } else {
      saveAs(blob,  'audio.wav');
    }
  }
}
