<div *ngIf="ifFiredChanged" class="alert alert-important alert-success alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
      Изменено состояние сотрудника
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>

<div *ngIf="isErr" class="alert alert-important alert-warning alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
      {{errText}}
    </div>
  </div>
  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
</div>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">Состояние работы менеджера</h3>
  </div>
  <div class="card-body">
    <div class="mb-3">
      <label class="form-check mb-2">
        <input class="form-check-input" type="checkbox" [(ngModel)]="isFired">
        <span class="form-check-label">Уволен</span>
        <span class="form-check-description">Сотрудник не будет получать доступ к защищенным ресурсам</span>
      </label>
    </div>
  </div>
  <div class="card-footer text-end bgw">
    <div class="d-flex">
      <button [class.disabled]="isFiredChangeLoading" (click)="changedIsFired()" type="submit" class="btn btn-primary ms-auto">
        <span *ngIf="isFiredChangeLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
        Сохранить
      </button>
    </div>
  </div>
</div>
