<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="container-xl">
          <div class="page-header d-print-none">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="page-title">
                  Создание категории
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <div class="row row-cards">

              <div class="col-md-12">

                <div *ngIf="isCreated" class="alert alert-important alert-success alert-dismissible" role="alert">
                  <div class="d-flex">
                    <div>
                    </div>
                    <div>
                      Категория создана
                    </div>
                  </div>
                  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
                </div>

                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Новая категория транспорта</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Наименование</label>
                      <div class="col">
                        <input [(ngModel)]="description" type="text" [class.is-invalid]="isDescriptionErr" class="form-control"
                               placeholder="Введите наименование">
                        <small *ngIf="!isDescriptionErr" class="form-hint">Не может быть несколько одинаковых наименований</small>
                        <div class="invalid-feedback">{{descriptionErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Сокр. наименование</label>
                      <div class="col">
                        <input [(ngModel)]="shortName" [class.is-invalid]="isShortNameErr" type="text" class="form-control"
                               placeholder="Введите сокращенное наименование">
                        <small *ngIf="!isShortNameErr" lass="form-hint">Не может быть несколько одинаковых сокращенных наименований</small>
                        <div class="invalid-feedback">{{shortNameErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Синоним</label>
                      <div class="col">
                        <input [(ngModel)]="synonym" [class.is-invalid]="isSynonymErr" type="text" class="form-control"
                               placeholder="Введите сокращенное наименование">
                        <small *ngIf="!isSynonymErr" lass="form-hint">Не может быть несколько одинаковых синонимов</small>
                        <div class="invalid-feedback">{{synonymErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Вместительность</label>
                      <div class="col">
                        <div class="input-group mb-1">
                              <span class="input-group-text">кг.</span>
                          <input [(ngModel)]="capacity" [class.is-invalid]="isCapacityErr" mask="9*"  type="text" class="form-control" placeholder="Вместительность" autocomplete="off">
                        </div>
                        <small *ngIf="!isCapacityErr" lass="form-hint">Указывается в киллограммах</small>
                        <div class="invalid-feedback">{{capacityErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Объем</label>
                      <div class="col">
                        <div class="input-group mb-1">
                          <span class="input-group-text">с3.</span>
                          <input [(ngModel)]="volume" [class.is-invalid]="isVolumeErr" mask="9*"  type="text" class="form-control" placeholder="Объем" autocomplete="off">
                        </div>
                        <small *ngIf="!isCapacityErr" lass="form-hint">Указывается в сантиметрах кубических</small>
                        <div class="invalid-feedback">{{capacityErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Длина</label>
                      <div class="col">
                        <div class="input-group mb-1">
                          <span class="input-group-text">см.</span>
                          <input [(ngModel)]="length" [class.is-invalid]="isLengthErr" mask="9*"  type="text" class="form-control" placeholder="Длина" autocomplete="off">
                        </div>
                        <small *ngIf="!isLengthErr" lass="form-hint">Указывается в сантиметрах</small>
                        <div class="invalid-feedback">{{lengthErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Ширина</label>
                      <div class="col">
                        <div class="input-group mb-1">
                          <span class="input-group-text">см.</span>
                          <input [(ngModel)]="width" [class.is-invalid]="isWidthErr" mask="9*"  type="text" class="form-control" placeholder="Ширина" autocomplete="off">
                        </div>
                        <small *ngIf="!isWidthErr" lass="form-hint">Указывается в сантиметрах</small>
                        <div class="invalid-feedback">{{widthErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Высота</label>
                      <div class="col">
                        <div class="input-group mb-1">
                          <span class="input-group-text">см.</span>
                          <input [(ngModel)]="height" [class.is-invalid]="isHeightErr" mask="9*"  type="text" class="form-control" placeholder="Высота" autocomplete="off">
                        </div>
                        <small *ngIf="!isHeightErr" lass="form-hint">Указывается в сантиметрах</small>
                        <div class="invalid-feedback">{{heightErr}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-end bgc-t">
                    <div class="d-flex">
                      <a href="javascript:void(0)" (click)="clear()" class="btn btn-link">Очистить</a>
                      <button [class.disabled]="isCreating || isCreated" [class.btn-success]="isCreated" [class.btn-danger]="isErr" type="submit" (click)="create()" class="btn btn-primary ms-auto">
                        <span *ngIf="isCreating" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        {{isCreated ? 'Создан' : 'Создать'}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
