import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ManagerService} from "../../../services/manager.service";
import {ToastrService} from "ngx-toastr";
import {Rdp} from "../../../../../../app-nestjs/src/modules/rdp/rdp.interface";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";

@Component({
  selector: 'app-profile-rdp',
  templateUrl: './rdp.profile.component.html',
  styleUrls: ['./rdp.profile.component.scss']
})
export class RdpProfileComponent implements OnInit {

  public rdpSessions: Rdp.RdpInterface[] = []
  public isLoading: boolean = true;
  public isRemoving: boolean = false

  constructor(
    private readonly http: HttpClient,
    private readonly managerService: ManagerService,
    private toastr: ToastrService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadRdpSessions()
  }

  async loadRdpSessions(): Promise<any> {
    await this.http.post<SuccessResponseInterface>('/api/manager/rdp/sessions/list', {}).toPromise()
      .then((data) => {
        this.rdpSessions = data.data as Rdp.RdpInterface[]
        this.isLoading = false
      })
      .catch((err) => {
        this.isLoading = false
      })
  }

  async removeRdpSession(sessionId: string): Promise<any> {
    this.isRemoving = true
    await this.http.post<SuccessResponseInterface>('/api/manager/rdp/sessions/remove', {sessionId: sessionId}).toPromise()
      .then(async (answer) => {
        // Добавить тостр с уведомлением пользователя об удалении
        await this.loadRdpSessions()
      })
      .catch(async(err: ErrorResponseInterface) => {
        await this.loadRdpSessions()
      })
    this.isRemoving = false
  }

}

