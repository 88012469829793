<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="container-xl">
          <div class="page-header d-print-none">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="page-title">
                  Печать конверта
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <div *ngIf="isComplete" class="alert alert-important alert-success alert-dismissible" role="alert">
              <div class="d-flex">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><rect x="7" y="13" width="10" height="8" rx="2" /></svg>
                </div>
                <div>
                  Конверт отправлен на печать
                </div>
              </div>
              <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
            </div>
            <div *ngIf="isFailed" class="alert alert-important alert-warning alert-dismissible" role="alert">
              <div class="d-flex">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><rect x="7" y="13" width="10" height="8" rx="2" /></svg>
                </div>
                <div>
                  Во время отправки конверта на печать произошла ошибка
                </div>
              </div>
              <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
            </div>
            <div class="row row-cards">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label required">Организация (отправитель)</label>
                        <div class="col">
                          <div class="input-group">
                            <input [disabled]="isPrinting" [class.is-invalid]="isFromOrgErr" [(ngModel)]="json.fromOrg" type="text" class="form-control" placeholder="Введите организацию" type="text" class="form-control">
                            <button data-bs-toggle="dropdown" type="button" class="btn dropdown-toggle dropdown-toggle-split"></button>
                            <div class="dropdown-menu dropdown-menu-end" style="">
                              <a class="dropdown-item" (click)="installOrg('tkakro')" href="javascript:void(0)">
                                ТК АКРО
                              </a>
                              <a class="dropdown-item" (click)="installOrg('iprodikov')" href="javascript:void(0)">
                                ИП Родиков А.А.
                              </a>
                            </div>
                            <div class="invalid-feedback">{{fromOrgErr}}</div>
                          </div>
                          <small *ngIf="!isFromOrgErr" class="form-hint">Укажите организацию от которой производится отправка</small>
                        </div>
                      </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Адрес отправления</label>
                      <div class="col">
                        <input [disabled]="isPrinting" [class.is-invalid]="isFromAddressErr" [(ngModel)]="json.fromAddress" type="text" class="form-control" placeholder="Введите адрес отправления">
                        <small *ngIf="!isFromAddressErr" class="form-hint">Адрес отправления</small>
                        <div class="invalid-feedback">{{fromAddressErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Индекс отправления</label>
                      <div class="col">
                        <input [disabled]="isPrinting" [class.is-invalid]="isFromIndexErr" mask="000000" [(ngModel)]="json.fromIndex" type="text" class="form-control" placeholder="Введите индекс отправления">
                        <small *ngIf="!isFromIndexErr" class="form-hint">Индекс отправления содержит 6 символов</small>
                        <div class="invalid-feedback">{{fromIndexErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Размер конверта</label>
                      <div class="col">
                        <select [disabled]="isPrinting" [class.is-invalid]="isEnvSizeErr" class="form-select" [(ngModel)]="json.envSize">
                          <option [value]="envSize.c4">Большой (С4)</option>
                          <option [value]="envSize.c5">Средний (С5)</option>
                          <option [value]="envSize.c4_without">Большой без изображения (С4)</option>
                          <option [value]="envSize.c5_without">Средний без изображения (С5)</option>
                        </select>
                        <small *ngIf="!isEnvSizeErr" class="form-hint">Укажите размер конверта в зависимости от кол-ва отправляемых документов</small>
                        <div class="invalid-feedback">{{envSizeErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Тип отправления</label>
                      <div class="col">
                        <select [disabled]="isPrinting" [class.is-invalid]="isEnvTypeErr" class="form-select" [(ngModel)]="json.envType">
                          <option [value]="envType.regular">Почтовая</option>
                        </select>
                        <small *ngIf="!isEnvTypeErr" class="form-hint">Пока существует только почтовая отправка, без трекинга</small>
                        <div class="invalid-feedback">{{envTypeErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Организация (получатель)</label>
                      <div class="col">
                        <input [disabled]="isPrinting" [(ngModel)]="json.destOrg" [class.is-invalid]="isDestOrgErr" type="text" class="form-control" placeholder="Введите организацию получателя">
                        <small *ngIf="!isDestOrgErr" class="form-hint">Укажите организацию к которой произовдится отправка</small>
                        <div class="invalid-feedback">{{destOrgErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Адрес получателя</label>
                      <div class="col">
                        <input [disabled]="isPrinting" [(ngModel)]="json.destAddress" [class.is-invalid]="isDestAddressErr" type="text" class="form-control" placeholder="Введите адрес получателя">
                        <small *ngIf="!isDestAddressErr" class="form-hint">Адрес получателя</small>
                        <div class="invalid-feedback">{{destAddressErr}}</div>
                      </div>
                    </div>
                    <div class="form-group mb-3 row">
                      <label class="form-label col-3 col-form-label required">Индекс получателя</label>
                      <div class="col">
                        <input [disabled]="isPrinting" mask="000000" [(ngModel)]="json.destIndex" [class.is-invalid]="isDestIndexErr" type="text" class="form-control" placeholder="Введите индекс получателя">
                        <small *ngIf="!isDestIndexErr" class="form-hint">Индекс получателя содержит 6 символов</small>
                        <div class="invalid-feedback">{{destIndexErr}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="d-flex">
                      <a href="javascript:void(0)" (click)="loadDefault()" class="btn btn-link">Очистить</a>
                      <a [class.disabled]="isPrinting" href="javascript:void(0)" (click)="print()" class="btn btn-primary ms-auto">
                        <span *ngIf="isPrinting" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        {{ isPrinting ? 'Печатается...' : 'Печать'}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
