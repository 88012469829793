import { Component, OnInit } from '@angular/core';
import {TitleService} from "../../services/title.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss']
})
export class InternalServerErrorComponent implements OnInit {

  constructor(
    private _location: Location,
  ) { }

  ngOnInit(): void {
  }

  back() {
    this._location.back();
  }
}
