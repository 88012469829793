<div class="antialiased border-top-wide border-primary d-flex flex-column max-height">
  <div class="flex-fill d-flex flex-column justify-content-center py-4 max-height">
    <div class="container-tight py-4">
      <div class="empty">
        <div class="empty-header">500</div>
        <p class="empty-title">Упс… Ошибка сервера</p>
        <p class="empty-subtitle text-muted">
          К сожалению не удалось выполнить ваше действие из-за ошибки сервера
        </p>
        <div class="empty-action">
          <a (click)="back()" href="javascript:void(0)" class="btn btn-primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                 stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="5" y1="12" x2="19" y2="12"/>
              <line x1="5" y1="12" x2="11" y2="18"/>
              <line x1="5" y1="12" x2="11" y2="6"/>
            </svg>
            Назад
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
