<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div *ngIf="constService.get(constService.itemTypes.polygonsAdmonition)"
                   class="alert alert-important alert-info alert-dismissible" role="alert">
                <div class="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24"
                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                         stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <line x1="12" y1="8" x2="12.01" y2="8"></line>
                      <polyline points="11 12 12 12 12 16 13 16"></polyline>
                    </svg>
                  </div>
                  <div>
                    Полигоны вносятся вручную. На основании полигонов проверяется принадлежность точки к зоне доставки. Что в свою очередь определяет пропуск
                  </div>
                </div>
                <a (click)="constService.add(constService.itemTypes.polygonsAdmonition)" class="btn-close btn-close-white"
                   data-bs-dismiss="alert" aria-label="close"></a>
              </div>
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="row align-items-center mw-100">
                    <div class="col">
                      <div class="mb-1">
                        <ol class="breadcrumb breadcrumb-alternate" aria-label="breadcrumbs">
                          <li *ngFor="let key of titleService.getNavi()" class="breadcrumb-item"
                              [class.active]="key.current">
                            <a [routerLink]="['', key.url]">{{key.title}}</a>
                          </li>
                        </ol>
                      </div>
                      <h2 class="page-title mb-3">
                        Полигоны
                      </h2>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>


                <div class="row">
                  <div *ngFor="let key of polygons" class="col-lg-6 mb-3">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-title">{{key.description}}</div>
                        <div class="ratio ratio-16x9">
                          <div>
                            <div leaflet [leafletOptions]="key.map" [leafletFitBounds]="key.fitBounds" class="w-100 h-100">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <span (click)="openModalWithComponent(key.manager)" *ngIf="!key.manager?.avatar" class="avatar me-2 rounded" style="">{{getTextAvatar(key.manager?.name, key.manager?.surname)}}
                            <span *ngIf="key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span>
                                            <span *ngIf="!key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                                          </span>
                          <span (click)="openModalWithComponent(key.manager)" *ngIf="key.manager?.avatar" class="avatar me-2 rounded" [style.background-image]="'url(' + key.manager?.avatar + ')'">
                                            <span *ngIf="key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span>
                                            <span *ngIf="!key.manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-gray"></span>
                                          </span>
                          <div>
                            <a href="javascript:void(0)" style="color: black" (click)="openModalWithComponent(key.manager)">{{key?.manager?.surname ?? ''}} {{key?.manager?.name ?? '' }}</a>
                            <div class="text-muted">Отредактировал ({{key?.manager?.position}})</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
