import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TitleService} from "./services/title.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'driver-frontend';

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly titleService: TitleService
  ) {
  }

  public getNavi(): any[] {
    console.log(this.activatedRoute.snapshot.data)
    return []
  }
}
