import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Rdp} from "../../../../../../../app-nestjs/src/modules/rdp/rdp.interface";
import {ManagerService} from "../../../../services/manager.service";
import {ToastrService} from "ngx-toastr";
import {SuccessResponseInterface} from "../../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../../interfaces/error-response.interface";
import {switchMap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-edit-manager-rdp',
  templateUrl: './rdp.edit.manager.component.html',
  styleUrls: ['./rdp.edit.manager.component.scss']
})
export class RdpEditManagerComponent implements OnInit {

  public id: string | undefined = undefined;

  public rdpSessions: Rdp.RdpInterface[] = []
  public isLoading: boolean = true;
  public isRemoving: boolean = false

  constructor(
    private readonly http: HttpClient,
    private readonly managerService: ManagerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe((data) => {
        this.id = data
      });
    await this.loadRdpSessions()
  }

  async loadRdpSessions(): Promise<any> {
    await this.http.post<SuccessResponseInterface>('/api/manager/rdp/admin/list', {
      managerId: this.id
    }).toPromise()
      .then((data) => {
        this.rdpSessions = data.data as Rdp.RdpInterface[]
        this.isLoading = false
      })
      .catch((err) => {
        this.isLoading = false
      })
  }

  async removeRdpSession(sessionId: string): Promise<any> {
    this.isRemoving = true
    await this.http.post<SuccessResponseInterface>('/api/manager/rdp/admin/remove', {sessionId: sessionId, managerId: this.id}).toPromise()
      .then(async (answer) => {
        // Добавить тостр с уведомлением пользователя об удалении
        await this.loadRdpSessions()
      })
      .catch(async(err: ErrorResponseInterface) => {
        await this.loadRdpSessions()
      })
    this.isRemoving = false
  }

}

