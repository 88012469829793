import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {Carcase} from "../../../../../../app-nestjs/src/modules/carcase/carcase.interface";
import {Category} from "../../../../../../app-nestjs/src/modules/category/category.interface";

@Component({
  selector: 'app-category-list',
  templateUrl: './list.category.component.html',
  styleUrls: ['./list.category.component.scss']
})
export class ListCategoryComponent implements OnInit {

  public isLoading: boolean = true
  public categories: Category.CategoryInterface[] = []

  constructor(
    private readonly http: HttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    await this.load()
  }

  private async load(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/categories/list', {}).toPromise()
      .then((data) => {
        this.categories = data.data as Category.CategoryInterface[]
        this.categories.sort(function (a, b) {
          if (a.volume > b.volume) {
            return 1;
          }
          if (a.volume < b.volume) {
            return -1;
          }
          return 0;
        });
        this.isLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }

  public getName(name: string) {
    return  name[0]
  }

  public getRandom(): string {
    const array = [
      'bg-blue-lt',
      'bg-azure-lt',
      'bg-indigo-lt',
      'bg-purple-lt',
      'bg-pink-lt',
      'bg-red-lt',
      'bg-orange-lt',
      'bg-yellow-lt',
      'bg-lime-lt',
      'bg-green-lt',
      'bg-teal-lt',
      'bg-cyan-lt'
    ]
    return  array[Math.floor(Math.random()*array.length)];
  }

}
