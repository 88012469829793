import {Component, OnInit} from '@angular/core';
import { ManagerService} from "../../../services/manager.service";
import {HttpClient} from "@angular/common/http";
import {GenderEnum} from "../../../../../../app-nestjs/src/constants/gender.enum";
import {MainProfileComponentInterface} from "./main.profile.component.interface";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";

@Component({
  selector: 'app-main-profile',
  templateUrl: './main.profile.component.html',
  styleUrls: ['./main.profile.component.scss']
})
export class MainProfileComponent implements OnInit {

  public readonly genderEnum = GenderEnum

  public isProfileUpdated: boolean = false
  public isProfileUpdatedError: boolean = false

  public isUpdatingLoading: boolean = false

  public email: string = ""
  public name: string = ""
  public surname: string = ""
  public patronymic: string = ""
  public gender: string = ""
  public mobile: string = ""
  public addNum: string = ""
  public position: string = ""
  public role: string = ""

  public isEmailError: boolean = false
  public isNameError: boolean = false
  public isSurnameError: boolean = false
  public isGenderError: boolean = false
  public isPatronymicError: boolean = false
  public isMobileError: boolean = false
  public isAddNumError: boolean = false

  public emailErrorText: string = ""
  public nameErrorText: string = ""
  public surnameErrorText: string = ""
  public genderErrorText: string = ""
  public patronymicErrorText: string = ""
  public mobileErrorText: string = ""
  public addNumberErrorText: string = ""


  constructor(
    private readonly managerService: ManagerService,
    private readonly http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.loadDefaultProfile()
  }

  clearErrors(): void {
    this.isEmailError = false
    this.isNameError = false
    this.isSurnameError = false
    this.isGenderError = false
    this.isPatronymicError = false
    this.isMobileError = false
    this.isAddNumError = false

    this.emailErrorText = ""
    this.nameErrorText = ""
    this.surnameErrorText = ""
    this.genderErrorText = ""
    this.patronymicErrorText = ""
    this.mobileErrorText = ""
    this.addNumberErrorText = ""

    this.isProfileUpdated = false
    this.isProfileUpdatedError = false
  }

  async updateProfile(): Promise<void> {
    this.isUpdatingLoading = true
    this.isProfileUpdated = false
    this.isProfileUpdatedError = false
    this.clearErrors()
    if (this.mobile.length == 11) {
      this.mobile = '+' + this.mobile
    }
    const json: MainProfileComponentInterface = {
      addNum: this.addNum,
      gender: this.gender,
      mobile: this.mobile,
      name: this.name,
      patronymic: this.patronymic,
      surname: this.surname
    }
    await this.http.patch<SuccessResponseInterface>('/api/manager/profile/update', json).toPromise()
      .then(async (data) => {
        this.isUpdatingLoading = false
        this.isProfileUpdated = true
        await this.managerService.getProfile()
        this.loadDefaultProfile()
      })
      .catch((err: ErrorResponseInterface) => {
        this.isProfileUpdatedError = true
        this.isUpdatingLoading = false
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key == 'Добавочный номер должен иметь минимум 3 цифры') {
                this.isAddNumError = true
                this.addNumberErrorText = key
            }
            if (key == 'Передан неверный мобильный номер телефона') {
              this.isMobileError = true
              this.mobileErrorText = key
            }
            if (key == 'Отчество указано неверно') {
              this.isPatronymicError = true
              this.patronymicErrorText = key
            }
            if (key == 'Фамилия указана неверно') {
              this.isSurnameError = true
              this.surnameErrorText = key
            }
            if (key == 'Имя указано неверно') {
              this.isNameError = true
              this.nameErrorText = key
            }
            if (key == 'Пол указан неверно') {
              this.isGenderError = true
              this.genderErrorText = key
            }
          }
          return;
        }
      })
  }

  loadDefaultProfile(): void {
    this.email = this.managerService.manager?.email as string
    this.name = this.managerService.manager?.name as string
    this.surname = this.managerService.manager?.surname as string
    this.patronymic = this.managerService.manager?.patronymic as string
    this.gender = this.managerService.manager?.gender as string
    this.mobile = this.managerService.manager?.mobile as string
    this.addNum = this.managerService.manager?.addNum as string
    this.position = this.managerService.manager?.position as string
    this.role = this.managerService.manager?.role as string
  }


}
