import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileComponentSelected: boolean = false // Готово
  avatarComponentSelected: boolean = false // Готово
  officeComponentSelected: boolean = false // Готово
  sessionsComponentSelected: boolean = false // Готово
  passwordComponentSelected: boolean = false // Готово
  rdpComponentSelected: boolean = false // Готово

  constructor(

  ) { }

  ngOnInit(): void {
    this.profileComponentSelected = true;
  }

  // Установка дефолтного компонента
  setComponent(value: string): void {
    this.profileComponentSelected = false;
    this.avatarComponentSelected = false;
    this.officeComponentSelected = false;
    this.sessionsComponentSelected = false;
    this.passwordComponentSelected = false;
    this.rdpComponentSelected = false
    if (value == 'main') {
      this.profileComponentSelected = true;
    }
    if (value == 'avatar') {
      this.avatarComponentSelected = true;
    }
    if (value == 'office') {
      this.officeComponentSelected = true;
    }
    if (value == 'sessions') {
      this.sessionsComponentSelected = true;
    }
    if (value == 'password') {
      this.passwordComponentSelected = true;
    }
    if (value == 'rdp') {
      this.rdpComponentSelected = true;
    }
  }



}
