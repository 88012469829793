<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="container-xl">
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <h2 class="page-title">
                Калькулятор паллет
              </h2>
            </div>
          </div>
        </div>
        <div class="row row-cards">
          <div class="col-lg-8">
            <div class="card">
              <div class="card-body" style="padding-bottom: 0rem;">
                <label class="form-label mb-3">Вариант</label>
                <div class="form-selectgroup-boxes row mb-3">
                  <div class="col-lg-6">
                    <label (click)="checkedRadio = checkedPalletCalculatorEnum.simple" class="form-selectgroup-item mb-3">
                      <input name="pallet" type="radio" class="form-selectgroup-input" [checked]="checkedRadio == checkedPalletCalculatorEnum.simple">
                      <span [style.height]="basicPalletRadio > diffPalletRadio ? basicPalletRadio.offsetHeight : diffPalletRadio.offsetHeight" #basicPalletRadio class="form-selectgroup-label d-flex align-items-center p-3">
                        <span class="me-3">
                          <span class="form-selectgroup-check"></span>
                          </span>
                          <span class="form-selectgroup-label-content">
                            <span class="form-selectgroup-title strong mb-1">Одинаковые паллеты</span>
                            <span class="d-block text-muted">Загрузка паллетов одного типа (например только европаллеты или финские паллеты)</span>
                          </span>
                        </span>
                    </label>
                  </div>
                  <div class="col-lg-6">
                    <label (click)="checkedRadio = checkedPalletCalculatorEnum.diff" class="form-selectgroup-item mb-3">
                      <input name="pallet" type="radio" class="form-selectgroup-input" [checked]="checkedRadio == checkedPalletCalculatorEnum.diff">
                      <span [style.height]="basicPalletRadio > diffPalletRadio ? basicPalletRadio.offsetHeight : diffPalletRadio.offsetHeight" #diffPalletRadio class="form-selectgroup-label d-flex align-items-center p-3">
                        <span class="me-3">
                          <span class="form-selectgroup-check"></span>
                        </span>
                        <span class="form-selectgroup-label-content">
                          <span class="form-selectgroup-title strong mb-1">Разные типы паллет</span>
                          <span class="d-block text-muted">Загрузка разными паллетами (например одновременно европаллеты и финские паллеты)</span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <div class="col-lg-12">
                    <div *ngIf="checkedRadio == checkedPalletCalculatorEnum.simple" class="">
                      <h3 class="card-title">Паллеты одинакового типа</h3>
                      <p>Необходимо выбрать тип палет, указать максимальную высоту паллетов, вес всех паллет, а так же указать количество паллет. Калькулятор не учитывает нагрузку на ось. Возможно создать нестандартный паллет, который будет доступен для просмотра только вам. При необходимости кастомный паллет можно добавить в глобальный список паллет и закрепить за определенным клиентом. Так же возможно добавление категории транспорта, которая будет доступна только вам. Вместо категории транспорта можно добавить само транспортное средство</p>
                      <div class="col-12">
                        <div class="mb-3">
                          <div class="form-label required">Тип паллета</div>
                          <select class="form-select" [(ngModel)]="simplePalletId">
                            <option *ngFor="let key of palletTypes" [value]="key.id">{{key.name}} ({{toUpperCase(key.value)}})</option>
                          </select>
                        </div>
                      </div>
                      <div class="row g-2">
                        <div class="col-4">
                          <div class="mb-2">
                            <label class="form-label">Высота паллетов (см)</label>
                            <input mask="000" [class.is-invalid]="isSimpleErr.isHeightErr" [(ngModel)]="simpleJson.height" type="text" class="form-control" placeholder="В сантиметрах">
                            <div class="invalid-feedback">{{simpleErrText.heightErrText}}</div>
                        </div>
                        </div>
                        <div class="col-4">
                          <div class="mb-2">
                            <label class="form-label">Вес всех паллет (кг)</label>
                          <input mask="00000" [class.is-invalid]="isSimpleErr.isWeightErr" [(ngModel)]="simpleJson.weight" type="text" class="form-control" placeholder="В килограммах">
                            <div class="invalid-feedback">{{simpleErrText.weightErrText}}</div>
                        </div>
                        </div>
                        <div class="col-4">
                          <div class="mb-2">
                            <label class="form-label">Кол-во паллет (шт)</label>
                          <input mask="00" [class.is-invalid]="isSimpleErr.isCountErr" [(ngModel)]="simpleJson.count" type="text" class="form-control" placeholder="В штуках">
                            <div class="invalid-feedback">{{simpleErrText.countErrText}}</div>
                        </div>
                        </div>
                        <div class="col-12">
                          <div class="">
                            <div class="row g-2">
                              <a href="javascript:void(0)" (click)="addSimplePallets()" class="btn btn-primary btn-icon" aria-label="Button">
                                Рассчитать
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="checkedRadio == checkedPalletCalculatorEnum.diff"  class="">
                      <h3 class="card-title">Паллеты разного типа</h3>
                      <p>Необходимо указать тип паллета, максимальную высоту одного паллета, вес одного паллета и количество паллет. Возможно добавлять по одному паллету, если все паллеты разные по габаритам, весу и типу. Каждый паллет можно выделить отдельным цветом, для более удобного визуального отображения. Калькулятор не учитывает нагрузку на ось. Возможно создать нестандартный паллет, который будет доступен для просмотра только вам. При необходимости кастомный паллет можно добавить в глобальный список паллет и закрепить за определенным клиентом. Так же возможно добавление категории транспорта, которая будет доступна только вам. Вместо категории транспорта можно добавить само транспортное средство</p>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <div class="form-label required">Тип паллета</div>
                            <select class="form-select">
                              <option *ngFor="let key of palletTypes" [value]="key.id">{{key.name}} ({{toUpperCase(key.value)}})</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-1">
                          <div class="mb-3">
                            <label class="form-label">Цвет</label>
                            <input type="color" style="max-height: 2.25rem;" class="form-control form-control-color" value="#206bc4" title="Choose your color">
                          </div>
                        </div>
                      </div>
                      <div class="row g-2">
                        <div class="col-4">
                          <div class="mb-2">
                            <label class="form-label">Высота одного паллета (см)</label>
                            <input mask="000" type="text" class="form-control" placeholder="В сантиметрах">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="mb-2">
                            <label class="form-label">Вес одного паллета (кг)</label>
                            <input mask="0000" type="text" class="form-control" placeholder="В килограммах">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="mb-2">
                            <label class="form-label">Кол-во паллет (шт)</label>
                            <input mask="00" type="text" class="form-control" placeholder="В штуках">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="">
                            <div class="row g-2">
                              <a href="javascript:void(0)" class="btn btn-primary btn-icon" aria-label="Button">
                                Рассчитать
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="simpleResponse.length > 0 && checkedRadio == checkedPalletCalculatorEnum.simple" class="accordion mb-3" id="accordion">
                  <div *ngFor="let key of simpleResponse" class="accordion-item">
                    <h2 class="accordion-header" [id]="'heading' + key.category.id">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + key.category.id" aria-expanded="false">
                        {{key.category.description}} <span *ngIf="!key.isPackedSuccess" class="badge bg-red ms-2">Не вместится</span> <span *ngIf="key.isPackedSuccess" class="badge bg-green ms-2">Вместится</span>
                      </button>
                    </h2>
                    <div [id]="'collapse-' + key.category.id" class="accordion-collapse collapse" data-bs-parent="#accordion" style="">
                      <div class="accordion-body pt-0">
                        <div *ngIf="!key.isPackedSuccess">
                          {{key.category.description}} -  габариты: {{key.category.length / 100}}x{{key.category.width / 100}}x{{key.category.height /100}} м. грузоподъемность {{key.category.capacity / 1000}} т. объем {{key.category.volume / 1000000}} м3.
                          <b>{{key.heightSuccess ? '' : 'Высота паллет превышает высоту кузова '}}</b><b>{{key.countSuccess ? '' : 'Паллеты не поместились в кузов. '}}</b><b>{{key.capacitySuccess ? '' : 'Общий вес паллет превышает грузоподъемность транспорта'}}</b>
                        </div>
                        <div *ngIf="key.isPackedSuccess">
                          {{key.category.description}} -  габариты: {{key.category.length / 100}}x{{key.category.width / 100}}x{{key.category.height /100}} м. грузоподъемность {{key.category.capacity / 1000}} т. объем {{key.category.volume / 1000000}} м3.
                        </div>
                        <div style="text-align: center;">
                          <canvas *ngIf="key.isPackedSuccess"  [id]="'simple-' + key.category.id"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card mb-3">
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label mb-3">Типы паллет</label>
                  <label *ngFor="let key of palletTypes" class="form-check mb-2">
                    <input class="form-check-input" [disabled]="true" type="checkbox" [checked]="true">
                    <span class="form-check-label">
                                {{key.name}} ({{toUpperCase(key.value)}})
                      <a *ngIf="key.isCustom" (click)="removePalletFromPalletList(key)" href="javascript:void(0)"
                         class="text-muted text-red">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon text-red small" width="24" height="24"
                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                 stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                      fill="none"></path><line x1="18"
                                                                                                               y1="6"
                                                                                                               x2="6"
                                                                                                               y2="18"></line><line
                              x1="6" y1="6" x2="18" y2="18"></line></svg></a>
                              </span>
                    <span class="form-check-description">
                      {{key.length / 10}}x{{key.width / 10}}x{{key.height / 10}} см. {{key.capacity}} кг.
                              </span>
                  </label>
                </div>
              </div>
              <div class="card-footer">
                <a href="javascript:void(0)" (click)="openModal(addPalletToPalletCalculator)"
                   class="btn btn-primary w-100">
                  Добавить
                </a>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label mb-3">Список категорий ТС</label>
                  <label *ngFor="let key of categories" class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="key.checked">
                    <span class="form-check-label">
                                {{key.description}}
                      <a *ngIf="key.canBeRemoved" (click)="removeCategory(key)" href="javascript:void(0)"
                         class="text-muted text-red">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon text-red small" width="24" height="24"
                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                 stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                      fill="none"></path><line x1="18"
                                                                                                               y1="6"
                                                                                                               x2="6"
                                                                                                               y2="18"></line><line
                              x1="6" y1="6" x2="18" y2="18"></line></svg></a>
                              </span>
                    <span class="form-check-description">
                                Вместительность: {{key.capacity}} кг. Объем: {{key.volume / 1000000}}
                      м3. Габариты: {{key.length / 100}}х{{key.width / 100}}х{{key.height / 100}} м
                              </span>
                  </label>
                </div>
              </div>
              <div class="card-footer">
                <a href="javascript:void(0)" (click)="openModal(addCategoryToPalletCalculator)"
                   class="btn btn-primary w-100">
                  Добавить
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>

<ng-template #addCategoryToPalletCalculator>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Добавление категории</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalRef?.hide()"
              aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label class="form-label required">Описание <span tooltip="Наименование категории или название автомобиля"
                                                          class="form-help">?</span>
        </label>
        <input [class.is-invalid]="isNewCategoryErr.isDescriptionErr" type="text" class="form-control"
               [(ngModel)]="newCategory.description" placeholder="Введите наименование категории">
        <div class="invalid-feedback">{{newCategoryErr.descriptionErrText}}</div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label required">Длина (см) <span tooltip="1 метр = 100 см"
                                                                class="form-help">?</span></label>
            <input mask="0000" [class.is-invalid]="isNewCategoryErr.isLengthErr" [(ngModel)]="newCategory.length"
                   type="text" class="form-control" placeholder="Длина (см)">
            <div class="invalid-feedback">{{newCategoryErr.lengthErrText}}</div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label required">Ширина (см) <span tooltip="1 метр = 100 см"
                                                                 class="form-help">?</span></label>
            <input type="text" mask="000" class="form-control" [class.is-invalid]="isNewCategoryErr.isWidthErr"
                   [(ngModel)]="newCategory.width" placeholder="Ширина (см)">
            <div class="invalid-feedback">{{newCategoryErr.widthErrText}}</div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <label class="form-label required">Высота (см) <span tooltip="1 метр = 100 см"
                                                                 class="form-help">?</span></label>
            <input mask="000" [class.is-invalid]="isNewCategoryErr.isHeightErr" [(ngModel)]="newCategory.height"
                   type="text" class="form-control" placeholder="Высота (см)">
            <div class="invalid-feedback">{{newCategoryErr.heightErrText}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label required">Грузоподъемность (кг) <span tooltip="1 тонна = 1000 кг"
                                                                           class="form-help">?</span></label>
            <input mask="00000" [class.is-invalid]="isNewCategoryErr.isCapacityErr" [(ngModel)]="newCategory.capacity"
                   type="text" class="form-control" placeholder="Вес (кг)">
            <div class="invalid-feedback">{{newCategoryErr.capacityErrText}}</div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label required">Объем (м3)</label>
            <input mask="0*.0" thousandSeparator="." [dropSpecialCharacters]="false"
                   [class.is-invalid]="isNewCategoryErr.isVolumeErr" [(ngModel)]="newCategory.volume" type="text"
                   class="form-control" placeholder="Метры кубические (м3)">
            <div class="invalid-feedback">{{newCategoryErr.volumeErrText}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <div class="d-flex">
        <a href="javascript:void(0)" (click)="clearModalAddCategory() && modalRef?.hide()"
           class="btn btn-link">Отмена</a>
        <button type="button" (click)="modalAddCategory() && modalRef?.hide()" class="btn btn-primary ms-auto">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #addPalletToPalletCalculator>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Добавление нестандартного типа паллета</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalRef?.hide()"
              aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label class="form-label required">Описание <span tooltip="Наименование паллета"
                                                          class="form-help">?</span>
        </label>
        <input type="text" class="form-control" [class.is-invalid]="isNewPalletErr.isNameErr"
               [(ngModel)]="newPallet.name" placeholder="Введите наименование паллета">
        <div class="invalid-feedback">{{newPalletErr.nameErrText}}</div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label required">Длина (см) <span tooltip="1 метр = 100 см. 1 см = 10 мм. 1 метр = 1000мм"
                                                                class="form-help">?</span></label>
            <input mask="0000" [(ngModel)]="newPallet.length" [class.is-invalid]="isNewPalletErr.isLengthErr"
                   type="text" class="form-control" placeholder="Длина (см)">
            <div class="invalid-feedback">{{newPalletErr.lengthErrText}}</div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label required">Ширина (см) <span tooltip="1 метр = 100 см. 1 см = 10 мм. 1 метр = 1000мм"
                                                                 class="form-help">?</span></label>
            <input type="text" mask="0000" class="form-control" [class.is-invalid]="isNewPalletErr.isWidthErr"
                   [(ngModel)]="newPallet.width" placeholder="Ширина (см)">
            <div class="invalid-feedback">{{newPalletErr.widthErrText}}</div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label required">Высота (см) <span tooltip="1 метр = 100 см. 1 см = 10 мм. 1 метр = 1000мм"
                                                                 class="form-help">?</span></label>
            <input mask="000" [(ngModel)]="newPallet.height" [class.is-invalid]="isNewPalletErr.isHeightErr"
                   type="text" class="form-control" placeholder="Высота (см)">
            <div class="invalid-feedback">{{newPalletErr.heightErrText}}</div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-3">
            <label class="form-label required">Грузоподъемность (кг) <span tooltip="1 тонна = 1000 кг"
                                                                           class="form-help">?</span></label>
            <input mask="0000" [(ngModel)]="newPallet.capacity" [class.is-invalid]="isNewPalletErr.isCapacityErr"
                   type="text" class="form-control" placeholder="Вес (кг)">
            <div class="invalid-feedback">{{newPalletErr.capacityErrText}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <div class="d-flex">
        <a href="javascript:void(0)"
           class="btn btn-link">Отмена</a>
        <button type="button" (click)="addDiffPallets() && modalRef?.hide()" class="btn btn-primary ms-auto">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</ng-template>
