<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div *ngIf="constService.get(constService.itemTypes.palletsAdmonition)" class="alert alert-important alert-info alert-dismissible" role="alert">
                <div class="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><line x1="12" y1="8" x2="12.01" y2="8"></line><polyline points="11 12 12 12 12 16 13 16"></polyline></svg>
                  </div>
                  <div>
                    Информация предоставленная на странице является константой и не может быть изменена самостоятельно
                  </div>
                </div>
                <a (click)="constService.add(constService.itemTypes.palletsAdmonition)" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>
              </div>
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="row align-items-center mw-100">
                    <div class="col">
                      <div class="mb-1">
                        <ol class="breadcrumb breadcrumb-alternate" aria-label="breadcrumbs">
                          <li *ngFor="let key of titleService.getNavi()" class="breadcrumb-item" [class.active]="key.current">
                            <a [routerLink]="['', key.url]">{{key.title}}</a>
                          </li>
                        </ol>
                      </div>
                      <h2 class="page-title">
                        Список паллет
                      </h2>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>

                <div class="col-md-12">
                  <div class="card mb-3">
                    <div class="card-header">
                      <h3 class="card-title">Список</h3>
                    </div>
                    <div class="table-responsive" style="margin-bottom: 0rem;">
                      <table class="table table-vcenter table-mobile-md card-table">
                        <thead>
                        <tr>
                          <th>Наименование</th>
                          <th>Длина</th>
                          <th>Ширина</th>
                          <th>Высота</th>
                          <th>Грузоподъемность</th>
                          <th>Стандартный</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of pallets">
                          <td>
                            <div class="d-flex py-1 align-items-center">
                              <span [classList]="true ? item.val + ' avatar me-2'  : 'avatar me-2'" style="">{{getName(item?.value)}}</span>
                              <div class="flex-fill">
                                <div class="font-weight-medium"><small>{{toUpperCase(item?.value)}}</small></div>
                                <div class="text-muted"><a href="javascript:void(0)" class="text-reset"><small>{{item?.description}}</small></a></div>
                              </div>
                            </div>
                          </td>
                          <td class="text-muted">
                            <small>{{item?.length / 10}} см</small>
                          </td>
                          <td class="text-muted">
                            <small>{{item?.width / 10}} см</small>
                          </td>
                          <td class="text-muted">
                            <small>{{item?.height / 10}} см</small>
                          </td>
                          <td class="text-muted">
                            <small>{{item?.capacity}} кг</small>
                          </td>
                          <td class="text-muted">
                            <label class="form-check">
                              <input [checked]="!item?.isCustom" class="form-check-input" type="checkbox" disabled="">
                            </label>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
