import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-offices',
  templateUrl: './offices.profile.component.html',
  styleUrls: ['./offices.profile.component.scss']
})
export class OfficesProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
