import {Injectable} from '@angular/core';
import {SuccessResponseInterface} from "../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../interfaces/error-response.interface";
import {Admonition} from "../../../../app-nestjs/src/modules/admonition/admonition.interface";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AdmonitionService {

  private list: Admonition.ManagerAdmonition.ManagerAdmonitionInterface[] = []
  public isFirstLoaded: boolean = false
  public itemTypes = Admonition.ManagerAdmonition.ItemTypes

  constructor(
    private http: HttpClient
  ) {
    if (!this.isFirstLoaded) {
      this.load()
    }
  }

  async load(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/admonitions/list', {}).toPromise()
      .then((data) => {
        this.list = [] as Admonition.ManagerAdmonition.ManagerAdmonitionInterface[]
        for (let key of data.data as Admonition.ManagerAdmonition.ManagerAdmonitionInterface[]) {
          this.list.push(key)
        }
        this.recreateStorage()
        this.isFirstLoaded = true
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.recreateStorage()
        return;
      })
  }

  recreateStorage(): void {
    for (let key of this.list) {
      localStorage.setItem(key.item, 'true')
    }
  }

  public get(item: Admonition.ManagerAdmonition.item): boolean {
    let exist = localStorage.getItem(item)
    if (!exist) {
      return true
    }
    return false
  }

  public async add(item: string): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/admonitions/add', {item: item}).toPromise()
      .then((data) => {
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        return;
      })
    await this.load()
  }

}
