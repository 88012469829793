import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../services/title.service";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Manager} from "../../../../../app-nestjs/src/modules/manager/manager.interface";
import {GetManagerComponent} from "../manager/get/get.manager.component";
import {CreateRdpComponent} from "./create/create.rdp.component";
import {take} from "rxjs/operators";
import {AdmonitionService} from "../../services/admonition.service";

@Component({
  selector: 'app-rdp',
  templateUrl: './rdp.component.html',
  styleUrls: ['./rdp.component.scss']
})
export class RdpComponent implements OnInit {

  bsModalRef: BsModalRef | undefined

  public isLoading: boolean = true
  public rdps: any[] = []
  public managers: Manager.ManagerInterface[] = []

  constructor(
    private readonly http: HttpClient,
    public readonly constService: AdmonitionService,
    public readonly titleService: TitleService,
    public readonly toastr: ToastrService,
    private modalService: BsModalService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadManagers()
    await this.load()
  }

  private async loadManagers(): Promise<void> {
    const body = {
      offset: 0,
      limit: 1000
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/managers/list', body).subscribe(
      (data) => {
        this.managers = data.data
      },
      (err) => {
      }
    )
  }

  private async load(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/rdp/list', {}).toPromise()
      .then((data) => {
        for (const rdp of data.data) {
          let key = rdp
          key.val = this.getRandom()
          this.rdps.push(key)
        }
        this.isLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }

  public getName(name: string) {
    return name[0]
  }

  public getRandom(): string {
    const array = [
      'bg-blue-lt',
      'bg-azure-lt',
      'bg-indigo-lt',
      'bg-purple-lt',
      'bg-pink-lt',
      'bg-red-lt',
      'bg-orange-lt',
      'bg-yellow-lt',
      'bg-lime-lt',
      'bg-green-lt',
      'bg-teal-lt',
      'bg-cyan-lt'
    ]
    return array[Math.floor(Math.random()*array.length)];
  }

  public async remove(id: string): Promise<any> {
    await this.http.post<SuccessResponseInterface>('/api/manager/rdp/remove', {
      id: id
    }).toPromise()
      .then((data) => {
        this.isLoading = false
        this.toastr.clear()
        this.toastr.info('RDP настройка удалена', 'Успешно')
        return true
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
        this.toastr.clear()
        this.toastr.info('Не удалось удалить RDP настройку', 'Ошибка')
        return false
      })
    this.rdps = []
    await this.load()
  }

  public async openModalWithComponent() {
    const initialState = {
      managers: this.managers
    };
    this.bsModalRef = this.modalService.show(CreateRdpComponent, {initialState})
    this.bsModalRef.onHide
      .pipe(take(1))
      .subscribe(async () => {
        this.rdps = []
        await this.load()
      });
  }

}
