import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BsModalService} from "ngx-bootstrap/modal";
import {Manager} from "../../../../app-nestjs/src/modules/manager/manager.interface";
import {SuccessResponseInterface} from "../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../interfaces/error-response.interface";
import {Router} from "@angular/router";
import {Buffer} from "buffer"

import {EventSourcePolyfill} from 'ng-event-source';


@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  private token: string | null = null
  public manager: Manager.ManagerInterface | null = null

  public avatar: string | null = null
  public avatarBase64: any


  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private router: Router
  ) {

  }

  // Установить токен
  public setToken(token: string): boolean {
    try {
      if (!token) {
        return false;
      }
      this.token = token
      localStorage.setItem('token', token)
      return true
    } catch (err) {
      return false;
    }
  }

  // Получить токен
  public getToken(): string {
    if (!this.token) {
      return ''
    }
    return this.token
  }

  // Авторизован ли пользователь
  public checkToken(): boolean {
    try {
      if (this.token) {
        return true;
      }
      const data = localStorage.getItem('token')
      if (!data) {
        return false
      }
      this.token = data
      return true
    } catch (err) {
      return false
    }
  }

  // Получить профиль на сервере
  public async getProfile(): Promise<boolean> {
    try {
      return await this.http.post<SuccessResponseInterface>('/api/manager/profile/me', {}).toPromise()
        .then(async(data) => {
          this.manager = data.data as Manager.ManagerInterface
          if (this.manager?.avatar) {
            await this.loadAvatar()
          }
          return true;
        })
        .catch((err: ErrorResponseInterface) => {
          this.manager = null;
          if (err.error.statusCode == 401) {
            this.router.navigateByUrl('/login')
          }
          if (err.error.statusCode == 500) {
            this.router.navigateByUrl('/error')
          }
          return false;
        })
    } catch (err) {
      console.log(err)
      return false
    }
  }

  // Проверить получен ли профиль
  public async isAuthManager(): Promise<boolean> {
    try {
      // Проверить есть ли токен
      const checkToken = this.checkToken()
      if (!checkToken) {
        return false;
      }
      // Проверить есть ли менеджер
      if (!this.manager) {
        const getProfile = await this.getProfile()
        if (!getProfile) {
          return false;
        }
        return true;
      }
      return true
    } catch (err) {
      return false
    }
  }

  // Выйти из сессии
  async logout(): Promise<void> {
    this.manager = null
    this.token = null
    localStorage.removeItem('token')
  }

  // Получить строку для аватара
  getStringAvatar(): any {
    if (this.manager?.surname && this.manager?.name) {
      return this.manager?.name[0] + this.manager?.surname[0]
    }
    if (this.manager?.name) {
      return this.manager?.name[0]
    }
  }

  // Скачать аватар
  async loadAvatar(): Promise<void> {
    await this.http.get(this.manager?.avatar as string, { responseType: 'arraybuffer' }).toPromise()
      .then((data) => {
          // @ts-ignore
        this.avatarBase64 = Buffer.from(data, 'binary').toString('base64')
      })
      .catch((err) => {})
  }

}
