<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div class="row row-cards">

                <!-- Логистика -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Логистика
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Калькулятор паллет -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/pallet-calculator" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" /><line x1="12" y1="12" x2="20" y2="7.5" /><line x1="12" y1="12" x2="12" y2="21" /><line x1="12" y1="12" x2="4" y2="7.5" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Калькулятор паллет</div>
                          <div class="text-muted">Отображает машину, в которую все поместится</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 11v8a1 1 0 0 0 1 1h8m-9 -14v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1" /><path d="M4 12h7a1 1 0 0 1 1 1v7" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Калькулятор вместительности</div>
                          <div class="text-muted">По габаритам груза или объему груза</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Карты -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Карты
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Полигоны-->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/polygon" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="9.5" cy="9.5" r="5.5" /><circle cx="14.5" cy="14.5" r="5.5" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Полигоны</div>
                          <div class="text-muted">Отображение информации о точке в полигоне</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Дистанция-->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/distance" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="6" cy="18" r="2" /><circle cx="18" cy="6" r="2" /><line x1="7.5" y1="16.5" x2="16.5" y2="7.5" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Дистанция</div>
                          <div class="text-muted">Расстояние от полигона до точки</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Ближайшая точка-->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/nearest" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Ближайшая точка</div>
                          <div class="text-muted">Отображает ближайшую точку или точки от полигона</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Доступность-->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/isochrone" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M22 17c0 -5.523 -4.477 -10 -10 -10s-10 4.477 -10 10" /><path d="M18 17a6 6 0 1 0 -12 0" /><path d="M14 17a2 2 0 1 0 -4 0" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Доступность</div>
                          <div class="text-muted">Выводит доступную территорию на карте</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Координаты-->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/coords" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="11" r="3" /><path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Координаты</div>
                          <div class="text-muted">Отображение координат на карте</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Бухгалтерия -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Бухгалетрия
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Чеки СЗ -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/self-employed-check/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="9" y1="7" x2="10" y2="7" /><line x1="9" y1="13" x2="15" y2="13" /><line x1="13" y1="17" x2="15" y2="17" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Чеки СЗ</div>
                          <div class="text-muted">Внесение чеков в платежные поручения 1С</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Печать конвертов -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/env-print" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <!-- Download SVG icon from http://tabler-icons.io/i/printer -->
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><rect x="7" y="13" width="10" height="8" rx="2" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Печать конверта</div>
                          <div class="text-muted">Печать конверта для почтовой отправки</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Телефония -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Телефония
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Все звонки -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/cdr" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-blue-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /><path d="M15 7a2 2 0 0 1 2 2" /><path d="M15 3a6 6 0 0 1 6 6" /></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Записи звонков</div>
                          <div class="text-muted">Отображает список звонков сотрудникам с записями</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>

