import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ManagerService} from "../../services/manager.service";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private managerService: ManagerService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.managerService.logout()
    this.router.navigateByUrl('/login')
  }

}
