import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Manager} from "../../../../../../../app-nestjs/src/modules/manager/manager.interface";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {Buffer} from "buffer";
import {ManagerService} from "../../../../services/manager.service";
import {ManagerComponentService} from "../../manager.component.service";

@Component({
  selector: 'app-avatar-edit-manager-profile',
  templateUrl: './avatar.edit.manager.component.html',
  styleUrls: ['./avatar.edit.manager.component.scss']
})
export class AvatarEditManagerComponent implements OnInit {

  public isLoading: boolean = true
  public manager: Manager.ManagerInterface | undefined = undefined
  public avatarBase64: any

  public id: string | undefined = undefined;

  fileToUpload: File | null = null;
  fileUploaded: boolean = false;
  fileAvatarRemoved: boolean = false;
  fileRemoveLoaded: boolean = false;
  fileUploadErr: boolean = false;
  fileUploadErrText: string = '';

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private managerService: ManagerService,
    private managerComponentService: ManagerComponentService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe((data) => {
        this.id = data
      });
    await this.loadProfile()
  }

  async handleFileInput(event: Event): Promise<void> {
    this.fileAvatarRemoved = false;
    this.fileUploadErr = false;
    this.fileUploadErrText= '';
    this.fileUploaded = false;
    let target = event.target as HTMLInputElement;
    this.fileToUpload = (target.files as FileList)[0];
    const formData: FormData = new FormData();
    formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
    formData.append('id', this.id as string);
    return await this.http.patch('/api/manager/managers/upload-avatar', formData).toPromise()
      .then(async (data) => {
        this.fileUploaded = true
        await this.loadProfile()
        if (this.managerService?.manager?.id == this.id) {
          await this.managerService.getProfile()
        }
      }).catch(async (err: any) => {
        if (err.error.message == 'Accept only images(png, jpeg, jpg)') {
          this.fileUploaded = false
          this.fileUploadErr = true
          this.fileUploadErrText = 'Поддерживаются только форматы изображений: png, jpeg'
        }
      })
  }

  // Удалить аватар
  async removeAvatar(): Promise<void> {
    this.fileUploadErr = false;
    this.fileUploaded = false;
    this.fileAvatarRemoved = false;
    this.fileRemoveLoaded = true;
    const json = {
      id: this.id as string
    }
    await this.http.post('/api/manager/managers/remove-avatar', json).toPromise()
      .then(async (response) => {
        this.fileRemoveLoaded = false;
        this.fileAvatarRemoved = true;
        await this.loadProfile()
        if (this.managerService?.manager?.id == this.id) {
          await this.managerService.getProfile()
        }
      })
      .catch(async (err) => {
        this.fileRemoveLoaded = false;
        this.fileAvatarRemoved = false;
      })
  }

  async loadProfile(): Promise<void> {
    this.isLoading = true
    await this.managerComponentService.loadProfile(this.id as string)
    this.manager = this.managerComponentService.manager
    if (this.manager?.avatar) {
      await this.loadAvatar()
    }
    this.isLoading = false
  }

  // Скачать аватар
  async loadAvatar(): Promise<void> {
    await this.http.get(this.manager?.avatar as string, { responseType: 'arraybuffer' }).toPromise()
      .then((data) => {
        // @ts-ignore
        this.avatarBase64 = Buffer.from(data, 'binary').toString('base64')
      })
      .catch((err) => {})
  }

  // Получить строку для аватара
  getStringAvatar(): any {
    if (this.manager?.surname && this.manager?.name) {
      return this.manager?.name[0] + this.manager?.surname[0]
    }
    if (this.manager?.name) {
      return this.manager?.name[0]
    }
  }

}
