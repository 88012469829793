<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div *ngIf="constService.get(constService.itemTypes.banksAdmonition)"
                   class="alert alert-important alert-info alert-dismissible" role="alert">
                <div class="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24"
                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                         stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <line x1="12" y1="8" x2="12.01" y2="8"></line>
                      <polyline points="11 12 12 12 12 16 13 16"></polyline>
                    </svg>
                  </div>
                  <div>
                    Классификатор банков обновляется каждые 12 часов. Если
                    новый банк не отображается - значит он еще не попал в систему. Ручное добавление невозможно
                  </div>
                </div>
                <a (click)="constService.add(constService.itemTypes.banksAdmonition)" class="btn-close btn-close-white"
                   data-bs-dismiss="alert" aria-label="close"></a>
              </div>
              <div class="row row-cards">

                <div *ngIf="!isLoading" class="container-xl">
                  <div class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Классификатор банков
                        </h2>
                        <div class="text-muted mt-1">Всего {{banks.length}} банк(ов)</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading" class="container-xl">
                  <div class="loader loader-center"></div>
                </div>


                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Список банков</h3>
                    </div>
                    <div style="margin-bottom: 0.5rem;" class="table-responsive">
                      <table class="table table-vcenter card-table">
                        <thead>
                        <tr>
                          <th>БИК</th>
                          <th>Наименование</th>
                          <th>Город</th>
                          <th>Кор счет</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let key of banks">
                          <td class="small"><a target="_blank" rel="noopener noreferrer" [href]="'https://bik10.ru/' + key.bik">{{key.bik}}</a></td>
                          <td class="text-muted small">
                            {{key.namemini}}
                          </td>
                          <td class="text-muted small">{{key.city}}
                          </td>
                          <td class="text-muted small">{{key.ks}}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="search-results"
                      infiniteScroll
                      [infiniteScrollDistance]="2"
                      [infiniteScrollUpDistance]="1.5"
                      [infiniteScrollThrottle]="50"
                      (scrolled)="load()"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
