import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {TitleService} from "../../services/title.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent implements OnInit {

  constructor(
    private _location: Location,
    private router: Router,
    ) { }

  ngOnInit(): void {
  }

  back() {
    this.router.navigateByUrl('/')
  }

}
