import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'app-edit-carcase',
  templateUrl: './edit.carcase.component.html',
  styleUrls: ['./edit.carcase.component.scss']
})
export class EditCarcaseComponent implements OnInit {

  public id: string | undefined = undefined;
  public isLoading: boolean = true

  public isChanging: boolean = false
  public isChange: boolean = false
  public isErr: boolean = false
  public nameErr: String = ''

  public description: string = ''
  public awning: boolean = false
  public conics: boolean = false
  public flatFloor: boolean = true
  public katyusha: boolean = false
  public manipulator: boolean = false
  public pyramid: boolean = false
  public sanitization: boolean = false
  public tailLift: boolean = false
  public temperatureRegime: boolean = false

  constructor(
    private readonly http: HttpClient,
    private readonly toastrService: ToastrService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe((data) => {
        this.id = data
      });
    await this.load()
  }

  public async clear(): Promise<void> {
    this.isChanging = false
    this.isChange = false
    this.clearErrors()
    await this.load()
  }

  public clearErrors(): void {
    this.isErr = false
    this.nameErr = ''
  }

  async load(): Promise<any> {
    this.isLoading = true
    const json = {
      id: this.id as string
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/carcases/get', json).toPromise()
      .then((data) => {
        this.description = data.data.description
        this.awning = data.data.awning
        this.conics = data.data.conics
        this.flatFloor = data.data.flatFloor
        this.katyusha = data.data.katyusha
        this.manipulator = data.data.manipulator
        this.pyramid = data.data.pyramid
        this.sanitization = data.data.sanitization
        this.tailLift = data.data.tailLift
        this.temperatureRegime = data.data.temperatureRegime
        this.isLoading = false
      })
      .catch((err) => {
        this.isLoading = false
        this.toastrService.error('Во время получения данных произошла ошибка', 'Ошибка')
      })
  }

  async change(): Promise<void> {
    this.clearErrors()
    this.isErr = false
    this.isChanging = true
    const json = {
      "id": this.id as string,
      "awning": this.awning,
      "conics": this.conics,
      "description": this.description,
      "flatFloor": this.flatFloor,
      "katyusha": this.katyusha,
      "manipulator": this.manipulator,
      "pyramid": this.pyramid,
      "sanitization": this.sanitization,
      "tailLift": this.tailLift,
      "temperatureRegime": this.temperatureRegime
    }
    await this.http.patch<SuccessResponseInterface>('/api/manager/carcases/update', json).toPromise()
      .then((data) => {
        this.isChanging = false
        this.isErr = false
        this.isChange = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.isChanging = false
        this.isErr = true
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Id кузова не указано') {
              this.nameErr = key
              this.isErr = true
            }
            if (key === 'Наименование кузова не указано') {
              this.nameErr = key
              this.isErr = true
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          if (err.error.message === 'Уже существует кузов с таким именем') {
            this.isErr = true;
            this.nameErr = err.error.message
          }
          return;
        }
      })
  }

}
