import {AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TitleService} from "../../services/title.service";
import {HttpClient} from "@angular/common/http";
import {Category} from "../../../../../app-nestjs/src/modules/category/category.interface";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import * as uuid from 'uuid';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {
  PalletInterface,
  PalletsTypeEnum, SimpleCalculatorJson,
  SimplePalletCalculator
} from "../../../../../app-nestjs/src/modules/pallet/pallet.interface";
import {upperCase} from "upper-case";
import {SVG} from "leaflet";

export interface CategoryInterfaceWeb extends Category.CategoryInterface {
  canBeRemoved: boolean,
  checked: boolean
}

export enum CheckedPalletCalculatorEnum {
  'simple' = 'simple',
  'diff' = 'diff'
}

export interface CapacityErrInterface {
  capacityErrText: string
  descriptionErrText: string,
  heightErrText: string,
  lengthErrText: string,
  volumeErrText: string,
  widthErrText: string
}

export interface IsNewCategoryErrInterface {
  isCapacityErr: boolean,
  isDescriptionErr: boolean,
  isHeightErr: boolean,
  isLengthErr: boolean,
  isVolumeErr: boolean,
  isWidthErr: boolean
}


@Component({
  selector: 'app-pallet-calculator',
  templateUrl: './pallet-calculator.component.html',
  styleUrls: ['./pallet-calculator.component.scss']
})
export class PalletCalculatorComponent implements OnInit, AfterViewInit {

  modalRef?: BsModalRef;

  public isBaseLoading: boolean = true
  public isBaseErr: boolean = false
  public baseErrText: string = ''
  public categories: CategoryInterfaceWeb[] = []
  public palletTypes: PalletInterface[] = []

  public newCategory: CategoryInterfaceWeb = {
    canBeRemoved: true,
    capacity: 0,
    checked: true,
    description: '',
    height: 0,
    id: uuid.v4(),
    length: 0,
    managerId: "",
    shortName: "",
    synonym: "",
    volume: 0,
    width: 0
  }

  public newCategoryErr: CapacityErrInterface = {
    capacityErrText: '',
    descriptionErrText: '',
    heightErrText: '',
    lengthErrText: '',
    volumeErrText: '',
    widthErrText: ''
  }

  public isNewCategoryErr: IsNewCategoryErrInterface = {
    isCapacityErr: false,
    isDescriptionErr: false,
    isHeightErr: false,
    isLengthErr: false,
    isVolumeErr: false,
    isWidthErr: false
  }

  public newPallet: PalletInterface = {
    capacity: 0,
    clientId: null,
    description: "Кастомный",
    height: 0,
    id: uuid.v4(),
    isCustom: true,
    length: 0,
    name: "",
    value: PalletsTypeEnum.custom,
    width: 0
  }

  public isNewPalletErr = {
    isCapacityErr: false,
    isHeightErr: false,
    isLengthErr: false,
    isNameErr: false,
    isWidthErr: false
  }

  public newPalletErr = {
    capacityErrText: '',
    heightErrText: '',
    lengthErrText: '',
    nameErrText: '',
    widthErrText: ''
  }

  public checkedPalletCalculatorEnum = CheckedPalletCalculatorEnum
  public checkedRadio: CheckedPalletCalculatorEnum = CheckedPalletCalculatorEnum.diff

  @ViewChild('basicPalletRadio') basicPalletRadio: ElementRef | undefined;
  @ViewChild('diffPalletRadio') diffPalletRadio: ElementRef | undefined;


  // Simple
  public simpleJson: SimplePalletCalculator = {
    categories: [],
    count: 0,
    height: 0,
    weight: 0,
    pallet: null
  }
  public simplePalletId: string = ''

  public isSimpleErr = {
    isCountErr: false,
    isHeightErr: false,
    isWeightErr: false,
  }

  public simpleErrText = {
    countErrText: "",
    heightErrText: "",
    weightErrText: "",
  }

  public simpleResponse: SimpleCalculatorJson[] = []

  constructor(
    private readonly http: HttpClient,
    private modalService: BsModalService,
    private elRef: ElementRef
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadBaseCategories()
    await this.loadPalletTypes()
    this.simpleJson = {
      count: 1,
      height: 100,
      weight: 1000,
      pallet: this.palletTypes[0],
      categories: [],
    }
    this.simplePalletId = this.palletTypes[0].id as string
  }

  ngAfterViewInit(): void {
  }

  async loadBaseCategories(): Promise<void> {
    if (this.isBaseErr) {
      return;
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/categories/list', {}).toPromise()
      .then((data) => {
        for (let key of data.data) {
          key.canBeRemoved = false
          key.checked = true
          this.categories.push(key as CategoryInterfaceWeb)
        }
        let tempCategory = this.localstorageGetCategoryArr()
        for (let key of tempCategory) {
          this.categories.push(key as CategoryInterfaceWeb)
        }
        this.sortCategory()
        // this.addNewCategory('dsfdsf', 1,1,1,111111,1)
      })
      .catch((err: ErrorResponseInterface) => {
        console.log(err)
      })
  }

  public addNewCategory(description: Category.description, capacity: Category.capacity, height: Category.height, length: Category.length, volume: Category.volume, width: Category.width): void {
    const category: CategoryInterfaceWeb = {
      canBeRemoved: true,
      capacity: capacity,
      checked: true,
      description: description,
      height: height,
      id: uuid.v4(),
      length: length,
      managerId: "",
      shortName: "",
      synonym: "",
      volume: volume,
      width: width
    }
    this.categories.unshift(category)
    let tempArr: any = localStorage.getItem('pallet-calculator')
    if (tempArr === null) {
      let newArray = []
      newArray.push(category)
      localStorage.setItem('pallet-calculator', JSON.stringify(newArray))
      this.sortCategory()
      return;
    }
    if (JSON.parse(tempArr)) {
      let newArray = JSON.parse(tempArr)
      newArray.push(category)
      localStorage.setItem('pallet-calculator', JSON.stringify(newArray))
      this.sortCategory()
      return;
    }
  }

  public sortCategory(): void {
    this.categories.sort(function (a, b) {
      if (a.volume > b.volume) {
        return 1;
      }
      if (a.volume < b.volume) {
        return -1;
      }
      return 0;
    });
  }

  public localstorageGetCategoryArr(): CategoryInterfaceWeb[] {
    let arr: CategoryInterfaceWeb[] = []
    let data = localStorage.getItem('pallet-calculator')
    if (data === null) {
      return arr;
    }
    if (JSON.parse(data)) {
      for (let key of JSON.parse(data)) {
        arr.push(key)
      }
    }
    return arr
  }

  public removeCategory(key: CategoryInterfaceWeb): void {
    try {
      let index = this.categories.indexOf(key)
      this.categories.splice(index, 1);
      let localstorage = this.localstorageGetCategoryArr()
      let newArray = []
      for (let value of localstorage) {
        if (value.id !== key.id) {
          newArray.push(value)
        }
      }
      localStorage.setItem('pallet-calculator', JSON.stringify(newArray))
    } catch (err) {

    }
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, {class: 'modal-lg modal-dialog'}));
  }

  public modalAddCategory(): boolean {
    let err: boolean = false

    if (this.newCategory.description.length < 3) {
      this.isNewCategoryErr.isDescriptionErr = true
      this.newCategoryErr.descriptionErrText = 'Наименование категории не может содержать менее 3 символов'
      err = true
    }

    if (this.newCategory.capacity < 1) {
      this.isNewCategoryErr.isCapacityErr = true
      this.newCategoryErr.capacityErrText = 'Не менее 1 кг'
      err = true
    }

    if (this.newCategory.height < 1) {
      this.isNewCategoryErr.isHeightErr = true
      this.newCategoryErr.heightErrText = 'Не менее 1 см'
      err = true
    }

    if (this.newCategory.length < 1) {
      this.isNewCategoryErr.isLengthErr = true
      this.newCategoryErr.lengthErrText = 'Не менее 1 см'
      err = true
    }

    if (this.newCategory.volume < 1) {
      this.isNewCategoryErr.isVolumeErr = true
      this.newCategoryErr.volumeErrText = 'Не менее 1 м3'
      err = true
    }

    if (this.newCategory.width < 1) {
      this.isNewCategoryErr.isWidthErr = true
      this.newCategoryErr.widthErrText = 'Не менее 1 см'
      err = true
    }

    if (err) {
      return false
    }

    this.newCategory.volume = this.newCategory.volume * 1000000

    this.addNewCategory(this.newCategory.description, this.newCategory.capacity, this.newCategory.height, this.newCategory.length, this.newCategory.volume, this.newCategory.width)
    this.clearModalAddCategory()
    return true
  }

  public clearModalAddCategory(): boolean {
    this.newCategory = {
      canBeRemoved: true,
      capacity: 0,
      checked: true,
      description: '',
      height: 0,
      id: uuid.v4(),
      length: 0,
      managerId: "",
      shortName: "",
      synonym: "",
      volume: 0,
      width: 0
    }
    this.newCategoryErr = {
      capacityErrText: '',
      descriptionErrText: '',
      heightErrText: '',
      lengthErrText: '',
      volumeErrText: '',
      widthErrText: ''
    }
    this.isNewCategoryErr = {
      isCapacityErr: false,
      isDescriptionErr: false,
      isHeightErr: false,
      isLengthErr: false,
      isVolumeErr: false,
      isWidthErr: false
    }
    return true
  }

  public toUpperCase(value: string): string {
    return upperCase(value)
  }

  public async loadPalletTypes(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/pallet/list', {}).toPromise()
      .then((data) => {
        for (const pallet of data.data as PalletInterface[]) {
          this.palletTypes.push(pallet)
        }
        let arr = this.listLocalstoragePallets()
        for (let key of arr) {
          this.palletTypes.push(key)
        }
        this.isBaseLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isBaseLoading = false
      })
  }

  public listLocalstoragePallets(): PalletInterface[] {
    let palletsPalletCalculator = localStorage.getItem('palletsPalletCalculator')
    let array: PalletInterface[] = []
    if (palletsPalletCalculator != null) {
      if (Array.isArray(JSON.parse(palletsPalletCalculator))) {
        for (let key of JSON.parse(palletsPalletCalculator)) {
          array.push(key)
        }
        return array
      }
    }
    return array
  }

  public addDiffPallets(): boolean {

    // Проверка на правильность заполнения
    this.clearErrorsNewPallet()
    let err = false
    if (this.newPallet.name.length < 2) {
      this.newPalletErr.nameErrText = 'Имя указано неверно'
      this.isNewPalletErr.isNameErr = true
      err = true
    }

    if (this.newPallet.height < 1) {
      this.newPalletErr.heightErrText = 'Не менее 1 см'
      this.isNewPalletErr.isHeightErr = true
      err = true
    }

    if (this.newPallet.length < 1) {
      this.newPalletErr.lengthErrText = 'Не менее 1 см'
      this.isNewPalletErr.isLengthErr = true
      err = true
    }

    if (this.newPallet.width < 1) {
      this.newPalletErr.widthErrText = 'Не менее 1 см'
      this.isNewPalletErr.isWidthErr = true
      err = true
    }

    if (this.newPallet.capacity < 1) {
      this.newPalletErr.capacityErrText = 'Не менее 1 кг'
      this.isNewPalletErr.isCapacityErr = true
      err = true
    }

    if (err) {
      return false;
    }

    this.newPallet.length = this.newPallet.length * 10
    this.newPallet.height = this.newPallet.height * 10
    this.newPallet.width = this.newPallet.width * 10

    let listLocalstoragePallets = this.listLocalstoragePallets()
    if (listLocalstoragePallets.length == 0) {
      let arr = []
      arr.push(this.newPallet)
      localStorage.setItem('palletsPalletCalculator', JSON.stringify(arr))
      this.palletTypes.push(this.newPallet)
    } else {
      listLocalstoragePallets.push(this.newPallet)
      localStorage.setItem('palletsPalletCalculator', JSON.stringify(listLocalstoragePallets))
      this.palletTypes.push(this.newPallet)
    }

    this.loadDefaultNewPallet()
    return true;
  }

  public removePalletFromPalletList(item: PalletInterface) {
    this.palletTypes.splice(this.palletTypes.indexOf(item), 1);
    let arr: PalletInterface[] = []
    for (let key of this.palletTypes) {
      if (key.isCustom === true) {
        arr.push(key)
      }
    }
    localStorage.setItem('palletsPalletCalculator', JSON.stringify(arr))
  }

  public loadDefaultNewPallet(): void {
    this.newPallet = {
      capacity: 0,
      clientId: null,
      description: "Кастомный",
      height: 0,
      id: uuid.v4(),
      isCustom: true,
      length: 0,
      name: "",
      value: PalletsTypeEnum.custom,
      width: 0
    }
    this.clearErrorsNewPallet()
  }

  public clearErrorsNewPallet(): void {
    this.newPalletErr = {
      capacityErrText: '',
      heightErrText: '',
      lengthErrText: '',
      nameErrText: '',
      widthErrText: ''
    }

    this.isNewPalletErr = {
      isCapacityErr: false,
      isHeightErr: false,
      isLengthErr: false,
      isNameErr: false,
      isWidthErr: false
    }
  }

  public findPalletInArray(id: string) {
    for (let key of this.palletTypes) {
      if (key.id === id) {
        return key
      }
    }
    return null
  }

  public getCheckedCategories(): Category.CategoryInterface[] {
    let arr: Category.CategoryInterface[] = []
    for (let key of this.categories) {
      if (key.checked) {
        arr.push(key)
      }
    }
    return arr
  }

  public clearSimpleErrors(): void {
    this.isSimpleErr = {
      isCountErr: false,
      isHeightErr: false,
      isWeightErr: false,
    }

    this.simpleErrText = {
      countErrText: "",
      heightErrText: "",
      weightErrText: "",
    }
  }

  public simpleSortCategory(): void {
    this.simpleResponse.sort(function (a, b) {
      if (a.category.volume > b.category.volume ) {
        return 1;
      }
      if (a.category.volume  < b.category.volume ) {
        return -1;
      }
      return 0;
    });
  }

  public async addSimplePallets(): Promise<void> {

    // Проверка на ошибки
    this.clearSimpleErrors()
    let err = false
    if (this.simpleJson.count <= 0) {
      this.isSimpleErr.isCountErr = true
      this.simpleErrText.countErrText = 'Не менее 1 шт'
      err = true
    }
    if (this.simpleJson.height < 1) {
      this.isSimpleErr.isHeightErr = true
      this.simpleErrText.heightErrText = 'Не менее 1 см'
      err = true
    }

    if (this.simpleJson.weight < 1) {
      this.isSimpleErr.isWeightErr = true
      this.simpleErrText.weightErrText = 'Не менее 1 кг'
      err = true
    }

    if (err) {
      return
    }

    this.simpleJson.pallet = this.findPalletInArray(this.simplePalletId)
    this.simpleJson.categories = this.getCheckedCategories()

    await this.http.post<SuccessResponseInterface>('/api/manager/pallet/simple-pallet-calculator', { ... this.simpleJson}).toPromise()
      .then((data) => {

        this.simpleResponse = data.data as SimpleCalculatorJson[]
        this.simpleSortCategory()
      })
      .catch((err: ErrorResponseInterface) => {
        console.log(err)
      })

    for (let key of this.simpleResponse) {
      if (key.isPackedSuccess) {
        setTimeout(() => {
          let canvasId = (document.getElementById('simple-' + key.category.id)) as HTMLCanvasElement
          canvasId.height = key.category.length
          let ctx = canvasId.getContext('2d') as CanvasRenderingContext2D

          ctx.fillStyle = "#dedfe4";
          ctx.fillRect(0, 0, (key.category.width ), (key.category.length ));

          for (let pallet of key.packetPallets) {
            ctx.fillStyle = "#206bc4";
            ctx.rect(pallet.CoordX, pallet.CoordZ, pallet.packX -1 , pallet.packZ - 1);
            ctx.fill();
          }
          for (let [i, pallet] of key.packetPallets.entries()) {
            let index = i + 1
            ctx.font = "0.8rem Arial";
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.fillStyle = "#f4f6fa";
            ctx.fillText('' + index + '', pallet.CoordX + (pallet.packX / 2), pallet.CoordZ + (pallet.packZ /2));
          }
        }, 100)
      }
    }

    return
  }


  public async getSimplePalletFromServer(): Promise<void> {
    return
  }

}
