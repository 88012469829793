import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../services/title.service";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {AdmonitionService} from "../../services/admonition.service";

@Component({
  selector: 'app-currencies',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {

  public isLoading: boolean = true
  public statuses: any[] = []

  constructor(
    private readonly http: HttpClient,
    public readonly constService: AdmonitionService,
    public readonly titleService: TitleService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.load()
  }

  private async load(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/currencies/list', {}).toPromise()
      .then((data) => {
        for (const role of data.data) {
          let key = role
          key.val = this.getRandom()
          this.statuses.push(key)
        }
        this.isLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }

  public getName(name: string) {
    return name[0]
  }

  public getRandom(): string {
    const array = [
      'bg-blue-lt',
      'bg-azure-lt',
      'bg-indigo-lt',
      'bg-purple-lt',
      'bg-pink-lt',
      'bg-red-lt',
      'bg-orange-lt',
      'bg-yellow-lt',
      'bg-lime-lt',
      'bg-green-lt',
      'bg-teal-lt',
      'bg-cyan-lt'
    ]
    return array[Math.floor(Math.random()*array.length)];
  }


}
