<div class="antialiased border-top-wide border-primary d-flex flex-column max-height">
  <div class="flex-fill d-flex align-items-center justify-content-center">
    <div class="container-tight py-6">
      <div class="empty">
        <div class="empty-img"><img src="../../../assets/static/illustrations/undraw_quitting_time_dm8t.svg" height="128" alt="">
        </div>
        <p class="empty-title">Доступ запрещен</p>
        <p class="empty-subtitle text-muted">
          Страница к которой вы обращаетесь недоступна вам для посещения. Права доступа зависят от занимаемой вами должности
        </p>
        <div class="empty-action">
          <a href="javascript:void(0)" (click)="back()" class="btn btn-primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="5" y1="12" x2="19" y2="12" /><line x1="5" y1="12" x2="11" y2="18" /><line x1="5" y1="12" x2="11" y2="6" /></svg>
            Главная
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
