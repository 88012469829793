import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../../interfaces/error-response.interface";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit.category.component.html',
  styleUrls: ['./edit.category.component.scss']
})
export class EditCategoryComponent implements OnInit {

  public isLoading: boolean = true

  public id: string | undefined = undefined;

  public isChanging: boolean = false
  public isChanged: boolean = false

  public isErr: boolean = false

  public isCapacityErr: boolean = false
  public isDescriptionErr: boolean = false
  public isSynonymErr: boolean = false
  public isHeightErr: boolean = false
  public isImageErr: boolean = false
  public isLengthErr: boolean = false
  public isShortNameErr: boolean = false
  public isVolumeErr: boolean = false
  public isWidthErr: boolean = false

  public capacityErr: string = ''
  public descriptionErr: string = ''
  public synonymErr: string = ''
  public heightErr: string = ''
  public lengthErr: string = ''
  public shortNameErr: string = ''
  public volumeErr: string = ''
  public widthErr: string = ''

  public capacity: string = ''
  public description: string = ''
  public synonym: string = ''
  public height: string = ''
  public length: string = ''
  public shortName: string = ''
  public volume: string = ''
  public width: string = ''

  constructor(
    private readonly http: HttpClient,
    private readonly toastrService: ToastrService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe((data) => {
        this.id = data
      });
    await this.load()
  }

  public async clear(): Promise<void> {
    this.isChanging = false
    this.isChanged = false
    this.clearErrors()
    await this.load()
  }

  public clearErrors(): void {

    this.isErr = false
    this.isCapacityErr = false
    this.isDescriptionErr = false
    this.isSynonymErr = false
    this.isHeightErr = false
    this.isImageErr = false
    this.isLengthErr = false
    this.isShortNameErr = false
    this.isVolumeErr = false
    this.isWidthErr = false

    this.capacityErr = ''
    this.descriptionErr = ''
    this.synonymErr = ''
    this.heightErr = ''
    this.lengthErr = ''
    this.shortNameErr = ''
    this.volumeErr = ''
    this.widthErr = ''
  }

  async load(): Promise<any> {
    this.isLoading = true
    const json = {
      id: this.id as string
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/categories/get', json).toPromise()
      .then((data) => {
          this.capacity = data.data.capacity,
          this.description = data.data.description,
          this.synonym = data.data.synonym,
          this.height = data.data.height,
          this.length = data.data.length,
          this.shortName = data.data.shortName,
          this.volume = data.data.volume,
          this.width = data.data.width,
          this.isLoading = false
      })
      .catch((err) => {
        this.isLoading = false
        this.toastrService.error('Во время получения данных произошла ошибка', 'Ошибка')
      })
  }

  async change(): Promise<void> {
    this.clearErrors()
    this.isErr = false
    this.isChanging = true
    const json = {
      "id": this.id as string,
      "capacity": parseInt(this.capacity),
      "description": this.description,
      "synonym": this.synonym,
      "height": parseInt(this.height),
      "length": parseInt(this.length),
      "shortName": this.shortName,
      "volume": parseInt(this.volume),
      "width": parseInt(this.width)
    }
    await this.http.patch<SuccessResponseInterface>('/api/manager/categories/update', json).toPromise()
      .then((data) => {
        this.isChanging = false
        this.isErr = false
        this.isChanged = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.isChanging = false
        this.isErr = true
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Не указан ID категории') {
              this.isDescriptionErr = true
              this.descriptionErr = key
            }
            if (key === 'Вместительность указана неверно') {
              this.isCapacityErr = true
              this.capacityErr = key
            }
            if (key === 'Неверное полное наименование') {
              this.isDescriptionErr = true
              this.descriptionErr = key
            }
            if (key === 'Неверно указан синоним категории') {
              this.isSynonymErr = true
              this.synonymErr = key
            }
            if (key === 'Высота указана неверно') {
              this.isHeightErr = true
              this.heightErr = key
            }
            if (key === 'Длина указана неверно') {
              this.isLengthErr = true
              this.lengthErr = key
            }
            if (key === 'Сокращенное наименование указано неверно') {
              this.isShortNameErr = true
              this.shortNameErr = key
            }
            if (key === 'Объем указан неверно') {
              this.isVolumeErr = true
              this.volumeErr = key
            }
            if (key === 'Ширина указана неверно') {
              this.isWidthErr = true
              this.widthErr = key
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          if (err.error.message === 'Уже существует категория с таким полным наименованием') {
            this.isDescriptionErr = true
            this.descriptionErr = err.error.message
          }
          if (err.error.message === 'Уже существует категория с таким сокращенном наименованием') {
            this.isShortNameErr = true
            this.shortNameErr = err.error.message
          }
          if (err.error.message === 'Уже существует категория с таким синонимом') {
            this.isSynonymErr = true
            this.synonymErr = err.error.message
          }
          return;
        }
      })
  }

}
