import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {ManagerService} from "../services/manager.service";

@Injectable({
  providedIn: 'root'
})
export class IsAlreadyAuthGuard implements CanActivate {

  constructor(
    private readonly managerService: ManagerService,
    private router: Router
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    let isAuth = await this.managerService.isAuthManager()
    if (isAuth) {
      await this.router.navigateByUrl('/');
    }
    return true
  }

}
