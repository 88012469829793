import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../services/title.service";
import {
  EnvPrintInterface,
  EnvSizeEnum,
  EnvTypeEnum
} from "../../../../../app-nestjs/src/modules/evn-print/env-print.interface";
import {HttpClient} from "@angular/common/http";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";

@Component({
  selector: 'app-env-print',
  templateUrl: './env-print.component.html',
  styleUrls: ['./env-print.component.scss']
})
export class EnvPrintComponent implements OnInit {

  public isComplete: boolean = false
  public isFailed: boolean = false

  public isPrinting: boolean = false

  public envSize = EnvSizeEnum
  public envType = EnvTypeEnum

  public isDestAddressErr: boolean = false
  public isDestIndexErr: boolean = false
  public isDestOrgErr: boolean = false
  public isEnvSizeErr: boolean = false
  public isEnvTypeErr: boolean = false
  public isFromAddressErr: boolean = false
  public isFromIndexErr: boolean = false
  public isFromOrgErr: boolean = false

  public destAddressErr: string = ''
  public destIndexErr: string = ''
  public destOrgErr: string = ''
  public envSizeErr: string = ''
  public envTypeErr: string = ''
  public fromAddressErr: string = ''
  public fromIndexErr: string = ''
  public fromOrgErr: string = ''

  public json: EnvPrintInterface = {
    destAddress: "",
    destIndex: "",
    destOrg: "",
    envSize: EnvSizeEnum.c4,
    envType: EnvTypeEnum.regular,
    fromAddress: "",
    fromIndex: "",
    fromOrg: ""
  }

  constructor(
    private readonly http: HttpClient
  ) { }

  ngOnInit(): void {
    this.installOrg('tkakro')
  }

  public loadDefault(): void {
    this.isComplete = false
    this.isPrinting = false
    this.isFailed = false

    this.isDestAddressErr = false
    this.isDestIndexErr = false
    this.isDestOrgErr = false
    this.isEnvSizeErr = false
    this.isEnvTypeErr = false
    this.isFromAddressErr = false
    this.isFromIndexErr = false
    this.isFromOrgErr = false

    this.destAddressErr = ''
    this.destIndexErr = ''
    this.destOrgErr = ''
    this.envSizeErr = ''
    this.envTypeErr = ''
    this.fromAddressErr = ''
    this.fromIndexErr = ''
    this.fromOrgErr = ''
  }

  public async print(): Promise<void> {
    this.loadDefault()
    this.isPrinting = true
    await this.http.post<SuccessResponseInterface>('/api/manager/env-print/print', this.json).toPromise()
      .then((data) => {
        this.isPrinting = false
        this.isComplete = true
      })
      .catch((err: ErrorResponseInterface) => {
        this.isPrinting = false
        if (Array.isArray(err.error.message)) {
          for (const key of err.error.message) {
            if (key === 'Неверно указана организация-отправитель') {
              this.isFromOrgErr = true
              this.fromOrgErr = key
            }
            if (key === 'Индекс отправителя указан неверно') {
              this.isFromIndexErr = true
              this.fromIndexErr = key
            }
            if (key === 'Неверно указан адрес отправления') {
              this.isFromAddressErr = true
              this.fromAddressErr = key
            }
            if (key === 'Неверно указан размер конверта') {
              this.isEnvSizeErr = true
              this.envSizeErr = key
            }
            if (key === 'Неверно указана организация-получатель') {
              this.isDestOrgErr = true
              this.destOrgErr = key
            }
            if (key === 'Индекс получателя указан неверно') {
              this.isDestIndexErr = true
              this.destIndexErr = key
            }
            if (key === 'Неверно указан адрес получателя') {
              this.isDestAddressErr = true
              this.destAddressErr = key
            }
            if (key === 'Тип конверта указан неверно') {
              this.isEnvTypeErr = true
              this.envTypeErr = key
            }
          }
          return;
        }
        if (typeof err.error.message === 'string') {
          if (err.error.message === 'Во время отправки на печать произошла ошибка') {
            this.isFailed = true
            return;
          }
          if (err.error.message === 'Произошла непредвиденная ошибка сервиса') {
            this.isFailed = true
            return;
          }
        }
      })
  }

  public installOrg(value: string): void {
    if (value === 'tkakro') {
      this.json.fromIndex = '107553'
      this.json.fromOrg = 'ООО "ТК АКРО"'
      this.json.fromAddress = 'г. Москва, ул. Большая Черкизовская 24А, С1, оф. 833, БЦ Эталон'
    }
    if (value === 'iprodikov') {
      this.json.fromIndex = '107553'
      this.json.fromOrg = 'ИП Родиков А.А.'
      this.json.fromAddress = 'г. Москва, ул. Большая Черкизовская 24А, С1, оф. 833, БЦ Эталон'
    }
    if (value === 'oooakro') {
      this.json.fromIndex = '111024'
      this.json.fromOrg = 'ООО "АКРО"'
      this.json.fromAddress = 'г. Москва, ул. Авиамоторная, д. 50, стр. 2, ЭТ 2 П XI К 16 ОФ 16Б от ООО "АКРО"'
    }
  }

}
