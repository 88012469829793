import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../../services/title.service";
import {Manager} from "../../../../../../app-nestjs/src/modules/manager/manager.interface";
import {SuccessResponseInterface} from "../../../interfaces/success-response.interface";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {GetManagerComponent} from "../get/get.manager.component";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-list-manager',
  templateUrl: './list.manager.component.html',
  styleUrls: ['./list.manager.component.scss']
})
export class ListManagerComponent implements OnInit {

  bsModalRef: BsModalRef | undefined

  public isLoading: boolean = true
  public offset: number = 0
  public limit: number = 100

  public managers: Manager.ManagerInterface[] = []

  constructor(
    private readonly http: HttpClient,
    private modalService: BsModalService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.load()
  }

  // Загрузить менеджеров
  private async load(): Promise<void> {
    this.isLoading = true;
    const body = {
      offset: this.offset,
      limit: this.limit
    }
    await this.http.post<SuccessResponseInterface>('/api/manager/managers/list', body).subscribe(
      (data) => {
        this.managers = data.data
        this.isLoading = false
      },
      (err) => {
        this.isLoading = false
      }
    )
  }

  // Получить текстовый аватар
  public getTextAvatar(name: Manager.name, surname: Manager.surname): any {
    if (surname && name) {
      return name[0] + surname[0]
    }
    if (name) {
      return name[0]
    }
  }

  public async openModalWithComponent(manager: Manager.ManagerInterface) {
    const initialState = {
      manager: manager
    };
    // @ts-ignore
    this.bsModalRef = this.modalService.show(GetManagerComponent, {initialState})
  }

}
