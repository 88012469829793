<button type="button" (click)="bsModalRef.hide()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
<div *ngIf="!manager?.isFired" class="modal-status bg-success"></div>
<div *ngIf="manager?.isFired" class="modal-status bg-danger"></div>
<div class="modal-body text-center py-4">
  <h3>Профиль сотрудника</h3>
  <span *ngIf="!manager?.avatar" class="avatar avatar-xl mb-2"
        style="">{{getTextAvatar(manager?.name, manager?.surname)}}
    <span *ngIf="manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span></span>
  <span *ngIf="manager?.avatar" class="avatar avatar-xl mb-2"
        [style.background-image]="'url(' + manager?.avatar + ')'">
    <span *ngIf="manager?.isOnline" [style.width.rem]="0.5" [style.height.rem]="0.5" class="badge bg-success"></span>
  </span>
  <h4>{{manager?.surname}} {{manager?.name}} {{manager?.patronymic}}</h4>
  <span *ngIf="!manager?.isFired"  class="badge bg-success mb-2">{{manager?.position}}</span>
  <span *ngIf="manager?.isFired"  class="badge bg-danger mb-2">{{manager?.position}}</span>
  <div class="text-muted">Email: {{manager?.email}}</div>
  <div class="text-muted">Мобильный: {{manager?.mobile || '-'}}</div>
  <div class="text-muted">Добавочный: {{manager?.addNum || '-'}}</div>
</div>
<div class="modal-footer">
  <div class="w-100">
    <div class="row">
      <div class="col">
        <a href="javascript:void(0)" (click)="bsModalRef.hide()" class="btn btn-white w-100" data-bs-dismiss="modal">
          Закрыть
        </a>
      </div>
    </div>
  </div>
</div>
