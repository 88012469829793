<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="container-xl">
        <div class="empty">
          <div class="empty-img"><img src="/assets/static/illustrations/undraw_printing_invoices_5r4r.svg" height="128" alt="">
          </div>
          <p class="empty-title">Рабочий стол</p>
          <p class="empty-subtitle text-muted">
            На этой странице будет располагаться виджеты и данные быстрого доступа
          </p>
          <div class="empty-action">
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
