<div *ngIf="isLoading" class="col-lg-12 centered">
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div *ngIf="!isLoading" class="alert alert-important alert-info alert-dismissible" role="alert">
  <div class="d-flex">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24" viewBox="0 0 24 24"
           stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="12" cy="12" r="9"/>
        <line x1="12" y1="8" x2="12.01" y2="8"/>
        <polyline points="11 12 12 12 12 16 13 16"/>
      </svg>
    </div>
    <div>
      Сессии удаляются автоматически если последняя активность была свыше 3 месяцев
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="card">
  <div class="card-header">
    <h3 class="card-title">Сессии</h3>
  </div>
  <div class="card-body">
    <div class="divide-y">
      <div *ngFor="let session of sessions">
        <div class="row">
          <div class="col-auto">
            <span class="avatar">
	            <svg *ngIf="session.platform == 'Apple Mac'" xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                   height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z"/><path
                d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2"/></svg>
	            <svg *ngIf="session.platform == 'Microsoft Windows'" xmlns="http://www.w3.org/2000/svg" class="icon"
                   width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z"/><line
                x1="12" y1="5" x2="12" y2="19"/><line x1="4" y1="12" x2="20" y2="12"/></svg>
	            <svg *ngIf="session.platform == 'Linux'" xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                   height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                d="M12 3a9 9 0 0 1 3.618 17.243l-2.193 -5.602a3 3 0 1 0 -2.849 0l-2.193 5.603a9 9 0 0 1 3.617 -17.244z"/></svg>
	            <svg *ngIf="session.platform == 'Android'" xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                   height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line
                x1="4" y1="10" x2="4" y2="16"/><line x1="20" y1="10" x2="20" y2="16"/><path
                d="M7 9h10v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-8a5 5 0 0 1 10 0"/><line x1="8" y1="3" x2="9" y2="5"/><line
                x1="16" y1="3" x2="15" y2="5"/><line x1="9" y1="18" x2="9" y2="21"/><line x1="15" y1="18" x2="15"
                                                                                          y2="21"/></svg>
	            <svg *ngIf="getDeviceOther(session)" xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                   height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                d="M3 5h6v14h-6z"/><path d="M12 9h10v7h-10z"/><path d="M14 19h6"/><path d="M17 16v3"/><path
                d="M6 13v.01"/><path d="M6 16v.01"/></svg>
            </span>
          </div>
          <div class="col">
            <div class="text-truncate" [innerHTML]="getTextForSession(session)">
            </div>
            <div class="text-muted">Последняя активность:
              <strong>{{session.lastOnline | date:"dd/MM/yy HH:mm" }}</strong></div>
          </div>
          <div class="col-auto align-self-center">
            <a *ngIf="!session.isCurrent" (click)="removeOneSessionModal(modalRemoveOneSessions, session)"
               href="javascript:void(0)">
              <svg href="javascript:void(0)" xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                   viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                   stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="4" y1="7" x2="20" y2="7"/>
                <line x1="10" y1="11" x2="10" y2="17"/>
                <line x1="14" y1="11" x2="14" y2="17"/>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer bgw">
    <div class="d-flex">
      <a href="javascript:void(0)" (click)="removeAllSessionsModal(modalRemoveAllSessions)"
         class="btn btn-link ms-auto bgc-t">Удалить все сессии, кроме текущей</a>
    </div>
  </div>
</div>

<ng-template #modalRemoveAllSessions>
  <div class="modal modal-blur fade show" tabindex="-1" role="dialog" aria-modal="true" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div *ngIf="!allSessionsRemoved && !allSessionsRemovedErr" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удаление всех сессий</h5>
          <button (click)="modalRef.hide(); allSessionsRemoved = false; allSessionsRemovedErr = false" type="button"
                  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body py-4">
          Все сессии, кроме текущей будут удалены. Вы уверены?
        </div>
        <div class="modal-footer">
          <button (click)="modalRef.hide(); allSessionsRemoved = false; allSessionsRemovedErr = false" type="button"
                  class="btn me-auto" data-bs-dismiss="modal">Отмена
          </button>
          <button (click)="removeAllSessions()" [class.disabled]="allSessionsRemoveLoading" type="button"
                  class="btn btn-danger" data-bs-dismiss="modal">
            <span *ngIf="allSessionsRemoveLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                 stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="4" y1="7" x2="20" y2="7"/>
              <line x1="10" y1="11" x2="10" y2="17"/>
              <line x1="14" y1="11" x2="14" y2="17"/>
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
            </svg>
            Удалить
          </button>
        </div>
      </div>
      <div *ngIf="allSessionsRemoved && !allSessionsRemovedErr" class="modal-content">
        <button (click)="modalRef.hide(); allSessionsRemoved = false; allSessionsRemovedErr = false" type="button"
                class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-status bg-success"></div>
        <div class="modal-body text-center py-4">
          <h3>Сессии удалены</h3>
          <div class="text-muted">Все сессии вашей учетный записи, за исключением текущей были удалены</div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <div class="row">
              <div class="col">
                <a (click)="modalRef.hide(); allSessionsRemoved = false; allSessionsRemovedErr = false"
                   href="javascript:void(0)" class="btn btn-success w-100" data-bs-dismiss="modal">
                  Закрыть
                </a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalRemoveOneSessions>
  <div class="modal modal-blur fade show" tabindex="-1" role="dialog" aria-modal="true" style="display: block;">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div *ngIf="!oneSessionRemoved && !oneSessionRemovedErr" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удаление сессии</h5>
          <button (click)="modalRef.hide(); oneSessionRemoved = false; oneSessionRemovedErr = false" type="button"
                  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body py-4">
          Сессия будет удалена. Подтверждаете?
        </div>
        <div class="modal-footer">
          <button (click)="modalRef.hide(); oneSessionRemoved = false; oneSessionRemovedErr = false" type="button"
                  class="btn me-auto" data-bs-dismiss="modal">Отмена
          </button>
          <button (click)="removeOneSession(sessionToRemove.id)" [class.disabled]="oneSessionRemoveLoading" type="button"
                  class="btn btn-danger" data-bs-dismiss="modal">
            <span *ngIf="oneSessionRemoveLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                 stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="4" y1="7" x2="20" y2="7"/>
              <line x1="10" y1="11" x2="10" y2="17"/>
              <line x1="14" y1="11" x2="14" y2="17"/>
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
            </svg>
            Удалить
          </button>
        </div>
      </div>
      <div *ngIf="oneSessionRemoved && !oneSessionRemovedErr" class="modal-content">
        <button (click)="modalRef.hide(); oneSessionRemoved = false; oneSessionRemovedErr = false" type="button"
                class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-status bg-success"></div>
        <div class="modal-body text-center py-4">
          <h3>Сессия удалена</h3>
          <div class="text-muted">Выбранная вами сессия (которая не является текущей) была удалена</div>
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <div class="row">
              <div class="col">
                <a (click)="modalRef.hide(); oneSessionRemoved = false; oneSessionRemovedErr = false"
                   href="javascript:void(0)" class="btn btn-success w-100" data-bs-dismiss="modal">
                  Закрыть
                </a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
