import {Category} from "../category/category.interface";

export enum PalletsTypeEnum {
    'eur' = 'eur',
    'fin' = 'fin',
    'usa' = 'usa',
    'asia' = 'asia',
    'custom' = 'custom'
}

// todo: добавить тип паллет с клиента

export namespace Pallet {
    export type value = PalletsTypeEnum;
    export type id = string | undefined;
    export type description = string;
    export type name = string;
    export type length = number;
    export type width = number;
    export type height = number;
    export type capacity = number;
    export type isCustom = boolean;
    export type clientId = string | null;
    export type createdAt = Date;
    export type updatedAt = Date;
}

export interface PalletInterface {
    value: Pallet.value,
    id: Pallet.id
    description: Pallet.description,
    name: Pallet.name,
    length: Pallet.length,
    width: Pallet.width,
    height: Pallet.height,
    capacity: Pallet.capacity
    isCustom: Pallet.isCustom,
    clientId: Pallet.clientId,
    createdAt?: Pallet.createdAt,
    updatedAt?: Pallet.updatedAt,
}

export interface SimplePalletCalculator {
    height: number,
    weight: number,
    count: number,
    pallet: PalletInterface | null
    categories: Category.CategoryInterface[]
}

export interface ContainersPalletCalculatorServerRequestInterface {
    ID: number,
    Length: number,
    Width: number,
    Height: number
}

export interface ItemsToPackPalletCalculatorServerRequestInterface {
    ID: number,
    Dim1: number,
    Dim2: number,
    Dim3: number,
    Quantity: number
}

export interface PalletCalculatorServerRequestInterface {
    Containers: ContainersPalletCalculatorServerRequestInterface[],
    ItemsToPack: ItemsToPackPalletCalculatorServerRequestInterface[],
    AlgorithmTypeIDs: Array<String>
}

export interface ItemsPalletCalculatorServerResponseInterface {
    ID: number,
    IsPacked: boolean,
    Dim1: number,
    Dim2: number,
    Dim3: number,
    CoordX: number,
    CoordY: number,
    CoordZ: number,
    PackDimX: number,
    PackDimY: number,
    PackDimZ: number,
    Volume: number
}

export interface AlgorithmPackingResultsPalletCalculatorServerResponseInterface {
    AlgorithmID: number,
    AlgorithmName: string,
    IsCompletePack: boolean,
    PackedItems: ItemsPalletCalculatorServerResponseInterface[],
    PackTimeInMilliseconds: number,
    PercentContainerVolumePacked: number,
    PercentItemVolumePacked: number,
    UnpackedItems: ItemsPalletCalculatorServerResponseInterface[]
}


export interface PalletCalculatorServerResponseInterface {
    ContainerID: number,
    AlgorithmPackingResults: AlgorithmPackingResultsPalletCalculatorServerResponseInterface[]
}

export interface SimplePalletPacked {
    packX: number,
    packY: number,
    packZ: number,
    sizeX: number,
    sizeY: number,
    sizeZ: number,
    CoordX: number,
    CoordY: number,
    CoordZ: number
}

export interface SimpleCalculatorJson {
    category: Category.CategoryInterface,
    isPackedSuccess: boolean,
    countSuccess: boolean,
    capacitySuccess: boolean,
    heightSuccess: boolean,
    percentContainerVolumePacked: number,
    percentItemVolumePacked: number,
    packTimeInMilliseconds: number
    algorithmName: string,
    packetPallets: SimplePalletPacked[]
}
