<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="container-xl">
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <h2 class="page-title">
                Профиль
              </h2>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <div class="row gx-lg-4">
              <div class="d-none d-lg-block col-lg-3">
                <ul class="nav nav-pills nav-vertical">
                  <li (click)="setComponent('main')" class="nav-item">
                    <a href="javascript:void(0)" [class.active]="profileComponentSelected" class="nav-link">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon nav-link-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="7" r="4" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                      Профиль
                    </a>
                  </li>
                  <li (click)="setComponent('avatar')" class="nav-item">
                    <a href="javascript:void(0)" [class.active]="avatarComponentSelected" class="nav-link">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon nav-link-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="15" y1="8" x2="15.01" y2="8" /><rect x="4" y="4" width="16" height="16" rx="3" /><path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" /><path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" /></svg>
                      Аватар
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="javascript:void(0)" [class.active]="officeComponentSelected" class="nav-link disabled">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon nav-link-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="3" y1="21" x2="21" y2="21" /><path d="M5 21v-14l8 -4v18" /><path d="M19 21v-10l-6 -4" /><line x1="9" y1="9" x2="9" y2="9.01" /><line x1="9" y1="12" x2="9" y2="12.01" /><line x1="9" y1="15" x2="9" y2="15.01" /><line x1="9" y1="18" x2="9" y2="18.01" /></svg>
                      Офисы и департаменты
                    </a>
                  </li>
                  <li class="nav-item disabled">
                    <a href="javascript:void(0)" [class.active]="sessionsComponentSelected" class="nav-link disabled">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon nav-link-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7" /><line x1="10" y1="10" x2="10.01" y2="10" /><line x1="14" y1="10" x2="14.01" y2="10" /><path d="M10 14a3.5 3.5 0 0 0 4 0" /></svg>
                      Сессии
                    </a>
                  </li>
                  <li (click)="setComponent('password')" class="nav-item">
                    <a href="javascript:void(0)" [class.active]="passwordComponentSelected" class="nav-link">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon nav-link-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="5" y="11" width="14" height="10" rx="2" /><circle cx="12" cy="16" r="1" /><path d="M8 11v-4a4 4 0 0 1 8 0v4" /></svg>
                      Смена пароля
                    </a>
                  </li>
                  <li class="nav-item disabled">
                    <a href="javascript:void(0)" [class.active]="sessionsComponentSelected" class="nav-link disabled">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon nav-link-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" /><path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" /></svg>
                      Интеграции
                    </a>
                  </li>
                  <li (click)="setComponent('rdp')" class="nav-item">
                    <a href="javascript:void(0)" [class.active]="rdpComponentSelected" class="nav-link">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon nav-link-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="4" y1="12" x2="20" y2="12" /></svg>
                      RDP
                    </a>
                  </li>
                </ul>
              </div>
              <app-main-profile *ngIf="profileComponentSelected" class="col-lg-9"></app-main-profile>
              <app-profile-avatar *ngIf="avatarComponentSelected" class="col-lg-9"></app-profile-avatar>
              <app-profile-offices *ngIf="officeComponentSelected" class="col-lg-9"></app-profile-offices>
              <app-profile-sessons *ngIf="sessionsComponentSelected" class="col-lg-9"></app-profile-sessons>
              <app-profile-password *ngIf="passwordComponentSelected" class="col-lg-9"></app-profile-password>
              <app-profile-rdp *ngIf="rdpComponentSelected" class="col-lg-9"></app-profile-rdp>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
