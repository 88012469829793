<div class="antialiased">
  <div class="page">
    <app-menu></app-menu>
    <div class="content">
      <div class="page-wrapper">
        <div class="page-body">
          <div class="page-body">
            <div class="container-xl">
              <div class="row row-cards">

                <!-- Глобальные -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Глобальные
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Регионы -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/regions/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M10.5 20.4l-6.9 -6.9c-.781 -.781 -.781 -2.219 0 -3l6.9 -6.9c.781 -.781 2.219 -.781 3 0l6.9 6.9c.781 .781 .781 2.219 0 3l-6.9 6.9c-.781 .781 -2.219 .781 -3 0z"/><path
                            d="M9 14v-2c0 -.59 .414 -1 1 -1h5"/><path d="M13 9l2 2l-2 2"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Регионы</div>
                          <div class="text-muted">Список типов перевозок - регионов</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Типы контрагентов -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="9" cy="7"
                                                                                                         r="4"/><path
                            d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/><line x1="19" y1="7" x2="19" y2="10"/><line
                            x1="19" y1="14" x2="19" y2="14.01"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Типы контрагентов</div>
                          <div class="text-muted">Перечисление типов контрагента</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Типы перевозок -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/service-types/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3l-4 7h8z" /><circle cx="17" cy="17" r="3" /><rect x="4" y="14" width="6" height="6" rx="1" /></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Типы перевозок</div>
                          <div class="text-muted">Перечисление типов перевозок</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Баги -->
                <div class="col-md-6 col-xl-3" hidden>
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M9 9v-1a3 3 0 0 1 6 0v1"/><path
                            d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3"/><line x1="3" y1="13" x2="7"
                                                                                                y2="13"/><line x1="17"
                                                                                                               y1="13"
                                                                                                               x2="21"
                                                                                                               y2="13"/><line
                            x1="12" y1="20" x2="12" y2="14"/><line x1="4" y1="19" x2="7.35" y2="17"/><line x1="20"
                                                                                                           y1="19"
                                                                                                           x2="16.65"
                                                                                                           y2="17"/><line
                            x1="4" y1="7" x2="7.75" y2="9.4"/><line x1="20" y1="7" x2="16.25" y2="9.4"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Баги и предложения</div>
                          <div class="text-muted">Список ошибок и предложений всех участников</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Файлы -->
                <div class="col-md-6 col-xl-3" hidden>
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"/><path
                            d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Файлы</div>
                          <div class="text-muted">Все что когда-то было загружено</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Полигоны -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/polygons/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="6" cy="19"
                                                                                                         r="2"/><circle
                            cx="18" cy="5" r="2"/><path
                            d="M12 19h4.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h3.5"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Полигоны</div>
                          <div class="text-muted">Зоны обслуживания для перевозок</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Паллеты -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/pallets/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><polyline
                            points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"/><line x1="12" y1="12" x2="20"
                                                                                        y2="7.5"/><line x1="12" y1="12"
                                                                                                        x2="12"
                                                                                                        y2="21"/><line
                            x1="12" y1="12" x2="4" y2="7.5"/></svg>
                           <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Паллеты</div>
                          <div class="text-muted">Стандартные и создананные клиентами паллеты</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Статусы заказа -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/statuses/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><polyline
                            points="9 11 12 14 20 6"/><path
                            d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Статусы заказа</div>
                          <div class="text-muted">Перечисления всех статусов заказа</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- События заказа -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/states/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-purple-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M3.5 5.5l1.5 1.5l2.5 -2.5"/><path d="M3.5 11.5l1.5 1.5l2.5 -2.5"/><path
                            d="M3.5 17.5l1.5 1.5l2.5 -2.5"/><line x1="11" y1="6" x2="20" y2="6"/><line x1="11" y1="12"
                                                                                                       x2="20" y2="12"/><line
                            x1="11" y1="18" x2="20" y2="18"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">События заказа</div>
                          <div class="text-muted">Перечисления возможных событий в заказе</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Автомобили и водители -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Автомобили и водители
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Автомобили -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-red-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="7" cy="17"
                                                                                                         r="2"/><circle
                            cx="17" cy="17" r="2"/><path
                            d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Автомобили</div>
                          <div class="text-muted">Список всех транспортных средств</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Водители -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/drivers/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-red-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="12" cy="12"
                                                                                                         r="9"/><circle
                            cx="12" cy="12" r="2"/><line x1="12" y1="14" x2="12" y2="21"/><line x1="10" y1="12"
                                                                                                x2="3.25" y2="10"/><line
                            x1="14" y1="12" x2="20.75" y2="10"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Водители</div>
                          <div class="text-muted">Список всех водителей-исполнителей</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Тип кузова -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/carcases/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-red-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="4" cy="17"
                                                                                                         r="2"/><circle
                            cx="13" cy="17" r="2"/><path d="M18 13v6h3"/><line x1="13" y1="19" x2="4" y2="19"/><line
                            x1="4" y1="15" x2="13" y2="15"/><path d="M8 12v-5h2a3 3 0 0 1 3 3v5"/><path
                            d="M5 15v-2a1 1 0 0 1 1 -1h7"/><line x1="18" y1="17" x2="15" y2="17"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Типы кузова</div>
                          <div class="text-muted">Перечисление всех типов кузовов</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Категории транспорта -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/categories/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-red-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path d="M20 20h.01"/><path
                            d="M4 20h.01"/><path d="M8 20h.01"/><path d="M12 20h.01"/><path d="M16 20h.01"/><path
                            d="M20 4h.01"/><path d="M4 4h.01"/><path d="M8 4h.01"/><path d="M12 4h.01"/><path
                            d="M16 4l0 0"/><rect x="4" y="8" width="16" height="8" rx="1"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Категории транспорта</div>
                          <div class="text-muted">Список всех категорий транспорта</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Прицепы -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-red-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="7" cy="17"
                                                                                                         r="2"/><circle
                            cx="17" cy="17" r="2"/><path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v6h-5l2 2m0 -4l-2 2"/><line
                            x1="9" y1="17" x2="15" y2="17"/><path d="M13 6h5l3 5v6h-2"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Прицепы</div>
                          <div class="text-muted">Перечисление прицепов и полуприцепов</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Бухгалтерия -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Бухгалетрия
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Акты -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/><rect
                            x="9" y="3" width="6" height="4" rx="2"/><path d="M9 14l2 2l4 -4"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Акты</div>
                          <div class="text-muted">Перечисление всех актов по заказам</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Акты сверки -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/><rect
                            x="9" y="3" width="6" height="4" rx="2"/><line x1="9" y1="12" x2="9.01" y2="12"/><line
                            x1="13" y1="12" x2="15" y2="12"/><line x1="9" y1="16" x2="9.01" y2="16"/><line x1="13"
                                                                                                           y1="16"
                                                                                                           x2="15"
                                                                                                           y2="16"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Акты сверки</div>
                          <div class="text-muted">Перечисление всех актов сверки по клиенту</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Банковские счета -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                               stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none"
                                                                                                                      d="M0 0h24v24H0z"
                                                                                                                      fill="none"/><path
                            d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/><rect x="9" y="3" width="6"
                                                                                                                        height="4" rx="2"/><path
                            d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"/><path d="M12 17v1m0 -8v1"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Банковские аккаунты</div>
                          <div class="text-muted">Список всех банковских счетов клиентов</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Банки-->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/banks/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="3" y1="21" x2="21" y2="21" /><line x1="3" y1="10" x2="21" y2="10" /><polyline points="5 6 12 3 19 6" /><line x1="4" y1="10" x2="4" y2="21" /><line x1="20" y1="10" x2="20" y2="21" /><line x1="8" y1="14" x2="8" y2="17" /><line x1="12" y1="14" x2="12" y2="17" /><line x1="16" y1="14" x2="16" y2="17" /></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Банки</div>
                          <div class="text-muted">Самообновляемый классификатор банков</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Валюты -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/currencies/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"/><path
                            d="M12 3v3m0 12v3"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Валюты</div>
                          <div class="text-muted">Перечисление всех валют используемых в системе</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Счет -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M14 3v4a1 1 0 0 0 1 1h4"/><path
                            d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/><line x1="9"
                                                                                                              y1="7"
                                                                                                              x2="10"
                                                                                                              y2="7"/><line
                            x1="9" y1="13" x2="15" y2="13"/><line x1="13" y1="17" x2="15" y2="17"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Счет</div>
                          <div class="text-muted">Список всех счетов в системе по клиенту</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Счет-реестры -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M15 3v4a1 1 0 0 0 1 1h4"/><path
                            d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z"/><path
                            d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Счет-реестры</div>
                          <div class="text-muted">Перечисление счетов-реестров по клиенту</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Сканы -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M14 3v4a1 1 0 0 0 1 1h4"/><path
                            d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v5m-5 6h7m-3 -3l3 3l-3 3"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Сканы</div>
                          <div class="text-muted">Перечисление всех сканов в системе</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- УПД -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-green-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M14 3v4a1 1 0 0 0 1 1h4"/><path
                            d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">УПД</div>
                          <div class="text-muted">Перечисление всех УПД по клиенту</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>


                <!-- Офисы и сотрудники -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Офис и сотрудники
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Сотрудники -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/managers/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-yellow-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="12" cy="7"
                                                                                                         r="4"/><path
                            d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Сотрудники</div>
                          <div class="text-muted">Список всех сотрудников компании</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Права сотрудников -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/managers/roles" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-yellow-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M4 8v-2a2 2 0 0 1 2 -2h2"/><path d="M4 16v2a2 2 0 0 0 2 2h2"/><path
                            d="M16 4h2a2 2 0 0 1 2 2v2"/><path d="M16 20h2a2 2 0 0 0 2 -2v-2"/><rect x="8" y="11"
                                                                                                     width="8"
                                                                                                     height="5" rx="1"/><path
                            d="M10 11v-2a2 2 0 1 1 4 0v2"/></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Права сотрудников</div>
                          <div class="text-muted">Список прав сотрудников (ролей)</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Департаменты -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-yellow-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="9" cy="7"
                                                                                                         r="4"/><path
                            d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/><path
                            d="M21 21v-2a4 4 0 0 0 -3 -3.85"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Департаменты</div>
                          <div class="text-muted">Список отделов и департаментов офисов</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Сессии сотрудников -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-yellow-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7"/><line
                            x1="10" y1="10" x2="10.01" y2="10"/><line x1="14" y1="10" x2="14.01" y2="10"/><path
                            d="M10 14a3.5 3.5 0 0 0 4 0"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Сессии сотрудников</div>
                          <div class="text-muted">Сессии сотрудников компании с возможностью удаления</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Должности сотрудников -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-yellow-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><rect x="4" y="4"
                                                                                                       width="16"
                                                                                                       height="4"
                                                                                                       rx="1"/><rect
                            x="4" y="12" width="6" height="8" rx="1"/><line x1="14" y1="12" x2="20" y2="12"/><line
                            x1="14" y1="16" x2="20" y2="16"/><line x1="14" y1="20" x2="20" y2="20"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Должности сотрудников</div>
                          <div class="text-muted">Список должностей сотрудников нашей компании</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Офисы -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/offices/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-yellow-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><line x1="3" y1="21"
                                                                                                       x2="21" y2="21"/><path
                            d="M5 21v-14l8 -4v18"/><path d="M19 21v-10l-6 -4"/><line x1="9" y1="9" x2="9" y2="9.01"/><line
                            x1="9" y1="12" x2="9" y2="12.01"/><line x1="9" y1="15" x2="9" y2="15.01"/><line x1="9"
                                                                                                            y1="18"
                                                                                                            x2="9"
                                                                                                            y2="18.01"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Офисы</div>
                          <div class="text-muted">Список контрагентов-офисов нашей компании</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- RDP -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/rdp/list" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-yellow-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17.8 20l-12 -1.5c-1 -.1 -1.8 -.9 -1.8 -1.9v-9.2c0 -1 .8 -1.8 1.8 -1.9l12 -1.5c1.2 -.1 2.2 .8 2.2 1.9v12.1c0 1.2 -1.1 2.1 -2.2 1.9z" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="4" y1="12" x2="20" y2="12" /></svg>
                          <span class="badge bg-success"></span>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">RDP</div>
                          <div class="text-muted">Список настроек пользователя для терминала</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Заказы и заявки -->
                <div hidden class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Заказы и заявки
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Заказы -->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-blue-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="6" cy="19"
                                                                                                         r="2"/><circle
                            cx="17" cy="19" r="2"/><path d="M17 17h-11v-14h-2"/><path d="M6 5l14 1l-1 7h-13"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Заказы</div>
                          <div class="text-muted">Вывод данных из таблицы заказов</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Запросы -->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-blue-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="10" cy="10"
                                                                                                         r="7"/><path
                            d="M21 21l-6 -6"/><path d="M12 7h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"/><path
                            d="M10 13v1m0 -8v1"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Запросы</div>
                          <div class="text-muted">Вывод данных из таблицы запросов</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- События заказов -->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-blue-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M8.7 3h6.6c.3 0 .5 .1 .7 .3l4.7 4.7c.2 .2 .3 .4 .3 .7v6.6c0 .3 -.1 .5 -.3 .7l-4.7 4.7c-.2 .2 -.4 .3 -.7 .3h-6.6c-.3 0 -.5 -.1 -.7 -.3l-4.7 -4.7c-.2 -.2 -.3 -.4 -.3 -.7v-6.6c0 -.3 .1 -.5 .3 -.7l4.7 -4.7c.2 -.2 .4 -.3 .7 -.3z"/><line
                            x1="12" y1="8" x2="12" y2="12"/><line x1="12" y1="16" x2="12.01" y2="16"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">События</div>
                          <div class="text-muted">Перечисление списка событий заказа и заявки</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Отработки -->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-blue-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M12 3a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3"/><path d="M6 3a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3"/><path
                            d="M13 7h7a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-7"/><path
                            d="M5 7h-1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h1"/><path d="M17 12h.01"/><path
                            d="M13 12h.01"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Отчет о работе</div>
                          <div class="text-muted">Перечисление отработок по заказам</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Точки маршрутов -->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-blue-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M10.828 9.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z"/><line x1="8" y1="7" x2="8"
                                                                                                  y2="7.01"/><path
                            d="M18.828 17.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z"/><line x1="16" y1="15"
                                                                                                   x2="16" y2="15.01"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Точки маршрутов</div>
                          <div class="text-muted">Перечисление всех точек маршрута указанных в заказах</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Клиенты и их сотрудники -->
                <div class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Клиенты и их сотрудники
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Клиенты-контрагенты -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-cyan-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="12" cy="12"
                                                                                                         r="9"/><line
                            x1="9" y1="9" x2="9.01" y2="9"/><line x1="15" y1="9" x2="15.01" y2="9"/><path
                            d="M8 13a4 4 0 1 0 8 0m0 0h-8"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Контрагенты</div>
                          <div class="text-muted">Список контрагентов (клиентов)</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Пользователи ЛК -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-cyan-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="7" cy="5"
                                                                                                         r="2"/><path
                            d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5"/><circle cx="17" cy="5"
                                                                                                   r="2"/><path
                            d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Пользователи</div>
                          <div class="text-muted">Список сотрудников контрагента-клиента</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Сессии пользователей -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-cyan-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7"/><line
                            x1="10" y1="10" x2="10.01" y2="10"/><line x1="14" y1="10" x2="14.01" y2="10"/><path
                            d="M10 14a3.5 3.5 0 0 0 4 0"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Сессии пользователя</div>
                          <div class="text-muted">Список сессий с возможностью удаления</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Права доступа пользователей -->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-cyan-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Права доступа</div>
                          <div class="text-muted">Права доступа пользователя к контрагенту</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Уведомления-->
                <div class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/404" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-cyan-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M10 6h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"/><circle cx="17" cy="7"
                                                                                                     r="3"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Уведомления</div>
                          <div class="text-muted">Список уведомлений пользователя</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <!-- Интеграции -->
                <div hidden class="container-xl">
                  <div style="margin: 0" class="page-header d-print-none">
                    <div class="row align-items-center">
                      <div class="col">
                        <h2 class="page-title">
                          Интеграции и 1С
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 1С-->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z"/><circle cx="12" cy="12" r="9"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">1С</div>
                          <div class="text-muted">API, WS, Webhooks и все что она умеет делать плохо</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Телефония-->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Asterisk</div>
                          <div class="text-muted">Обратные звонки, очереди и роботы</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Telegram-->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <!-- Download SVG icon from http://tabler-icons.io/i/brand-telegram -->
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><path
                            d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Telegram</div>
                          <div class="text-muted">Чат боты для клиентов, водителей и сотрудников</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Сайт-->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><circle cx="12" cy="12"
                                                                                                         r="9"/><line
                            x1="3.6" y1="9" x2="20.4" y2="9"/><line x1="3.6" y1="15" x2="20.4" y2="15"/><path
                            d="M11.5 3a17 17 0 0 0 0 18"/><path d="M12.5 3a17 17 0 0 1 0 18"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Сайт</div>
                          <div class="text-muted">Передача метаданных между сайтом и сервисом</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Почта-->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
	                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><rect x="3" y="5"
                                                                                                       width="18"
                                                                                                       height="14"
                                                                                                       rx="2"/><polyline
                            points="3 7 12 13 21 7"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Почта</div>
                          <div class="text-muted">Емейл маркетинг, ответы и настройки доступа</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- Bitrix24-->
                <div hidden class="col-md-6 col-xl-3">
                  <a class="card card-link" routerLink="/counterparty-types" href="javascript:void(0)">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-auto">
                        <span class="avatar rounded bg-lime-lt">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                               stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                    fill="none"/><rect x="3" y="4"
                                                                                                       width="18"
                                                                                                       height="12"
                                                                                                       rx="1"/><line
                            x1="7" y1="20" x2="17" y2="20"/><line x1="9" y1="16" x2="9" y2="20"/><line x1="15" y1="16"
                                                                                                       x2="15" y2="20"/><path
                            d="M8 12l3 -3l2 2l3 -3"/></svg>
                        </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">Bitrix24</div>
                          <div class="text-muted">Продажи, аналитика, omni channel</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
