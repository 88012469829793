import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TitleService} from "../../services/title.service";
import {SuccessResponseInterface} from "../../interfaces/success-response.interface";
import {ErrorResponseInterface} from "../../interfaces/error-response.interface";
import {AdmonitionService} from "../../services/admonition.service";
import {Polygon} from "../../../../../app-nestjs/src/modules/polygon/polygon.interface";
import {latLng, tileLayer, polygon, LatLngExpression} from "leaflet";
import * as turf from '@turf/turf'
import {Manager} from "../../../../../app-nestjs/src/modules/manager/manager.interface";
import {GetManagerComponent} from "../manager/get/get.manager.component";
import {ManagerService} from "../../services/manager.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";


@Component({
  selector: 'app-polygon',
  templateUrl: './polygon.component.html',
  styleUrls: ['./polygon.component.scss']
})
export class PolygonComponent implements OnInit {

  public bsModalRef: BsModalRef | undefined

  public isLoading: boolean = true
  public polygons: Polygon.PolygonInterface[] = []

  constructor(
    private readonly http: HttpClient,
    public readonly constService: AdmonitionService,
    public readonly titleService: TitleService,
    private modalService: BsModalService,
    public readonly managerService: ManagerService,
  ) { }

  async ngOnInit(): Promise<void> {

  }

  async ngAfterViewInit(): Promise<void>  {
    await this.load()
  }

  private async load(): Promise<void> {
    await this.http.post<SuccessResponseInterface>('/api/manager/polygons/list', {
      offset: 0,
      limit: 100
    }).toPromise()
      .then((data) => {
        let arr = data.data as Polygon.PolygonInterface[]
        for (const key of arr) {

          key.map = {
            layers: [
              tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'АКРО Маршрутизатор' })
            ],
          }
          // Для обычный полигонов
          if (key.json.type === 'FeatureCollection' && key.json.features[0].type === 'Feature' && key.json.features[0].geometry.type == "Polygon") {
            let poly = turf.polygon(key.json.features[0].geometry.coordinates)
            let center = turf.centerOfMass(poly);
            key.map.center = latLng(center.geometry.coordinates[1], center.geometry.coordinates[0])
            key.map.zoom = 8
            let coords: LatLngExpression[] = []
            for (let coord of key.json.features[0].geometry.coordinates[0]) {
              coords.push([coord[1], coord[0]])
            }
            let polyline = polygon(coords)
            key.map.layers.push(polyline)
            key.fitBounds = polyline.getBounds();
            this.polygons.push(key)

          } else if (key.json.type === 'Feature' && key.json.geometry.type == "MultiPolygon") {
            for (let pol of key.json.geometry.coordinates) {
              let coords: LatLngExpression[] = []
              key.map.center = latLng(pol[0][0][1], pol[0][1][0])
              for (let coord of pol[0]) {
                coords.push([coord[1], coord[0]])
              }
              let polyline = polygon(coords)
              key.map.layers.push(polyline)
            }
            key.map.zoom = 2
            this.polygons.push(key)
            if (key.description == 'Россия') {
              key.fitBounds = key.map.layers[key.map.layers.length-1].getBounds();
            }
          }
        }
        this.isLoading = false
        return;
      })
      .catch((err: ErrorResponseInterface) => {
        this.isLoading = false
      })
  }

  public async openModalWithComponent(manager: Manager.ManagerInterface | undefined) {
    const initialState = {
      manager: manager
    };
    this.bsModalRef = this.modalService.show(GetManagerComponent, {initialState})
  }

  // Получить текстовый аватар
  public getTextAvatar(name: Manager.name | undefined, surname: Manager.surname | undefined): any {
    if (typeof name == 'string' && typeof surname == 'string') {
      if (surname && name) {
        return name[0] + surname[0]
      }
      if (name) {
        return name[0]
      }
    }
  }

}
